<template>
    <div>
        <div class="swiper-container slideshow-container">
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
                <!-- Slides -->
                <slot name="my-slides"/>
            </div>
            <!-- If we need pagination -->
            <div class="swiper-pagination ns-slideshow"></div>

            <!-- If we need navigation buttons -->
            <!-- <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div> -->

        </div>
    </div>
</template>


<script>
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.min.css'
export default {
    data(){
        return {
            swiper: null
        }
    },
    mounted() {
        this.swiper = new Swiper('.swiper-container', {
            speed: 400,
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
            initialSlide: 0,
            init: true,
            loop: true,
        });

        this.swiper.destroy = () => {console.log('vunja')}

    },
    methods: {
        update() {
            if(this.swiper){
                this.swiper.update();
            }
            console.log("updated")
        }
    }
}
</script>

<style lang="scss">
    @import "../mixins";
    @import "../partials";

    .slideshow-container {
        .swiper-wrapper {
            height: 330px;
            width: 100%;
            @include mediaQuery($tablet-width) {
                height: 450px;
            }
            @include mediaQuery($pc-width) {
                height: 550px;
            }
            @include mediaQuery($lg-pc-width) {
                height: 600px;
            }
        }
        .ns-slideshow {
            .swiper-pagination-bullet {
                background: $white;
            }
        }
    }
</style>



