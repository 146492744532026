import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enUS from '@/locale/en-US'

Vue.use(VueI18n)

let i18n = new VueI18n({
    locale: 'en-US',
    fallbackLocale: 'en-US',
    messages: {
        'en-US': enUS
    }
})

export default i18n;
