import Vue from "vue";

let eventBus = new Vue();
export {eventBus};

let eventBusActions = {
    USER_LOGGED_IN: "userLoggedIn",
    USER_LOGGED_OUT: "userLoggedOut",
    SHOW_TOAST: "showToast",
    COPY_TEXT_TO_CLIPBOARD: "copyTextToClipboard"
}

export {eventBusActions}
