<template>
  <div class="about">
      <div class="overlay">
          <div class="text">
              <div>Page Not Found</div>
              <a-button @click="goHome" type="primary">Take Me Home</a-button>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    methods: {
        goHome() {
            this.$router.push('/')
        }
    }
}
</script>

<style lang="scss">
.about {
    background-image: url("https://bluewavecargo.com/files/slide-1.jpg");
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 2;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.65);
    z-index: 3;
}
.text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 200px;
    text-align: center;
    font-size: 30px;
    color: white;
}
</style>
