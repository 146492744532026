<template>
    <div class="wrapper">
        <div class="table-options">
            <div class="left">
                <div>
                    <div class="table-title">{{$t('transactions')}}</div>
                    <div>
                        <div>{{$t('summary')}}</div>
                        <div>{{$t('income')}}: {{totalIncome}}</div>
                        <div>{{$t('expenditure')}}: {{totalExpenditure}}</div>
                        <div>{{$t('balance')}}: {{roundToStringCurrency(balanceAmount)}}TShs.</div>
                    </div>
                </div>
            </div>
            <div class="right">
                <a-month-picker :defaultValue="momentNow" :allowClear="false" placeholder="Select Month" @change="onMonthChanged" />
                <a-button @click="showAddNewTransactionForm" type="primary" icon="plus">
                    {{$t('addNew')}}
                </a-button>
            </div>
        </div>

        <a-table
            :size="'middle'"
            :rowKey="'id'"
            bordered
            :columns="columns"
            :data-source="transactions"
            :pagination="pagination"
            :loading="isFetchingTransactions"
            @change="onTableChange"
        >
            <template slot="serialNumber" slot-scope="text, row, index">
                <div>{{ index + 1 }}</div>
            </template>
            <template slot="description" slot-scope="text, row">
                <div class="link" @click="showTransactionDetailsDrawer(row)">
                    <div v-if="text === ''" class="custom-ellipsis">{{ $t('noContent') }}</div>
                    <div v-else v-html="text"></div>
                </div>
            </template>
            <template slot="amount" slot-scope="amount, row">
                <div>{{ getAmountInTShs(row) }}</div>
                <div style="font-size: 12.5px; color: rgba(0,0,0,0.4);" v-if="row.isUSDTransaction">{{ getAmountInUSD(row) }}</div>
            </template>
            <template slot="transactionDate" slot-scope="text, row">
                <div>{{ text !== '' ? formatDate(text) : $t('noContent') }}</div>
            </template>
            <!--actions-->
            <template slot="actions" slot-scope="text, row">
                <a-button-group :size="'small'">
                    <a-tooltip>
                        <template slot="title">
                            <span>{{$t('update')}}</span>
                        </template>
                        <a-button @click="showUpdateTransactionForm(row)" type="primary" icon="edit"/>
                    </a-tooltip>
                    <a-tooltip>
                        <template slot="title">
                            <span>{{$t('delete')}}</span>
                        </template>
                        <a-button @click="confirmDeleteTransaction(row)" type="danger" icon="delete"/>
                    </a-tooltip>
                </a-button-group>
            </template>
        </a-table>

        <!--transaction info drawer-->
        <a-drawer
            :title="$t('containerInfo')"
            placement="right"
            :closable="true"
            :width="500"
            @close="onCloseContainerInfoDrawer"
            :bodyStyle="bodyStyle"
            :visible="isTransactionInfoDrawerVisible"
        >
            <div :style="contentStyle">
                <div class="content-wrapper">
                    <div :key="key" v-for="(item,key) in selectedTransaction" class="info-item">
                        <template v-if="key === $t('description')">
                            <div class="item-label">{{ key }}:</div>
                            <div v-if="item.trim() === ''" class="item-description">{{$t('noContent')}}</div>
                            <div v-else class="item-description remark" v-html="item"></div>
                        </template>
                        <template v-else-if="key === $t('transitStatus')">
                            <div class="item-label">{{ key }}:</div>
                            <div class="item-description">{{ getTransitStatusText(item) }}</div>
                        </template>
                        <template v-else>
                            <div class="item-label">{{ key }}:</div>
                            <div v-if="item === null || typeof item === 'undefined' || `${item}`.trim() === ''" class="item-description">{{$t('noContent')}}</div>
                            <div v-else class="item-description">{{ item }}</div>
                        </template>
                    </div>
                </div>
            </div>
        </a-drawer>


        <!--transaction form-->
        <a-drawer
            :title="transactionFormTitle"
            placement="right"
            :closable="true"
            :width="600"
            @close="onCloseContainerForm"
            :bodyStyle="bodyStyle"
            :visible="isTransactionFormVisible"
        >
            <div :style="contentFormStyle">
                <div class="content-form-wrapper">
                    <div @keyup.enter="submitTransactionForm">
                        <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-item
                                :label="$t('currency')">
                                <div v-if="isUpdateForm">{{formData.isUSDTransaction ? 'USD' : 'TShs.'}}</div>
                                <template v-else>
                                    <a-select @change="onCurrencyChange" v-model="formData.currency">
                                        <a-select-option :key="item.value" v-for="item in currencyOptions" :value="item.value">
                                            {{ item.title }}
                                        </a-select-option>
                                    </a-select>
                                </template>
                                <div class="form-tip" v-if="isUpdateForm">{{$t('currencyChangeTip')}}</div>
                                <div v-else class="form-tip">{{$t('createTransactionCurrencyTip')}}</div>
                            </a-form-item>
                            <a-form-item
                                :label="$t('amount')">
                                <a-input-number style="width: 100%;" v-model="formData.amount" autocomplete="new-password" :placeholder="$t('amount')"/>
                            </a-form-item>
                            <a-form-item
                                v-if="formData.isUSDTransaction"
                                :label="$t('currencyConverter')">
                                <a-input-number style="width: 100%;" v-model="formData.currencyConverter" autocomplete="new-password" :placeholder="$t('amount')"/>
                                <div class="form-tip">Equals to: {{roundToStringCurrency(formData.amount * formData.currencyConverter)}} TShs.</div>
                            </a-form-item>
                            <a-form-item
                                :label="$t('type')">
                                <div v-if="isUpdateForm">{{formData.isIncome ? 'Income' : 'Expenditure'}}</div>
                                <template v-else>
                                    <a-select @change="onTransactionTypeChanged" v-model="formData.transactionType">
                                        <a-select-option :key="item.value" v-for="item in transactionType" :value="item.value">
                                            {{ item.title }}
                                        </a-select-option>
                                    </a-select>
                                </template>
                                <div class="form-tip">{{$t('transactionTypeTip')}}</div>
                            </a-form-item>
                            <a-form-item v-if="!formData.isIncome"
                                :label="$t('isContainerExpenses')">
                                <a-select @change="formData.referenceId = ''" v-model="formData.isContainerExpense">
                                    <a-select-option :key="1" :value="1">
                                        Yes
                                    </a-select-option>
                                    <a-select-option :key="2" :value="0">
                                        No
                                    </a-select-option>
                                </a-select>
                                <div class="form-tip">{{$t('transactionTypeTip')}}</div>
                            </a-form-item>
                            <a-form-item v-if="!formData.isIncome && formData.isContainerExpense"
                                         :label="$t('selectContainer')">

                                <a-select
                                    show-search
                                    :placeholder="$t('selectContainer')"
                                    v-model="formData.referenceId"
                                    style="width: 100%;"
                                    :filter-option="onContainersFilter"
                                >
                                    <a-select-option :key="container.id" v-for="container in rawContainers" :value="container.id">
                                        {{container.containerNumber === '' ? $t('noContainerNumber') : container.containerNumber}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item :label="$t('description')">
                                <a-textarea rows="3" @keyup.enter.stop v-model.trim="formData.description" autocomplete="new-password" placeholder="Official Use Remark"/>
                                <div class="form-tip">{{$t('transactionTip')}}</div>
                            </a-form-item>
                        </a-form>
                    </div>
                </div>
            </div>
            <div :style="contentFormFooterStyle">
                <div class="footer-buttons">
                    <a-button @click="onCloseContainerForm">{{$t('cancel')}}</a-button>
                    <a-button @click="submitTransactionForm" type="primary">{{$t('submit')}}</a-button>
                </div>
            </div>
        </a-drawer>


    </div>
</template>

<script>
import {drawerSetups, errorHandler, fuseInstance, locale, successHandler} from "@/services/utils";
import http from "@/http";
import moment from "moment"

export default {
    name: "Finance",
    data() {
        return {
            isTransactionInfoDrawerVisible: false,
            bodyStyle: drawerSetups.bodyStyle,
            contentStyle: drawerSetups.contentStyle,
            contentFormStyle: drawerSetups.contentFormStyle,
            contentFormFooterStyle: drawerSetups.contentFormFooterStyle,
            selectedTransaction: {},
            searchKeys: ['containerNumber'],
            currencyOptions: [
                {
                    value: 'TShs.',
                    title: 'TShs.'
                },
                {
                    value: 'USD',
                    title: 'USD'
                }
            ],
            transactionType: [
                {
                    value: 'Income',
                    title: 'Income'
                },
                {
                    value: 'Expenses',
                    title: 'Expenses'
                }
            ],
            formData: {
                amount: 0,
                currency: 'TShs.',
                description: '',
                currencyConverter: 1,
                transactionType: 'Income',
                isIncome: 1,
                isContainerExpense: 0,
                isUSDTransaction: 0,
                referenceId: ''
            },
            formDataReset: {
                amount: 0,
                currency: 'TShs.',
                description: '',
                currencyConverter: 1,
                transactionType: 'Income',
                isIncome: 1,
                isContainerExpense: 0,
                isUSDTransaction: 0,
                referenceId: ''
            },
            isUpdateForm: false,
            isTransactionFormVisible: false,
            isSubmittingTransactionForm: false,
            labelCol: {
                xs: {span: 8}
            },
            wrapperCol: {
                xs: {span: 16}
            },
            keywords: '',
            columns: [
                {
                    title: this.$t('serialNumber'),
                    dataIndex: 'serialNumber',
                    scopedSlots: {customRender: 'serialNumber'},
                    align: 'center',
                    width: '70px'
                },
                {
                    title: this.$t('description'),
                    dataIndex: 'description',
                    scopedSlots: {customRender: 'description'},
                    maxWidth: '70px'
                },
                {
                    title: this.$t('amount'),
                    dataIndex: 'amount',
                    scopedSlots: {customRender: 'amount'},
                    width: '190px',
                    filters: [
                        {
                            text: 'Income',
                            value: 1
                        },
                        {
                            text: 'Expenditure',
                            value: 0
                        },
                    ],
                    filterMultiple: false,
                    onFilter: this.onTransactionTypeFilter
                },
                {
                    title: this.$t('transactionDateLabel'),
                    dataIndex: 'customCreatedAt',
                    scopedSlots: {customRender: 'transactionDate'},
                    sorter: this.createDateSorter,
                    width: '160px'
                },
                {
                    title: this.$t('actions'),
                    dataIndex: 'actions',
                    width: '140px',
                    align: 'center',
                    scopedSlots: {customRender: 'actions'}
                }
            ],
            pagination: {
                simple: true,
                current: 1,
                pageSize: 150,
                total: 1,
            },
            startDate: moment().toDate(),
            tableSorter: null,
            transactions: [],
            rawTransactions: [],
            rawContainers: [],
            balanceAmount: 0.0,
            isFetchingTransactions: false
        }
    },
    computed: {
        momentNow() {
          return moment()
        },
        transactionFormTitle() {
            return this.isUpdateForm ? this.$t('updateTransaction') : this.$t('addTransaction');
        },
        totalIncome() {
            let income = 0
            this.transactions.forEach((item)=>{
                if(item.isIncome) {
                    let amount = 0.0
                    if(item.isUSDTransaction) {
                        amount = item.amount * item. currencyConverter
                    } else {
                        amount = item.amount
                    }
                    income = income + amount
                }
            })
            return `${this.roundToStringCurrency(income)} TShs.`
        },
        totalExpenditure() {
            let income = 0
            this.transactions.forEach((item)=>{
                if(!item.isIncome) {
                    let amount = 0.0
                    if(item.isUSDTransaction) {
                        amount = item.amount * item. currencyConverter
                    } else {
                        amount = item.amount
                    }
                    income = income + amount
                }
            })
            return `${this.roundToStringCurrency(income)} TShs.`
        },
    },
    created() {
        this.formDataReset.currencyConverter = locale.getCurrencyConverter();
        this.fetchContainers();
        this.fetchTransactions();
    },
    methods: {
        onMonthChanged(date, dateString) {
            this.startDate = moment(dateString).toDate()
            this.fetchTransactions()
        },
        onTransactionTypeFilter(value, transaction) {
            if(value === 1) {
                return transaction.isIncome
            }
            return !transaction.isIncome
        },
        onContainersFilter(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        onTransactionTypeChanged() {
            this.formData.isIncome = this.formData.transactionType === 'Income'
        },
        onCurrencyChange() {
            this.formData.isUSDTransaction = this.formData.currency === 'USD'
        },
        getAmountInTShs(transactionItem) {
            let amount = 0.0
            if(transactionItem.isUSDTransaction) {
                amount = transactionItem.amount * transactionItem. currencyConverter
            } else {
                amount = transactionItem.amount
            }
            let amountString = this.roundToStringCurrency(amount)
            let sign = transactionItem.isIncome ? '+' : '-';
            return `${sign}${amountString} TShs.`
        },
        getAmountInUSD(transactionItem) {
            let sign = transactionItem.isIncome ? '+' : '-';
            return `(${sign}${this.roundToStringCurrency(transactionItem.amount)} USD.)`
        },
        roundToNumericCurrency(amount) {
            return (Math.round(amount * 100) / 100).toFixed(2)
        },
        roundToStringCurrency(amount) {
            return ((Math.round(amount * 100) / 100).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        getTransitStatusText(transitStatus) {
            let transitStatusText = "";
            for (let i=0; i< this.transitStatus.length; i++) {
                if(this.transitStatus[i].value === transitStatus) {
                    transitStatusText = this.transitStatus[i].title
                    break;
                }
            }
            return transitStatusText
        },
        formatDate(dateString) {
            return moment(dateString).format("DD-MM-YYYY")
        },
        onDepartureDateChange(momentInstance) {
            this.formData.departureDate = momentInstance
        },
        onArrivalDateChange(momentInstance) {
            this.formData.arrivalDate = momentInstance
        },
        getTodayDate() {
            return moment(new Date().getTime())
        },
        getMonthLaterDate() {
            return moment(new Date().getTime()).add(1, 'months')
        },
        onTableChange(pagination, filters, sorters) {
            if(this.pagination.current !== pagination.current) {
                //page number
                this.pagination.current = pagination.current;
                this.fetchTransactions();
            }
            /* else {
                //a change in filters or sorters
                this.pagination.current = 1;
                if(sorters.order) {
                    this.tableSorter = {
                        field: sorters.field,
                        order: sorters.order
                    }
                }else {
                    this.tableSorter = null;
                }
            }*/
        },
        onCloseContainerForm() {
            this.isTransactionFormVisible = false;
        },
        onCloseContainerInfoDrawer() {
            this.isTransactionInfoDrawerVisible = false;
        },
        createDateSorter(a,b) {
            if(a.customCreatedAt < b.customCreatedAt) { return -1; }
            if(a.customCreatedAt > b.customCreatedAt) { return 1; }
            return 0;
        },
        async fetchContainers() {
            try {
                let params = {
                    pageNum: 1,
                    itemsPerPage: 50
                }
                let response = await http.get('/containers', params);
                if(response.data.code === 200) {
                    let serverData = response.data.data;
                    this.rawContainers = serverData.result;
                }
            } catch (e) {
                // errorHandler(this.$t('networkError'));
            }
        },
        async fetchTransactions() {
            try {
                let params = {
                    monthYear: this.startDate
                }
                if(this.tableSorter) {
                    params.sorter = {
                        order: this.tableSorter.order,
                        field: this.tableSorter.field
                    }
                }
                this.isFetchingTransactions = true;
                let response = await http.get('/transactions', params);
                this.isFetchingTransactions = false;
                if(response.data.code === 200) {
                    let serverData = response.data.data;
                    this.rawTransactions = serverData.transactionsList;
                    this.balanceAmount = serverData.balanceAmount;
                    this.onSearch();
                }
            } catch (e) {
                this.isFetchingTransactions = false;
                errorHandler(this.$t('networkError'));
            }
        },
        async submitTransactionForm() {
            try {
                this.isSubmittingTransactionForm = true;
                if(this.formData.isIncome) {
                    this.formData.isContainerExpense = false
                }

                if(this.formData.isContainerExpense && this.formData.referenceId === '') {
                    this.$message.error(this.$t('chooseExpenseContainer'))
                    return
                }

                /*this.formData.isUSDTransaction = this.formData.isUSDTransaction === 1
                this.formData.isIncome = this.formData.isIncome === 1
                this.formData.isContainerExpense = this.formData.isContainerExpense === 1*/

                let response;
                if(this.isUpdateForm) {
                    response = await http.put('/transaction',this.formData);
                }else {
                    response = await http.post('/transaction',this.formData);
                }

                this.isSubmittingTransactionForm = false;
                if(response.data.code === 200) {
                    successHandler(this.$t('operationSuccessful'));
                    this.isTransactionFormVisible = false;
                    this.fetchTransactions();
                }
            } catch (e) {
                this.isSubmittingTransactionForm = false;
                errorHandler(this.$t('networkError'));
            }
        },
        confirmDeleteTransaction(transaction) {
            if(transaction.isCargoPayment) {
                this.$error({
                    title: `Wait`,
                    content: `This is a cargo payment, it can not be deleted here, it can only be deleted on the cargo where you can update this customer's cargo information, set the paid amount to zero (0), that's it. The balance will automatically be recalculated.`,
                });
                return
            }
            this.$confirm({
                title: this.$t('deleteConfirmation'),
                content: this.$t('deleteTransactionMessage',{description: transaction.description}),
                okText: this.$t('yes'),
                okType: 'danger',
                cancelText: this.$t('cancel'),
                onOk: () => {
                    this.deleteTransaction(transaction);
                }
            })
        },
        async deleteTransaction(transaction) {
            try {
                this.isFetchingTransactions = true;
                let response = await http.delete('/transaction',transaction);
                if(response.data.code === 200) {
                    successHandler(this.$t('operationSuccessful'))
                    this.fetchTransactions();
                }
            } catch (e) {
                this.isFetchingTransactions = false;
                errorHandler(this.$t('networkError'));
            }
        },
        showUpdateTransactionForm(transaction) {
            if(transaction.isCargoPayment) {
                this.$error({
                    title: `Wait`,
                    content: `This is a cargo payment, it can not be updated here, it can only be updated on the cargo where you can update this customer's cargo information.`,
                });
                return
            }

            this.formData = {
                ...transaction,
                currency: transaction.isUSDTransaction ? 'USD' : 'TShs.',
                transactionType: transaction.isIncome ? 'Income' : 'Expenditure',
                isUSDTransaction: transaction.isUSDTransaction ? 1 : 0,
                isIncome: transaction.isIncome ? 1 : 0,
                isContainerExpense: transaction.isContainerExpense ? 1 : 0
            }

            this.isUpdateForm = true;
            this.isTransactionFormVisible = true;

        },
        showTransactionDetailsDrawer(transaction) {
            let currency = transaction.isUSDTransaction ? 'US$':'TShs.'
            this.selectedTransaction = {
                [this.$t('amount')]: `${transaction.amount} ${currency}`,
                [this.$t('createdBy')]: transaction.creatorInfo ? transaction.creatorInfo.name : '(No Name)',
                [this.$t('currencyConverter')]: transaction.isUSDTransaction ? transaction.currencyConverter : '(Not Applicable)',
                [this.$t('type')]: transaction.isIncome ? 'Income' : 'Expenditure',
                [this.$t('isCargoPayment')]: transaction.isCargoPayment ? 'YES' : 'NO',
                [this.$t('isContainerExpenses')]: transaction.isContainerExpense ? 'YES' : 'NO',
                [this.$t('description')]: transaction.description.length > 0 ? transaction.description : '(No Content)',
            };
            this.isTransactionInfoDrawerVisible = true;
        },
        onSearch() {
            let output = fuseInstance.search(this.searchKeys, this.rawTransactions, this.keywords);
            this.transactions = [...output];
        },
        showAddNewTransactionForm() {
            this.resetTransactionForm();
            this.isUpdateForm = false
            this.isTransactionFormVisible = true;
        },
        resetTransactionForm() {
            this.formData = {
                ...this.formDataReset
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../partials";
@import "../../../mixins";
.wrapper {
    background: white;
    padding: 15px;

    .table-options {
        @include tableOptions;
    }
}
.content-wrapper {
    @include drawerList;
}
.content-form-wrapper {
    padding: 15px 30px;
}
.footer-buttons {
    @include drawerFormFooterButtons;
}
.open-container-btn {
    background-color: #4caf50;
    color: white;
    border-top: 1px solid #4caf50;
    border-bottom: 1px solid #4caf50;
}
.custom-ellipsis {
    overflow: hidden;
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
