<template>
    <div class="wrapper">
        <div class="table-options">
            <div class="left">
                <div class="table-title">{{$t('employees')}}</div>
            </div>
            <div class="right">
                <a-input-search v-model="keywords" :placeholder="$t('employeeName')" enter-button @search="onSearch"/>
                <a-button @click="addNewEmployee" type="primary" icon="plus">
                    {{$t('addNew')}}
                </a-button>
            </div>
        </div>

        <a-table
            :size="'middle'"
            :rowKey="'id'"
            bordered
            :columns="columns"
            :data-source="employees"
            :pagination="false"
            :loading="isFetchingEmployees"
        >
            <template slot="serialNumber" slot-scope="text, row, index">
                <div>{{ index + 1 }}</div>
            </template>
            <template slot="employeeName" slot-scope="text, row">
                <div class="link" @click="showEmployeeDetailsDrawer(row)">{{ text }}</div>
            </template>
            <template slot="employeePrivilege" slot-scope="text, row">
                <div style="text-transform: capitalize;">{{ text }}</div>
            </template>
            <!--actions-->
            <template slot="actions" slot-scope="text, row">
                <a-button-group :size="'small'">
                    <a-tooltip>
                        <template slot="title">
                            <span>{{$t('update')}}</span>
                        </template>
                        <a-button @click="showUpdateEmployeeForm(row)" type="primary" icon="edit"/>
                    </a-tooltip>
                    <a-tooltip>
                        <template slot="title">
                            <span>{{$t('resetPassword')}}</span>
                        </template>
                        <a-button @click="showResetPasswordForm(row)" class="reset-password-btn" type="primary"
                                  icon="unlock"/>
                    </a-tooltip>
                    <a-tooltip v-if="row.isSuspended">
                        <template slot="title">
                            <span>{{$t('activate')}}</span>
                        </template>
                        <a-button @click="confirmActivateEmployee(row)" class="activate-btn" icon="check-circle"/>
                    </a-tooltip>
                    <a-tooltip v-else>
                        <template slot="title">
                            <span>{{$t('suspend')}}</span>
                        </template>
                        <a-button @click="confirmSuspendEmployee(row)" icon="stop"/>
                    </a-tooltip>
                    <a-tooltip>
                        <template slot="title">
                            <span>{{$t('delete')}}</span>
                        </template>
                        <a-button @click="confirmDeleteEmployee(row)" type="danger" icon="delete"/>
                    </a-tooltip>
                </a-button-group>
            </template>
        </a-table>

        <!--employee info drawer-->
        <a-drawer
            :title="$t('employeeInfo')"
            placement="right"
            :closable="true"
            :width="500"
            @close="onCloseEmployeeInfoDrawer"
            :bodyStyle="bodyStyle"
            :visible="isEmployeeInfoDrawerVisible"
        >
            <div :style="contentStyle">
                <div class="content-wrapper">
                    <div :key="key" v-for="(item,key) in selectedEmployee" class="info-item">
                        <template v-if="key === 'Phone Numbers'">
                            <div class="item-label">{{ key }}:</div>
                            <div class="item-description">
                                <div v-if="item.length === 0">{{$t('noPhoneNumber')}}</div>
                                <template v-else>
                                    <div :key="index" v-for="(phone,index) in item">{{ phone }}</div>
                                </template>
                            </div>
                        </template>
                        <template v-else-if="key === 'Status'">
                            <div class="item-label">{{ key }}:</div>
                            <div class="item-description">
                                <a-tag v-if="item" color="red">{{$t('suspended')}}</a-tag>
                                <a-tag v-else color="green">{{$t('active')}}</a-tag>
                            </div>
                        </template>
                        <template v-else-if="key === 'Privilege'">
                            <div class="item-label">{{ key }}:</div>
                            <div style="text-transform: capitalize;" class="item-description">{{ item }}</div>
                        </template>
                        <template v-else>
                            <div class="item-label">{{ key }}:</div>
                            <div class="item-description">{{ item }}</div>
                        </template>
                    </div>
                </div>
            </div>
        </a-drawer>


        <!--employee form-->
        <a-modal
            v-model="isEmployeeFormVisible"
            :title="employeeFormTitle"
            :width="600"
            centered
            @ok="submitEmployeeForm"
            :okText="employeeFormOkButtonText"
            :confirmLoading="isSubmittingEmployeeForm"
            :cancelText="$t('cancel')"
        >
            <div @keyup.enter="submitEmployeeForm">
                <a-form :form="employeeForm" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-item
                        :label="$t('fullName')">
                        <a-input autocomplete="new-password" :placeholder="$t('fullNameExample')"
                                 v-decorator="['name',{rules:[{required: true,validator:validateName}]}]"/>
                    </a-form-item>
                    <a-form-item
                        :label="$t('email')">
                        <a-input autocomplete="new-password" :placeholder="$t('emailExample')"
                                 v-decorator="['email',{rules:[{required: true,validator:validateEmail}]}]"/>
                        <div class="form-tip">{{$t('emailTip')}}</div>
                    </a-form-item>
                    <a-form-item
                        :label="$t('password')" v-if="!isUpdateForm">
                        <a-input-password autocomplete="new-password" :placeholder="$t('password')"
                                          v-decorator="['password',{rules:[{required: true,validator:validatePassword}]}]"/>
                        <div class="form-tip">{{$t('passwordTip')}}</div>
                    </a-form-item>
                    <a-form-item
                        :label="$t('systemPrivilege')">
                        <a-select v-model="employeeFormData.privilege" @change="onPrivilegeChange">
                            <a-select-option :key="key" v-for="(item,key) in privileges" :value="item">
                                {{ key }}
                            </a-select-option>
                        </a-select>
                        <div class="form-tip">{{ privilegeTip }}</div>
                    </a-form-item>
                    <a-form-item
                        :label="$t('phoneNumbers')">
                        <div :key="`${index}-${phone.tempId}`"
                             v-for="(phone,index) in employeeFormData.phoneNumbersObjectsList"
                             class="user-input-wrapper">
                            <a-input autocomplete="new-password" v-model.trim="phone.number" class="user-input"
                                     :placeholder="$t('phoneNumberExample')"/>
                            <template v-if="index === 0">
                                <a-tooltip>
                                    <template slot="title">
                                        {{$t('addPhoneNumber')}}
                                    </template>
                                    <a-button @click="addNewPhoneNumber" type="primary" shape="circle" icon="plus"/>
                                </a-tooltip>
                            </template>
                            <template v-else>
                                <a-tooltip>
                                    <template slot="title">
                                        {{$t('removePhoneNumber')}}
                                    </template>
                                    <a-button @click="deletePhoneNumber(index)" type="danger" shape="circle"
                                              icon="delete"/>
                                </a-tooltip>
                            </template>
                        </div>
                    </a-form-item>
                </a-form>
            </div>
        </a-modal>


        <!--Reset Employee Password-->
        <a-modal
            v-model="isResetPasswordFormVisible"
            :title="$t('resetPassword')"
            :width="600"
            centered
            @ok="resetEmployeePassword"
            :okText="$t('resetPassword')"
            :confirmLoading="isSubmittingEmployeeForm"
            :cancelText="$t('cancel')"
        >
            <div @keyup.enter="resetEmployeePassword">
                <a-form :form="resetPasswordForm" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-item style="margin-bottom: 0"
                        :label="$t('fullName')">
                        <div>{{employeeFormData.name}}</div>
                    </a-form-item>
                    <a-form-item style="margin-bottom: 0"
                        :label="$t('email')">
                        <div>{{employeeFormData.email}}</div>
                    </a-form-item>
                    <a-form-item
                        :label="$t('newPassword')">
                        <a-input-password autocomplete="new-password" :placeholder="$t('newPassword')"
                                          v-decorator="['password',{rules:[{required: true,validator:validatePassword}]}]"/>
                        <div class="form-tip">{{$t('passwordTip')}}</div>
                    </a-form-item>
                </a-form>
            </div>
        </a-modal>


    </div>
</template>

<script>
import {currentUser, drawerSetups, errorHandler, fuseInstance, successHandler, validation} from "@/services/utils";
import http from "@/http";

export default {
    name: "Employees",
    data() {
        return {
            isFetchingEmployees: false,
            tempIndexId: 0,
            privilegeTip: '',
            tips: {
                'super admin': `This is the system master`,
                'system manager': `This user can manage the whole system including adding other employees like you can do.`,
                'content creator': `This user can create and update content`,
            },
            privileges: {
                // 'Super Admin': 'super admin',
                'Content Creator': 'content creator',
                'System Manager': 'system manager'
            },
            restrictedEmails: [],
            labelCol: {
                xs: {span: 6}
            },
            wrapperCol: {
                xs: {span: 18}
            },
            employeeForm: this.$form.createForm(this, {name: 'employee'}),
            resetPasswordForm: this.$form.createForm(this, {name: 'resetPassword'}),
            bodyStyle: drawerSetups.bodyStyle,
            contentStyle: drawerSetups.contentStyle,
            isEmployeeInfoDrawerVisible: false,
            isEmployeeFormVisible: false,
            isResetPasswordFormVisible: false,
            isUpdateForm: false,
            isSubmittingEmployeeForm: false,
            employeeFormData: {
                name: '',
                email: '',
                password: '',
                privilege: 'content creator',
                phoneNumbersObjectsList: [],
                phoneNumbers: [],
                isSuspended: false
            },
            employeeFormReset: {
                name: '',
                email: '',
                password: '',
                privilege: 'content creator',
                isSuspended: false,
                phoneNumbersObjectsList: [
                    {
                        tempId: Math.random() * 1000,
                        number: ''
                    }
                ],
                phoneNumbers: []
            },
            selectedEmployee: {},
            rawEmployees: [],
            employees: [],
            keywords: '',
            searchKeys: ['name', 'position'],
            columns: [
                {
                    title: this.$t('serialNumber'),
                    dataIndex: 'serialNumber',
                    scopedSlots: {customRender: 'serialNumber'},
                    align: 'center',
                    width: '70px'
                },
                {
                    title: this.$t('fullName'),
                    dataIndex: 'name',
                    scopedSlots: {customRender: 'employeeName'}
                },
                {
                    title: this.$t('privilege'),
                    dataIndex: 'privilege',
                    width: '150px',
                    scopedSlots: {customRender: 'employeePrivilege'}
                },
                {
                    title: this.$t('actions'),
                    dataIndex: 'actions',
                    width: '140px',
                    align: 'center',
                    scopedSlots: {customRender: 'actions'}
                }
            ]
        }
    },
    computed: {
        employeeFormTitle() {
            return this.isUpdateForm ? this.$t('updateEmployeeInfo') : this.$t('addNewEmployee');
        },
        employeeFormOkButtonText() {
            return this.isUpdateForm ? this.$t('update') : this.$t('submit');
        },
    },
    methods: {
        getRestrictedEmails(allEmployees) {
            this.restrictedEmails = allEmployees.map((employee)=>employee.email);
            this.restrictedEmails.push(currentUser.getUserDetails().email);
        },
        confirmActivateEmployee(employee) {
            this.$confirm({
                title: this.$t('activationConfirmation'),
                content: this.$t('activationMessage',{name: employee.name}),
                okText: this.$t('yes'),
                okType: 'primary',
                cancelText: this.$t('cancel'),
                onOk: () => {
                    this.activateEmployee(employee);
                }
            })
        },
        async activateEmployee(employee) {
            employee.isSuspended = false;
            this.changeEmployeeSuspensionStatus(employee);
            successHandler(this.$t('operationSuccessful'));
        },
        confirmSuspendEmployee(employee) {
            this.$confirm({
                title: this.$t('suspensionConfirmation'),
                content: this.$t('suspensionMessage',{name: employee.name}),
                okText: this.$t('yes'),
                okType: 'danger',
                cancelText: this.$t('cancel'),
                onOk: () => {
                    this.suspendEmployee(employee);
                }
            })
        },
        async suspendEmployee(employee) {
            employee.isSuspended = true;
            this.changeEmployeeSuspensionStatus(employee);
            successHandler(this.$t('operationSuccessful'));
        },
        async changeEmployeeSuspensionStatus(employee) {
            try {
                let response = await http.put('/update-employee-suspension-status',employee);
                if(response.data.code === 200) {
                    //console.log('done')
                }
            } catch (e) {
                // reverse the status since the operation failed
                employee.isSuspended = !employee.isSuspended;
                errorHandler(this.$t('networkError'));
            }
        },
        confirmDeleteEmployee(employee) {
            this.$confirm({
                title: this.$t('deleteConfirmation'),
                content: this.$t('deleteEmployeeMessage',{name: employee.name}),
                okText: this.$t('yes'),
                okType: 'danger',
                cancelText: this.$t('cancel'),
                onOk: () => {
                    this.deleteEmployee(employee);
                }
            });
        },
        async deleteEmployee(employee) {
            try {
                this.isFetchingEmployees = true;
                let response = await http.delete('/delete-employee',employee);
                if(response.data.code === 200) {
                    successHandler(this.$t('operationSuccessful'))
                    this.fetchAllEmployees();
                }
            } catch (e) {
                this.isFetchingEmployees = false;
                errorHandler(this.$t('networkError'));
            }
        },
        deletePhoneNumber(index) {
            this.employeeFormData.phoneNumbersObjectsList.splice(index, 1);
        },
        addNewPhoneNumber() {
            let phoneNumberField = this.getPhoneNumberField('');
            this.employeeFormData.phoneNumbersObjectsList.push(phoneNumberField);
        },
        onPrivilegeChange() {
            this.privilegeTip = this.tips[this.employeeFormData.privilege];
        },
        validateName(rule, value, callback) {
            if (!value || value.trim() === "") {
                callback(this.$t('employeeNameRequired'));
                return;
            }
            callback();
        },
        validateEmail(rule, value, callback) {
            if (!value || value.trim() === "") {
                callback(this.$t('emailRequired'));
                return;
            }

            if (!validation.isEmail(value.trim())) {
                callback(this.$t('invalidEmail'));
                return;
            }

            if(this.isUpdateForm && this.employeeFormData.oldEmail === this.employeeFormData.email) {
                //the user hasn't changed an email in the update form
                callback();
                return;
            }

            /* the user may have changed an email in the update form, or he is creating a new employee,
            check if his email can be used or not */

            if (this.restrictedEmails.indexOf(value.trim()) > -1) {
                callback(this.$t('emailTaken'));
                return;
            }

            callback();
        },
        validatePassword(rule, value, callback) {
            if (!value || value.trim() === "") {
                callback(this.$t('passwordRequired'));
                return;
            }
            if (value.trim().length < 3) {
                callback(this.$t('passwordTooShort'));
                return;
            }
            callback()
        },
        validateResetPasswordForm() {
            let validity = {
                isValid: true,
                message: this.$t('successful')
            };
            this.resetPasswordForm.validateFields({force: true}, (error, values) => {
                if (error) {
                    validity.isValid = false;
                    validity.message = this.$t('checkFormAgain');
                    return
                }
                this.employeeFormData.password = values.password;

            });
            return validity;
        },
        validateEmployeeForm() {
            let validity = {
                isValid: true,
                message: this.$t('successful')
            };
            this.employeeForm.validateFields({force: true}, (error, values) => {
                if (error) {
                    validity.isValid = false;
                    validity.message = this.$t('checkFormAgain');
                    return
                }
                this.employeeFormData.name = values.name.trim();
                this.employeeFormData.email = values.email.trim();
                this.employeeFormData.password = values.password;
                let tempNumbers = this.employeeFormData.phoneNumbersObjectsList.map((item) => item.number);
                this.employeeFormData.phoneNumbers = tempNumbers.filter((item) => item !== '');

            });
            return validity;
        },
        async resetEmployeePassword() {
            let {isValid, message} = this.validateResetPasswordForm();
            if (!isValid) {
                errorHandler(message);
                return;
            }
            try {
                this.isSubmittingEmployeeForm = true;
                let response = await http.put('/update-employee-password',this.employeeFormData);
                this.isSubmittingEmployeeForm = false;
                if(response.data.code === 200) {
                    successHandler(this.$t('operationSuccessful'));
                    this.isResetPasswordFormVisible = false;
                }
            } catch (e) {
                this.isSubmittingEmployeeForm = false;
                errorHandler(this.$t('networkError'));
            }

        },
        async submitEmployeeForm() {
            let {isValid, message} = this.validateEmployeeForm();
            if (!isValid) {
                errorHandler(message);
                return;
            }
            try {
                this.isSubmittingEmployeeForm = true;
                let response;
                if(this.isUpdateForm) {
                    response = await http.put('/update-employee-info',this.employeeFormData);
                }else {
                    response = await http.post('/register-employee',this.employeeFormData);
                }
                this.isSubmittingEmployeeForm = false;
                if(response.data.code === 5) {
                    errorHandler(this.$t('emailTaken'));
                    return;
                }
                if(response.data.code === 200) {
                    successHandler(this.$t('operationSuccessful'));
                    this.isEmployeeFormVisible = false;
                    this.fetchAllEmployees();
                }
            } catch (e) {
                this.isSubmittingEmployeeForm = false;
                errorHandler(this.$t('networkError'))
            }
        },
        resetEmployeeForm() {
            this.employeeForm.resetFields();
            this.employeeFormData = {
                ...this.employeeFormReset,
                phoneNumbers: [],
                phoneNumbersObjectsList: this.employeeFormReset.phoneNumbersObjectsList.map((item) => {
                    return {...item};
                })
            };
            this.privilegeTip = this.tips[this.employeeFormData.privilege];
        },
        getPhoneNumberField(phoneNumber) {
            return {
                tempId: this.tempIndexId++,
                number: phoneNumber
            };
        },
        showResetPasswordForm(employee) {
            this.isResetPasswordFormVisible = true;
            this.employeeFormData = {
                ...employee
            }
        },
        showUpdateEmployeeForm(employee) {
            this.isUpdateForm = true;
            this.isEmployeeFormVisible = true;
            this.$nextTick(() => {
                this.employeeForm.setFieldsValue({
                    name: employee.name,
                    email: employee.email
                });
                let phones = employee.phoneNumbers.length > 0 ?
                    employee.phoneNumbers.map((phoneNumber) => this.getPhoneNumberField(phoneNumber)) :
                    [{...this.getPhoneNumberField('')}];
                this.employeeFormData = {
                    ...this.employeeFormReset,
                    oldEmail: employee.email,
                    ...employee,
                    phoneNumbersObjectsList: phones
                }
                this.privilegeTip = this.tips[this.employeeFormData.privilege];
            });
        },
        addNewEmployee() {
            this.isUpdateForm = false;
            this.resetEmployeeForm();
            this.isEmployeeFormVisible = true;
        },
        showEmployeeDetailsDrawer(row) {
            this.selectedEmployee = {
                'Full Name': row.name,
                'E-mail': row.email,
                'Status': row.isSuspended,
                'Privilege': row.privilege,
                'Phone Numbers': row.phoneNumbers
            }
            this.isEmployeeInfoDrawerVisible = true;
        },
        onCloseEmployeeInfoDrawer() {
            this.isEmployeeInfoDrawerVisible = false;
        },
        onSearch() {
            let output = fuseInstance.search(this.searchKeys, this.rawEmployees, this.keywords);
            this.employees = [...output];
        },
        async fetchAllEmployees() {
            try{
                this.isFetchingEmployees = true;
                let {data} = await http.get('/all-employees');
                this.isFetchingEmployees = false;
                if (data.code === 200) {
                    this.rawEmployees = data.data;
                    this.getRestrictedEmails(this.rawEmployees);
                    this.onSearch();
                }
            }catch (e) {
                this.isFetchingEmployees = false;
                errorHandler(this.$t('networkError'));
            }
        }
    },
    created() {
        this.fetchAllEmployees();
    }
}
</script>

<style lang="scss" scoped>
@import "../../../partials";
@import "../../../mixins";

.wrapper {
    background: white;
    padding: 15px;

    .table-options {
        @include tableOptions;
    }

    .activate-btn {
        background-color: #4caf50;
        color: white;
        border-top: 1px solid #4caf50;
        border-bottom: 1px solid #4caf50;
    }

    .reset-password-btn {
        background-color: #ff9800;
        color: white;
        border-top: 1px solid #ff9800;
        border-bottom: 1px solid #ff9800;
    }
}

.content-wrapper {
    @include drawerList;
}

.user-input-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;
}

.user-input {
    width: 85%;
}
</style>
