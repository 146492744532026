<template>
    <div class="wrapper">
        <div class="table-options">
            <div class="left">
                <div>
                    <div class="table-title">{{$t('customers')}}</div>
                    <div>
                        <div>{{$t('summary')}}</div>
                        <div>{{$t('totalCustomers')}}: {{rawCustomers.length}}</div>
                    </div>
                </div>
            </div>
            <div class="right">
                <a-input-search v-model="keywords" :placeholder="$t('customerName')" enter-button @search="onSearch"/>
                <a-button @click="addNewCustomer" type="primary" icon="plus">
                    {{$t('addNew')}}
                </a-button>
            </div>
        </div>

        <a-table
            :size="'middle'"
            :rowKey="'id'"
            bordered
            :columns="columns"
            :data-source="customers"
            :pagination="false"
            :loading="isFetchingCustomers"
        >
            <template slot="serialNumber" slot-scope="text, row, index">
                <div>{{ index + 1 }}</div>
            </template>
            <template slot="customerName" slot-scope="text, row">
                <div class="link" @click="showCustomerDetailsDrawer(row)">{{ text === '' ? $t('noName') : text }}</div>
            </template>
            <template slot="phoneNumbers" slot-scope="text, row">
                <div style="text-transform: capitalize;">
                    <div v-if="text.length === 0">{{$t('noPhoneNumber')}}</div>
                    <template v-else>
                        <div :key="`${number}-${index}`" v-for="(number,index) in text">{{number}}</div>
                    </template>
                </div>
            </template>
            <!--actions-->
            <template slot="actions" slot-scope="text, row">
                <a-button-group :size="'small'">
                    <a-tooltip>
                        <template slot="title">
                            <span>{{$t('update')}}</span>
                        </template>
                        <a-button @click="showUpdateCustomerForm(row)" type="primary" icon="edit"/>
                    </a-tooltip>
                    <a-tooltip>
                        <template slot="title">
                            <span>{{$t('delete')}}</span>
                        </template>
                        <a-button @click="confirmDeleteCustomer(row)" type="danger" icon="delete"/>
                    </a-tooltip>
                </a-button-group>
            </template>
        </a-table>

        <!--customer info drawer-->
        <a-drawer
            :title="$t('customerInfo')"
            placement="right"
            :closable="true"
            :width="500"
            @close="onCloseCustomerInfoDrawer"
            :bodyStyle="bodyStyle"
            :visible="isCustomerInfoDrawerVisible"
        >
            <div :style="contentStyle">
                <div class="content-wrapper">
                    <div :key="key" v-for="(item,key) in selectedCustomer" class="info-item">
                        <template v-if="key === $t('fullName')">
                            <div class="item-label">{{ key }}:</div>
                            <div class="item-description">
                                <div>{{item === '' ? $t('noName') : item}}</div>
                            </div>
                        </template>
                        <template v-else-if="key === $t('phoneNumbers')">
                            <div class="item-label">{{ key }}:</div>
                            <div class="item-description">
                                <div v-if="item.length === 0">{{$t('noPhoneNumber')}}</div>
                                <template v-else>
                                    <div :key="index" v-for="(phone,index) in item">{{ phone }}</div>
                                </template>
                            </div>
                        </template>
                        <template v-else-if="key === $t('address')">
                            <div class="item-label">{{ key }}:</div>
                            <div class="item-description" v-if="item.length === 0">{{$t('noAddress')}}</div>
                            <div v-else class="item-description remark" v-html="item"></div>
                        </template>
                        <template v-else>
                            <div class="item-label">{{ key }}:</div>
                            <div class="item-description">{{ item }}</div>
                        </template>
                    </div>
                </div>
            </div>
        </a-drawer>


        <!--customer form-->
        <a-modal
            v-model="isCustomerFormVisible"
            :title="customerFormTitle"
            :width="600"
            centered
            @ok="submitCustomerForm"
            :okText="customerFormOkButtonText"
            :confirmLoading="isSubmittingCustomerForm"
            :cancelText="$t('cancel')"
        >
            <div @keyup.enter="submitCustomerForm">
                <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-item
                        :label="$t('fullName')">
                        <a-input v-model.trim="customerFormData.name" autocomplete="new-password" :placeholder="$t('fullNameExample')" />
                    </a-form-item>
                    <a-form-item
                        :label="$t('phoneNumbers')">
                        <div :key="`${index}-${phone.tempId}`"
                             v-for="(phone,index) in customerFormData.phoneNumbersObjectsList"
                             class="user-input-wrapper">
                            <a-input autocomplete="new-password" v-model.trim="phone.number" class="user-input"
                                     :placeholder="$t('phoneNumberExample')"/>
                            <template v-if="index === 0">
                                <a-tooltip>
                                    <template slot="title">
                                        {{$t('addPhoneNumber')}}
                                    </template>
                                    <a-button @click="addNewPhoneNumber" type="primary" shape="circle" icon="plus"/>
                                </a-tooltip>
                            </template>
                            <template v-else>
                                <a-tooltip>
                                    <template slot="title">
                                        {{$t('removePhoneNumber')}}
                                    </template>
                                    <a-button @click="deletePhoneNumber(index)" type="danger" shape="circle"
                                              icon="delete"/>
                                </a-tooltip>
                            </template>
                        </div>
                    </a-form-item>
                </a-form>
            </div>
        </a-modal>


    </div>
</template>

<script>
import {currentUser, drawerSetups, errorHandler, fuseInstance, successHandler, validation} from "@/services/utils";
import http from "@/http";

export default {
    name: "Customers",
    data() {
        return {
            isFetchingCustomers: false,
            tempIndexId: 0,
            labelCol: {
                xs: {span: 6}
            },
            wrapperCol: {
                xs: {span: 18}
            },
            bodyStyle: drawerSetups.bodyStyle,
            contentStyle: drawerSetups.contentStyle,
            isCustomerInfoDrawerVisible: false,
            isCustomerFormVisible: false,
            isUpdateForm: false,
            isSubmittingCustomerForm: false,
            customerFormData: {
                name: '',
                address: '',
                phoneNumbersObjectsList: [],
                phoneNumbers: []
            },
            customerFormReset: {
                name: '',
                address: '',
                phoneNumbersObjectsList: [
                    {
                        tempId: Math.random() * 1000,
                        number: ''
                    }
                ],
                phoneNumbers: []
            },
            selectedCustomer: {},
            rawCustomers: [],
            customers: [],
            keywords: '',
            searchKeys: ['name'],
            columns: [
                {
                    title: this.$t('serialNumber'),
                    dataIndex: 'serialNumber',
                    scopedSlots: {customRender: 'serialNumber'},
                    align: 'center',
                    width: '70px'
                },
                {
                    title: this.$t('fullName'),
                    dataIndex: 'name',
                    scopedSlots: {customRender: 'customerName'},
                    sorter: this.fullNameSorter
                },
                {
                    title: this.$t('phoneNumbers'),
                    dataIndex: 'phoneNumbers',
                    width: '200px',
                    scopedSlots: {customRender: 'phoneNumbers'}
                },
                {
                    title: this.$t('actions'),
                    dataIndex: 'actions',
                    width: '140px',
                    align: 'center',
                    scopedSlots: {customRender: 'actions'}
                }
            ],
            pagination: {
                simple: true,
                current: 1,
                pageSize: 1500,
                total: 1
            },
            tableSorter: null,
        }
    },
    computed: {
        customerFormTitle() {
            return this.isUpdateForm ? this.$t('updateCustomerInfo') : this.$t('addNewCustomer');
        },
        customerFormOkButtonText() {
            return this.isUpdateForm ? this.$t('update') : this.$t('submit');
        },
    },
    methods: {
        confirmDeleteCustomer(customer) {
            this.$confirm({
                title: this.$t('deleteConfirmation'),
                content: this.$t('deleteCustomerMessage',{name: customer.name}),
                okText: this.$t('yes'),
                okType: 'danger',
                cancelText: this.$t('cancel'),
                onOk: () => {
                    this.deleteCustomer(customer);
                }
            });
        },
        async deleteCustomer(customer) {
            try {
                let response = await http.delete('/customer',customer);
                if(response.data.code === 200) {
                    successHandler(this.$t('operationSuccessful'))
                    this.fetchAllCustomers();
                }
            } catch (e) {
                errorHandler(this.$t('networkError'));
            }
        },
        deletePhoneNumber(index) {
            this.customerFormData.phoneNumbersObjectsList.splice(index, 1);
        },
        addNewPhoneNumber() {
            let phoneNumberField = this.getPhoneNumberField('');
            this.customerFormData.phoneNumbersObjectsList.push(phoneNumberField);
        },

        async submitCustomerForm() {
          console.log('Adding customer')
            try {
                this.isSubmittingCustomerForm = true;
                this.customerFormData.phoneNumbers = this.customerFormData.phoneNumbersObjectsList.map((item)=>item.number)
                this.customerFormData.phoneNumbers = this.customerFormData.phoneNumbers.filter((item)=>item !== '')
                let response;
                if(this.isUpdateForm) {
                    response = await http.put('/customer',this.customerFormData);
                }else {
                    response = await http.post('/customer',this.customerFormData);
                }
                this.isSubmittingCustomerForm = false;
                console.log(`customer add response ${JSON.stringify(response.data)}`)
                if(response.data.code === 200) {
                    successHandler(this.$t('operationSuccessful'));
                    this.isCustomerFormVisible = false;
                    this.fetchAllCustomers();
                }
            } catch (e) {
                this.isSubmittingCustomerForm = false;
                errorHandler(this.$t('networkError'))
            }finally {

            }

          this.isCustomerFormVisible = false;
          this.isSubmittingCustomerForm = false;
          successHandler("Customer Added");


        },

        resetCustomerForm() {
            this.customerFormData = {
                ...this.customerFormReset,
                phoneNumbers: [],
                phoneNumbersObjectsList: this.customerFormReset.phoneNumbersObjectsList.map((item) => {
                    return {...item};
                })
            };
        },

        getPhoneNumberField(phoneNumber) {
            return {
                tempId: this.tempIndexId++,
                number: phoneNumber
            };
        },
        showUpdateCustomerForm(customer) {
            this.isUpdateForm = true;
            this.isCustomerFormVisible = true;
            this.$nextTick(() => {
                let phones = customer.phoneNumbers.length > 0 ?
                    customer.phoneNumbers.map((phoneNumber) => this.getPhoneNumberField(phoneNumber)) :
                    [{...this.getPhoneNumberField('')}];
                this.customerFormData = {
                    ...this.customerFormReset,
                    ...customer,
                    phoneNumbersObjectsList: phones
                }
            });
        },

        addNewCustomer() {
            this.isUpdateForm = false;
            this.resetCustomerForm();
            this.isCustomerFormVisible = true;
        },

        showCustomerDetailsDrawer(row) {
            this.selectedCustomer = {
                'Full Name': row.name,
                'Address': row.address,
                'Phone Numbers': row.phoneNumbers
            }
            this.isCustomerInfoDrawerVisible = true;
        },

        onCloseCustomerInfoDrawer() {
            this.isCustomerInfoDrawerVisible = false;
        },
        fullNameSorter(a,b) {
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        },
        onSearch() {
            let output = fuseInstance.search(this.searchKeys, this.rawCustomers, this.keywords);
            this.customers = [...output];
        },
        async fetchAllCustomers() {
            try{
                let params = {
                    pageNum: this.pagination.current,
                    itemsPerPage: this.pagination.pageSize
                }
                this.isFetchingCustomers = true;
                if(this.tableSorter) {
                    params.sorter = {
                        order: this.tableSorter.order,
                        field: this.tableSorter.field
                    }
                }

                let response = await http.get('/customers', params);
                this.isFetchingCustomers = false;
                if(response.data.code === 200) {
                    let serverData = response.data.data;
                    this.rawCustomers = serverData.result;
                    this.pagination.pageSize = serverData.itemsPerPage;
                    this.pagination.total = serverData.totalPages;
                    this.pagination.current = serverData.pageNum;
                    this.onSearch();
                }
            }catch (e) {
                console.log(e)
                this.isFetchingCustomers = false;
                errorHandler(this.$t('networkError'));
            }
        }
    },
    created() {
        this.fetchAllCustomers();
    }
}
</script>

<style lang="scss" scoped>
@import "../../../partials";
@import "../../../mixins";

.wrapper {
    background: white;
    padding: 15px;

    .table-options {
        @include tableOptions;
    }

    .activate-btn {
        background-color: #4caf50;
        color: white;
        border-top: 1px solid #4caf50;
        border-bottom: 1px solid #4caf50;
    }

    .reset-password-btn {
        background-color: #ff9800;
        color: white;
        border-top: 1px solid #ff9800;
        border-bottom: 1px solid #ff9800;
    }
}

.content-wrapper {
    @include drawerList;
}

.user-input-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;
}

.user-input {
    width: 85%;
}
</style>
