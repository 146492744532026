<template>

  <div style="width: 100%; padding: 32px">

    <div style="background-color:#fefefe; box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;">

      <!-- header -->
      <div
          style="padding: 16px 24px; border-bottom: 1px solid #a2d2ff;border-top: 1px solid #a2d2ff; background-color: #ecf6ff">
        <a-row>
          <a-col :span="14">
            <img :src="packageIcon" width="48" alt="" style="margin-right: 8px">
            <span class="cargo-info-h"> Cargo Information  {{ cargo?.trackingNumber??''}}</span>
          </a-col>
          <a-col :span="10">
            <div style="text-align: end">
              <a-spin :spinning="isSubmittingCargoForm">
                <a-button :size="'large'" @click="onBack" style="margin-right: 16px">Close</a-button>
                <a-button :size="'large'" @click="onSaveCargo" type="primary">Save</a-button>
              </a-spin>
            </div>
          </a-col>
        </a-row>
      </div>

      <!-- Content -->
      <div style="padding: 32px; margin-top: 24px">
        <a-row>

          <!-- Form -->
          <a-col :span="isNewCargo? 18 : 12">
            <div style="padding-right: 24px">
              <div style="background-color: #fafafa; padding: 24px; border: 1px solid #bee0ff; ">

                <h2 style="color: #1890ff;">Primary Information</h2>
                <hr style="height: 1px"/>
                <a-form :layout="'vertical'">
                  <a-form-item :label="'Transit Status'"
                               v-if="canUpdateCargoTransitStatus(cargoFormData.transitStatus) && isExistingCargo">
                    <a-select @change="onTransitStatusChanged" v-model="cargoFormData.transitStatus">
                      <a-select-option :key="item.value" v-for="item in transitStatusOptions" :value="item.value">
                        {{ item.title }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>

                  <a-form-item v-if="canUpdateCargoTransitStatus(cargoFormData.transitStatus)" :label="'Delivery Date'">
                    <a-date-picker
                        :key="`1-${new Date().getTime()}`"
                        :default-value="cargoFormData.deliveryDate"
                        format="DD-MM-YYYY"
                        placeholder="Date-Month-Year"
                        :allowClear="false"
                        @change="onDeliveryDateChange"
                    />
                  </a-form-item>

                  <p>
                    <img width="32px" v-bind:src="containerIcon" alt="">
                    Container ID: <span style="font-weight: bolder">{{ containerNumber }}</span>
                  </p>
                  <a-form-item
                      label="Exchage Rate">
                    <a-input style="width: 100%;" v-model="cargoFormData.exchangeRate"/>
                  </a-form-item>

                  <!-- Receiver -->
                  <a-form-item label="Receiver Information">
                    <div @click="showCustomerPickDialog('RECEIVER')" class="selected-customer btn-clickable">
                      <a-row>
                        <a-col :span="16" style="">
                          <ul v-if="isNotEmpty(cargoFormData.receiverCustomer?.id)" style="">
                            <li>Name: {{ cargoFormData.receiverCustomer?.name }}</li>
                            <li>Address: {{ cargoFormData.receiverCustomer?.address }}</li>
                            <li>Phones: {{ cargoFormData.receiverCustomer?.phoneNumber }}
                              {{ cargoFormData.receiverCustomer?.phoneNumber2 }} {{
                                cargoFormData.receiverCustomer?.phoneNumber3
                              }}
                            </li>
                          </ul>
                          <p v-else style="color: #9a9a9a; padding-left: 8px">Click to select receiver</p>
                        </a-col>

                        <a-col :span="8">
                          <div
                              style="width: 100%; float: right; text-align: end; padding-right: 16px; padding-bottom: 16px">
                            <i class="blue fa fa-chevron-down"></i>
                          </div>
                        </a-col>
                      </a-row>
                    </div>
                  </a-form-item>

                  <a-form-item
                      label="VAT Percentage">
                    <a-input style="width: 100%;" v-model="cargoFormData.vatPercent"/>
                  </a-form-item>

                  <div style="width: 100%; margin-bottom: 24px">

                  </div>

                  <a-form-item :label="'Official Remarks'">
                    <a-textarea rows="3" @keyup.enter.stop v-model.trim="cargoFormData.officialUseRemark"
                                autocomplete="new-password" placeholder="Official Use Remark"/>
                  </a-form-item>
                </a-form>
              </div>
            </div>
          </a-col>

          <!-- Payments -->
          <a-col :span="12" v-if="isExistingCargo">
            <div style="background-color: #fafafa;border: 1px solid #bee0ff; min-height: 420px">

              <!-- Payment header -->
              <div style=" padding: 24px;">
                <a-row>
                  <a-col span="12">
                    <h2 style="color: #1890ff;">Payments</h2>
                  </a-col>
                  <a-col span="12" style="text-align: end">
                    <a-button class="btn-action" @click="showTransactionForm"
                              type="default">
                      <i class="fa fa-money-bill" style="margin-right: 6px; color: #1890ff"></i>
                      Record Transaction
                    </a-button>
                  </a-col>
                </a-row>
              </div>
              <div class="blue-line"></div>

              <!-- Payment Body -->
              <div style="">
                <table class="fancy-table">

                  <!-- Costs breakdown -->
                  <!-- Sub total -->
                  <tr>
                    <td rowspan="3" colspan="2" style="background-color: #f3f3f3; font-size:1em; border-right: none;border-left: none; padding-top: 24px">
                    </td>

                    <td  style="background-color: #f3f3f3; border-right: none;border-left: none; padding-top: 24px"  >
                      Sub Total
                    </td>
                    <td  style="background-color: #f3f3f3; border-right: none;border-left: none; padding-top: 24px"  >
                        {{ cargo.amountBeforeVatUSD }} USD
                      <span style="font-size:1em; font-weight: lighter"> ({{ (cargo.amountBeforeVatUSD * cargoExchangeRate).toLocaleString() }} TZS)</span>
                    </td>
                  </tr>

                  <!-- VAT-->
                  <tr>
                    <td  style="background-color: #f3f3f3; border-right: none;border-left: none; padding-top: 24px"  >
                      VAT ({{cargo.vatPercent}})%
                    </td>
                    <td  style="background-color: #f3f3f3; border-right: none;border-left: none; padding-top: 24px"  >
                      {{ vatCost }} USD
                      <span style="font-size:1em; font-weight: lighter"> ({{ (vatCost * cargoExchangeRate).toLocaleString() }} TZS)</span>
                    </td>
                  </tr>

                  <!-- Grand Total-->
                  <tr>
                    <td  style="background-color: #f3f3f3; border-right: none;border-left: none; padding-top: 24px"  >
                      Grand Total
                    </td>
                    <td  style="background-color: #f3f3f3; border-right: none;border-left: none; padding-top: 24px"  >
                      {{ totalCost }} USD
                      <span style="font-size:1em; font-weight: lighter"> ({{ (totalCost * cargoExchangeRate).toLocaleString() }} TZS)</span>
                    </td>
                  </tr>
                  <!-- [End] Costs breakdown -->



                  <tr>
                    <th>Date</th>
                    <th>Action</th>
                    <th>Original Currency</th>
                    <th>Amount</th>

                  </tr>
                  <tr v-for="transaction in paymentTransactions">
                    <td>{{ toPrettyDate(transaction.customCreatedAt) }}</td>
                    <td>
                      <a-button @click="showTransactionRemovalForm(transaction)" class="btn-warning x-small"
                                size="small">
                        <i class="fa fa-times-circle" style="margin-right: 4px"></i>
                        Remove
                      </a-button>
                    </td>
                    <td>
                      <span v-if="transaction.originalCurrency!=='USD'">
                        {{ transaction.originalAmount }} {{ transaction.originalCurrency }} <br/>
                         <span class="blue" style="font-size: 0.8em;">1 USD = {{ transaction.exchangeRate }} {{ transaction.originalCurrency }}</span>
                      </span>
                    </td>
                    <td>{{ transaction.amount }} USD</td>
                  </tr>


                  <tr>
                    <td style="padding: 8px" colspan="5"></td>
                  </tr>


                  <!-- Total Paid -->
                  <tr>
                    <td class="total-bg"  colspan="3"> Total Amount Paid</td>
                    <td class="total-bg total-values"  style="color: #4caf50">
                      {{ totalPaid }} USD
                      <span style="font-size: 12px; font-weight: lighter"> ({{ (totalPaid* cargoExchangeRate).toLocaleString() }} TZS) </span>
                    </td>
                  </tr>

                  <!-- Remaining -->
                  <tr v-if="remainingAmount>0">
                    <td class="total-bg" colspan="3"> Remaining Amount</td>
                    <td class="total-bg total-values" style="color: #e63946" >
                      {{ remainingAmount }} USD
                      <span style="font-size: 12px; font-weight: lighter"> ({{ (remainingAmount* cargoExchangeRate).toLocaleString() }} TZS)  </span>
                    </td>
                  </tr>



                </table>
              </div>

            </div>
          </a-col>

        </a-row>

        <!-- Items Table-->
        <div style="padding-top: 64px">
          <div style="border: 1px solid #bde0fe; padding: 16px 0; margin-bottom: 24px">
            <div style="margin-bottom: 16px; padding: 0 16px">
              <a-row>
                <a-col :span="12">
                  <h2 v-bind:style="{disable:'inline-block',color:'#1890ff'}">
                    <img :src="itemsIcon" width="48" alt="" style="margin-right: 8px">
                    Packed Items
                  </h2>
                </a-col>
                <a-col :span="12" style="text-align: end">
                  <a-button @click="showAddPackageDialog" type="primary" icon="plus">
                    Add Item
                  </a-button>
                </a-col>
              </a-row>
            </div>

            <div>
              <a-table
                  :size="'middle'"
                  :rowKey="'id'"
                  bordered
                  :columns="itemsTableColumns"
                  :data-source="cargoFormData.packingList"
                  :pagination="false"
              >

                <template slot="serial" slot-scope="text, row">
                  <div>{{ row.serial }}</div>
                </template>
                <template slot="englishName" slot-scope="text, row">
                  <div v-if="text.trim() === ''">{{ $t('noContent') }}</div>
                  <div v-else>{{ text }}</div>
                </template>
                <template slot="price" slot-scope="text, row">
                  <div style="text-transform: capitalize;">
                    {{ text }} USD/<span style="text-transform: uppercase">
                            {{ row.quantityMeasure === 'other' ? row.customQuantityMeasure : row.quantityMeasure }}
                          </span>
                  </div>
                </template>
                <template slot="quantity" slot-scope="text, row">
                  <div>{{ text }}</div>
                </template>
                <template slot="amount" slot-scope="text, row">
                  <div>{{ getSinglePackingItemAmount(row) }}</div>
                </template>

                <!--actions-->
                <template slot="actions" slot-scope="text, row, index">
                  <a-button size="small" style="border: 1px solid #1890ff; color: #1890ff"
                            @click="showItemCustomizationForm(index)"
                            type="default"> Edit
                  </a-button>

                  <a-button size="small" style="border: 1px solid #e63946; color: #e63946; margin-left: 1em; margin-top: 12px"
                            @click="onRemovePackedItem(index)"
                            type="default"> Delete
                  </a-button>
                </template>
                <!--table footer-->

              </a-table>
            </div>

            <h3 style="padding: 16px"><span style="font-weight: normal">Total Amount Due:</span> {{ totalCost }} USD
            </h3>

          </div>
        </div>
      </div>

    </div>


    <!--customer form for a quick customer contact addition-->
    <a-modal
        v-model="isCustomerPickerDialogVisible"
        title="Select Customer"
        :width="720"
        centered
        @ok="onConfirmCustomerSelection"
        okText="Confirm"
        cancelText="Cancel"
        :confirmLoading="isLoadingCustomer">

      <div>

        <div style="padding: 16px">

          <!-- customer search form -->
          <a-row style="margin-bottom: 32px">
            <a-col>
              <a-input-search
                  v-model:value="customerSearchValue"
                  placeholder="Customer name or phone number "
                  enter-button="Search"
                  size="large"
                  @search="onSearchCustomer"
              />
            </a-col>
          </a-row>


          <a-button size="small" v-if="!isNewCustomerFormVisible" class="btn-action" @click="showNewCustomerForm()"
                    type="default">
            <i class="fa fa-user-plus" style="margin-right: 6px; color: #1890ff"></i>
            Add New Customer
          </a-button>

          <div style="padding: 24px 0; " v-if="customerList.length>0">
            <table class="fancy-table">
              <tr>
                <th></th>
                <th>Name</th>
                <th>Phones</th>
                <th>Address</th>
              </tr>
              <tr v-for="customer in customerList">

                <td>
                  <a-button @click="onCustomerSelected(customer)" class="btn-success x-small" size="small">
                    <i class="fa fa-check-circle" style="margin-right: 4px"></i>
                    Select
                  </a-button>
                </td>

                <td>{{ customer.name }}</td>

                <td>{{ customer.phoneNumber }} <br/>
                  {{ customer.phoneNumber2 }}<br/>
                  {{ customer.phoneNumber3 }}
                </td>

                <td> {{ customer.address }}</td>

              </tr>
            </table>
          </div>

          <!-- New Customer Form -->
          <div v-if="isNewCustomerFormVisible" style="border: 1px solid #1890ff; padding: 16px; margin: 24px">
            <a-row style="margin-bottom: 12px">
              <a-col span="12"><h3 style="margin: 0; padding: 0" class="blue"><i class="fa fa-user-plus"></i> Add Customer
              </h3></a-col>
              <a-col span="12" style="text-align: end">
                <a-button @click="closeCustomerForm" type="link">
                  <i style="color: #ff9800; font-size: 1.4em" class="fa fa-times-circle"></i></a-button>
              </a-col>
            </a-row>
            <a-form layout="vertical">

              <a-form-item label="Full Name">
                <a-input v-model.trim="customerInstance.name" placeholder="Customer name or Customer company name"/>
              </a-form-item>

              <a-form-item label="Customer Address">
                <a-input v-model.trim="customerInstance.address" placeholder="Country, Region, District, Town, Street"/>
              </a-form-item>

              <a-form-item label="Phone Number(s)">
                <div :key="`${index}_${phone.tempId}`"
                     v-for="(phone,index) in customerInstance.phoneNumbersSlots"
                     class="user-input-wrapper" style="margin-bottom: 20px">
                  <a-row>
                    <a-col span="12">
                      <a-input v-model.trim="phone.number" class="user-input" placeholder="Example 255745666777"/>
                    </a-col>
                    <a-col span="12" style="text-align: end">
                      <a-button class="btn-warning" v-if="index > 0" @click="removePhoneNumberSlot(index)" type="default"
                                icon="delete">
                        Remove
                      </a-button>
                    </a-col>
                  </a-row>
                </div>

                <a-button class="btn-action" @click="addPhoneNumberSlot" type="default" icon="plus"
                          style="margin-top: 1px">
                  Add Phone Number
                </a-button>
              </a-form-item>


            </a-form>
          </div>


        </div>


      </div>
    </a-modal>

    <!--categories selection modal-->
    <a-modal
        v-model="isCategoriesSelectionVisible"
        :title="$t('selectCargoCategory')"
        :width="800"
        centered
        @ok="onCategorySelected"
        :okText="$t('done')"
        :cancelText="$t('cancel')">

      <div style="max-height: 300px; overflow-y: auto;">

        <div class="table-options align-right">
          <div class="right">
            <a-input-search style="width: 300px;" v-model="categoriesKeywords" :placeholder="$t('categoryName')"
                            enter-button @search="onSearchCategories"/>
          </div>
        </div>

        <a-table
            :size="'middle'"
            :rowKey="'id'"
            bordered
            :columns="categoriesColumns"
            :data-source="categories"
            :pagination="false"
            :row-selection="categoryRowSelection()"
        >
          <template slot="englishName" slot-scope="text, row">
            <div v-if="text.trim() === ''">{{ $t('noContent') }}</div>
            <div v-else>{{ text }}</div>
          </template>
          <template slot="swahiliName" slot-scope="text, row">
            <div v-if="text.trim() === ''">{{ $t('noContent') }}</div>
            <div v-else>{{ text }}</div>
          </template>
          <template slot="chineseName" slot-scope="text, row">
            <div v-if="text.trim() === ''">{{ $t('noContent') }}</div>
            <div v-else>{{ text }}</div>
          </template>
          <template slot="price" slot-scope="text, row">
            <div style="text-transform: capitalize;">
              {{ text }}US$/<span
                style="text-transform: uppercase">{{
                row.quantityMeasure === 'other' ? row.customQuantityMeasure : row.quantityMeasure
              }}</span>
            </div>
          </template>
        </a-table>
      </div>

    </a-modal>

    <!--customize packing list item form-->
    <a-modal
        v-model="isCustomPackingListItemFormVisible"
        :title="'Modify Item'"
        :width="600"
        centered
        @ok="savePackageItem"
        :okText="$t('done')"
        :cancelText="$t('cancel')">
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item
            :label="$t('englishName')">
          <a-input autocomplete="new-password" placeholder="example:Bike Spare Parts"
                   v-model.trim="selectedPackingListItem.englishName"/>
        </a-form-item>
        <a-form-item
            label="Quantity Measure">
          <a-select v-model="selectedPackingListItem.quantityMeasure">
            <a-select-option :key="key" v-for="(item,key) in quantityMeasures" :value="item">
              {{ key }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="selectedPackingListItem.quantityMeasure === 'other'"
                     label="Other Measure">
          <a-input v-model.trim="selectedPackingListItem.customQuantityMeasure" autocomplete="new-password"
                   placeholder="Custom Quantity Measure"/>
        </a-form-item>
        <a-form-item label="Price In USD">
          <a-input-number style="width: 100%;" v-model="selectedPackingListItem.price" autocomplete="new-password"
                          placeholder="Price In US$"/>
          <div>Price: {{ selectedPackingListItem.price }} USD/<span
              style="text-transform: uppercase">{{
              selectedPackingListItem.quantityMeasure === 'other' ? selectedPackingListItem.customQuantityMeasure : selectedPackingListItem.quantityMeasure
            }}</span></div>
        </a-form-item>

        <a-form-item label="Quantity">
          <a-input-number style="width: 100%;"  v-model="selectedPackingListItem.quantity" placeholder="Quantity Measure"/>
        </a-form-item>

        <a-form-item label="BALE">
          <a-input style="width: 100%;"  v-model="selectedPackingListItem.baleNo" placeholder="Bale"/>
        </a-form-item>

        <a-form-item label="Receipt Number">
          <a-input style="width: 100%;"  v-model="selectedPackingListItem.receiptNumber" placeholder="Receipt No"/>
        </a-form-item>

        <a-form-item label="Number Of Pieces">
          <a-input-number style="width: 100%;"  v-model="selectedPackingListItem.numberOfPieces" placeholder="Total Pieces"/>
        </a-form-item>

        <a-form-item label="Number Of Packages">
          <a-input-number style="width: 100%;"  v-model="selectedPackingListItem.numberOfPackages" placeholder="Packages count"/>
        </a-form-item>

        <a-form-item label="Status">
          <a-select style="width: 100%;"  @change="onTransitStatusChanged" v-model="selectedPackingListItem.status">
            <a-select-option key="Shipped" value="Shipped">
              Shipped
            </a-select-option>
            <a-select-option key="Pending" value="Pending">
              Pending
            </a-select-option>
          </a-select>
        </a-form-item>

      </a-form>
    </a-modal>

    <!-- Payment Transaction Form-->
    <a-modal
        v-model="isTransactionFormVisible"
        title="Record Customer Payment"
        :width="600"
        centered
        @ok="onSaveTransaction"
        okText="Save"
        :confirmLoading="isSavingTransaction"
        cancelText="Cancel">

      <div>
        <a-form layout="vertical">
          <a-form-item label="Amount Paid">
            <a-input-number class="block-input" v-model.trim="transactionInstance.amount"
                            placeholder="Amount paid"/>
          </a-form-item>

          <a-row>
            <a-col :span="isUsdTransaction ? 24 : 12" style="padding-right: 16px;">
              <a-form-item label="Currency">
                <a-select @change="onTransitStatusChanged" v-model="transactionInstance.currency">
                  <a-select-option key="USD" value="USD">
                    USD
                  </a-select-option>
                  <a-select-option key="USD" value="TSH">
                    TSH
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col span="12" v-if="!isUsdTransaction">
              <a-form-item label="Exchange Rate">
                <a-input-number class="block-input" v-model.trim="transactionInstance.exchangeRate"
                                placeholder="Exchange Rate"/>
              </a-form-item>
            </a-col>
          </a-row>


          <a-form-item label="Payment Reference">
            <a-input class="block-input" v-model.trim="transactionInstance.thirdPartyReferenceNumber"
                     placeholder="Payment Reference (if available)"/>
          </a-form-item>

          <a-form-item label="Remark">
            <a-input class="block-input" v-model.trim="transactionInstance.remark" placeholder="Any notes"/>
          </a-form-item>

        </a-form>

      </div>
    </a-modal>

    <!-- Transaction Removal Form-->
    <a-modal
        v-model="isTransactionRemovalFormVisible"
        title="Remove Transaction"
        :width="600"
        centered
        @ok="onRemoveTransaction"
        okText="Remove Transaction"
        :confirmLoading="isSavingTransaction"
        cancelText="Cancel">

      <div>
        <a-form layout="vertical">

          <ul style="padding-left: 16px; margin-bottom: 32px">
            <li>Transaction Amount: <span style="font-weight: bold">
              {{ transactionRemovalData.transaction?.amount }}
              {{ transactionRemovalData.transaction?.currency }}</span></li>
            <li>Transaction Date: <span
                style="font-weight: bold">{{ toPrettyDate(transactionRemovalData.transaction?.createdAt) }}</span></li>
          </ul>

          <a-form-item label="Reason to remove">
            <a-textarea rows="3" v-model.trim="transactionRemovalData.reason"
                        placeholder="Why do you want to delete this transaction"/>
          </a-form-item>
        </a-form>

      </div>
    </a-modal>

    <a-modal
        v-model="isItemRemovalVisible"
        title="Confirm"
        :width="600"
        centered
        @ok="removePackedItem"
        okText="Continue removing"
        cancelText="Cancel">
      <div>

        <p>
          Are you sure you want to remove selected item?
        </p>

      </div>
    </a-modal>

  </div>
</template>

<script>

import {errorHandler, fuseInstance, successHandler} from "@/services/utils";
import http from "@/http";
import moment from "moment"
import {getTodayDate, isEmpty, isNotEmpty, roundToDecimalPlaces, toPrettyDate} from "@/utils/helpers";
import {canUpdateCargoTransitStatus, transitStatusOptions} from "@/utils/cargo-utils";


const CUSTOMER_SENDER = 'SENDER';
const CUSTOMER_RECEIVER = 'RECEIVER';

export default {

  name: "CargoForm",

  data() {
    return {
      isSubmittingCargoForm: false,
      transitStatusOptions: transitStatusOptions,
      packageIcon: require('@/assets/icons/package.png'),
      itemsIcon: require('@/assets/icons/items.png'),
      containerIcon: require('@/assets/icons/container.png'),

      cargoFormData: {
        transitStatus: 4,
        deliveryDate: getTodayDate(),
        trackingNumber: '',
        receiverInfo: {},
        containerId: '',
        containerInfo: {},
        packingList: [],
        paidAmountUSD: 0,
        selectedCategoriesIds: [],
        packagesNumber: 1,
        officialUseRemark: '',
        receiptNote: '',
        receiptNumber: '',
        vatPercent: 0,
        senderCustomer: {
          id: '',
          name: '',
          address: '',
          phoneNumber: '',
          phoneNumber2: '',
          phoneNumber3: ''
        },
        receiverCustomer: {
          id: '',
          name: '',
          address: '',
          phoneNumber: '',
          phoneNumber2: '',
          phoneNumber3: ''
        },
      },

      itemsTableColumns: [
        {
          title: "S/N",
          dataIndex: 'serial',
          scopedSlots: {customRender: 'serial'}
        },
        {
          title: "Item",
          dataIndex: 'englishName',
          scopedSlots: {customRender: 'englishName'}
        },
        {
          title: "Bale Number",
          dataIndex: 'baleNo',
          scopedSlots: {customRender: 'baleNo'}
        },
        {
          title: "Receipt Number",
          dataIndex: 'receiptNumber',
          scopedSlots: {customRender: 'receiptNumber'}
        },
        {
          title: "No. Pieces",
          dataIndex: 'numberOfPieces',
          scopedSlots: {customRender: 'numberOfPieces'}
        },
        {
          title: "No. Packages",
          dataIndex: 'numberOfPackages',
          scopedSlots: {customRender: 'numberOfPackages'}
        },
        {
          title: "Price (USD)",
          dataIndex: 'price',
          width: '150px',
          scopedSlots: {customRender: 'price'}
        },
        {
          title: "Quantity",
          dataIndex: 'quantity',
          scopedSlots: {customRender: 'quantity'}
        },
        {
          title: "Amount (USD)",
          dataIndex: 'amount',
          scopedSlots: {customRender: 'amount'}
        },
        {
          title: "Status",
          dataIndex: 'status',
          width: '98px',
          scopedSlots: {customRender: 'status'}
        },
        {
          title: "Action",
          dataIndex: 'actions',
          scopedSlots: {customRender: 'actions'}
        },
      ],

      currency: 1,
      isCurrencyConverterDialogVisible: false,
      isCategoriesSelectionVisible: false,
      containerNumber: '',
      containerId: '',
      searchKeys: ['customerName', 'trackingNumber'],
      categoriesSearchKeys: ['englishName', 'swahiliName', 'chineseName'],
      transitStatus: [
        {
          value: 4,
          title: this.$t('dischargedFromPort')
        },
        {
          value: 5,
          title: this.$t('deliveredToReceiver')
        }
      ],
      overallTransitStatus: [
        {
          value: 1,
          title: this.$t('loadingCargo')
        },
        {
          value: 2,
          title: this.$t('onTheWay')
        },
        {
          value: 3,
          title: this.$t('delayed')
        },
        {
          value: 4,
          title: this.$t('dischargedFromPort')
        },
        {
          value: 5,
          title: this.$t('deliveredToReceiver')
        }
      ],


      isUpdateForm: false,
      labelCol: {
        xs: {span: 8}
      },
      wrapperCol: {
        xs: {span: 16}
      },

      keywords: '',
      categoriesKeywords: '',
      pagination: {
        simple: true,
        current: 1,
        pageSize: 600,
        total: 1
      },

      categories: [],
      invisibleCategoriesIds: [],
      rawCategories: [],
      categoriesColumns: [
        {
          title: this.$t('englishName'),
          dataIndex: 'englishName',
          scopedSlots: {customRender: 'englishName'}
        },
        {
          title: this.$t('swahiliName'),
          dataIndex: 'swahiliName',
          scopedSlots: {customRender: 'swahiliName'}
        },
        {
          title: this.$t('chineseName'),
          dataIndex: 'chineseName',
          scopedSlots: {customRender: 'chineseName'}
        },
        {
          title: this.$t('priceUSD'),
          dataIndex: 'price',
          width: '150px',
          scopedSlots: {customRender: 'price'}
        }
      ],
      temporarySelectedCategories: [],
      temporarySelectedCategoriesIds: [],


      isFetchingCargoDetails: true,
      cargo: {
         id: '',
          paidAmountUSD: 0,
         amountBeforeVatUSD: 0,
         amountDueUSD: 0,
         vatPercent: 0,
      },
      isCargoFormVisible: false,
      cargoTableSorter: null,
      cargos: [],
      rawCargos: [],
      containerInfo: {},
      isFetchingCargos: false,
      isCustomPackingListItemFormVisible: false,
      quantityMeasures: {
        'CBM': 'cbm',
        'Tonne': 'tonne',
        'Piece': 'piece',
        'Bale': 'bale',
        'Other': 'other'
      },
      packingItemIndex: 0,
      selectedPackingListItem: {
        englishName: '',
        quantityMeasure: '',
        price: '',
        customQuantityMeasure: '',
        quantity: 1,
        numberOfPackages: 1,
        baleNo: '',
        receiptNumber: '',
        numberOfPieces: 1,
        status: 1
      },

      isTransactionFormVisible: false,
      isSavingTransaction: false,
      transactionInstance: {
        cargoId: '',
        amount: '',
        currency: 'USD',
        exchangeRate: 1,
        thirdPartyReferenceNumber: '',
        remark: '',
      },
      isTransactionRemovalFormVisible: false,
      transactionRemovalData: {
        reason: '',
        transaction: {},
      },
      paymentTransactions: [],

      customerList: [],
      selectedCustomerType: 'SENDER',
      isLoadingCustomer: false,
      isCustomerPickerDialogVisible: false,
      isNewCustomerFormVisible: false,
      customerSearchValue: '',
      customerInstance: {
        name: '',
        address: '',
        phoneNumbersSlots: [],
        phoneNumbers: []
      },

      isItemRemovalVisible: false,
      packedItemIndex: -1,
    }
  },

  computed: {
    isNewCargo() {
      return isEmpty(this.cargo?.id)
    },

    isExistingCargo() {
      return isNotEmpty(this.cargo?.id)
    },

    vatCost() {
      if(isEmpty(this.cargo.vatPercent)){
        return 0;
      }
      if((this.cargo.vatPercent)<=0){
        return 0;
      }

      const vatMultiplier = (this.cargo.vatPercent/100);
      const vatAmount = this.cargo.amountBeforeVatUSD * vatMultiplier;
      return roundToDecimalPlaces(vatAmount)
    },

    subTotalCost() {
      return this.cargo.amountBeforeVatUSD;
    },

    totalCost() {
      return this.cargo.amountDueUSD;
    },

    totalPaid() {
      return this.cargo.paidAmountUSD;
    },

    isUsdTransaction() {
      if (this.transactionInstance === null) {
        return false;
      }
      return this.transactionInstance.currency === 'USD';
    },

    remainingAmount(){
      const cost = this.totalCost;
      const paid = this.totalPaid;
      if(paid>cost){
        return 0;
      }
      return  roundToDecimalPlaces((cost -paid))
    },

    cargoExchangeRate(){
      if(isEmpty(this.cargo.exchangeRate)){
        return 1;
      }
      return this.cargo.exchangeRate;
    }

  },

  methods: {
    isNotEmpty,
    toPrettyDate,

    canUpdateCargoTransitStatus,

    fetchCargoDetails() {
      this.isFetchingCargoDetails = true;
      const uri = `cargo/details?cargoId=${this.cargoId}`;
      console.log(uri)

      http.get(uri).then((response) => {
        this.cargo = response.data.data
        this.onCargoLoaded()
      }).catch((err) => {
        console.log(err)
      });
    },

    showAddPackageDialog() {
      this.temporarySelectedCategories = [...this.cargoFormData.packingList]
      this.temporarySelectedCategoriesIds = [...this.cargoFormData.selectedCategoriesIds]
      this.categoriesKeywords = '';
      this.onSearchCategories();
      this.isCategoriesSelectionVisible = true;
    },

    customizeCurrencyConverter() {
      this.cargoFormData.exchangeRate = this.currency;
      this.isCurrencyConverterDialogVisible = false;
    },


    roundToStringCurrency(amount) {
      return ((Math.round(amount * 100) / 100).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    getUnpaidAmountUSD(item, isNumeric = true) {
      let paidAmountUSD = item.paidAmountUSD;
      let totalUSD = 0;
      item.packingList.forEach((packingItem) => {
        totalUSD = totalUSD + (packingItem.price * packingItem.quantity)
      });
      let unpaidAmountUSD = totalUSD - paidAmountUSD
      if (isNumeric) {
        return roundToDecimalPlaces(unpaidAmountUSD)
      }
      return this.roundToStringCurrency(unpaidAmountUSD);
    },
    getSinglePackingItemAmount(item) {
      let total = item.price * item.quantity;
      return roundToDecimalPlaces(total);
    },

    onDeliveryDateChange(momentInstance) {
      this.cargoFormData.deliveryDate = momentInstance
    },
    getTodayDate() {
      return moment(new Date().getTime())
    },
    onTransitStatusChanged(status) {
      if (status === this.transitStatus[this.transitStatus.length - 1].value) {
        this.cargoFormData.deliveryDate = this.getTodayDate()
      }
    },

    onSaveCargo(close=true) {

      if (this.cargoFormData.departureDate === "") {
        this.cargoFormData.departureDate = this.getTodayDate();
      }
      if (this.cargoFormData.arrivalDate === "") {
        this.cargoFormData.arrivalDate = this.getMonthLaterDate();
      }
      if (this.cargoFormData.paidAmountUSD === null || this.cargoFormData.paidAmountUSD === "") {
        this.cargoFormData.paidAmountUSD = 0;
      }

      //Validate receiver
      if (isEmpty(this.cargoFormData.receiverCustomer?.id)) {
        errorHandler("Please select a receiver")
        return;
      }


      if (this.cargoFormData.packingList.length === 0) {
        errorHandler("Please select at least one item")
        return;
      }

      const uri = isNotEmpty(this.cargo.id) ? '/cargo/update' : '/cargo/create';
      console.log(JSON.stringify(this.cargoFormData?.senderCustomer))
      const payload = {
        ...this.cargoFormData,
        senderId: this.cargoFormData?.senderCustomer?.id,
        receiverId: this.cargoFormData?.receiverCustomer?.id
      };
      console.log('updating cargo ' + uri, JSON.stringify(payload))

      this.isSubmittingCargoForm = true;
      http.post(uri, payload)
          .then((resp) => {
            console.log(resp.data)
            successHandler("Success! Cargo Saved")
            if(close){
              this.onBack();
            }else {
              this.reload();
            }
          }).catch((e) => {
        console.log(e)
        successHandler("Error! Failed to save cargo")
      }).finally(() => {
        this.isSubmittingCargoForm = false;
      })

    },

    fetchCategories() {
      http.get('/categories').then((resp) => {
        let serverData = resp.data.data;
        this.rawCategories = serverData.result;
      }).catch((err) => {
        console.log(err)
        errorHandler("Failed to load resources: categories")
      });
    },

    onSearchCategories() {
      let output = fuseInstance.search(this.categoriesSearchKeys, this.rawCategories, this.categoriesKeywords);
      this.categories = [...output];
      this.invisibleCategoriesIds = this.rawCategories.filter((item) => {
        let isInvisible = true
        for (let i = 0; i < this.categories.length; i++) {
          if (item.id === this.categories[i].id) {
            isInvisible = false;
            break;
          }
        }
        return isInvisible
      }).map((item) => item.id)
    },

    onCargoLoaded() {
      this.isUpdateForm = true;
      this.isCargoFormVisible = true;
      const transitStatus = this.canUpdateCargoTransitStatus(this.cargo.containerInfo?.transitStatus ?? '') ? this.cargo.transitStatus ?? '' : this.cargo.containerInfo?.transitStatus ?? '';

      if (isEmpty(this.cargo.id)) {
        return;
      }


      this.assignItemsSerials();

      this.$nextTick(() => {
        this.cargoFormData = {
          ...this.cargo,
          transitStatus: transitStatus,
          deliveryDate: moment(this.cargo.deliveryDate),
          packingList: this.cargo.packingList,
          selectedCategoriesIds: this.cargo.packingList.map((item) => item.id),
        }
      });


    },

    onBack() {
      this.$router.go(-1);
    },
    reload() {
      location.reload()
    },

    onCategorySelected() {
      this.temporarySelectedCategories.forEach((newItem) => {
        let foundItem = this.cargoFormData.packingList.find((item) => item.id === newItem.id)
        if (foundItem) {
          newItem.quantity = foundItem.quantity;
          newItem.price = foundItem.price;
          newItem.englishName = foundItem.englishName;
          newItem.quantityMeasure = foundItem.quantityMeasure;
          newItem.customQuantityMeasure = foundItem.customQuantityMeasure;

          newItem.numberOfPieces = foundItem.numberOfPieces;
          newItem.numberOfPackages = foundItem.numberOfPackages;
          newItem.receiptNumber = foundItem.receiptNumber;
          newItem.baleNo = foundItem.baleNo;
          newItem.status =  foundItem.status;

        } else {
          newItem.quantity = 1;
          newItem.numberOfPieces = 1;
          newItem.numberOfPackages = 1;
          newItem.baleNo = '';
          newItem.receiptNumber = '';
          newItem.status = 'Pending';
        }
      })

      this.cargoFormData.packingList = [...this.temporarySelectedCategories];
      this.cargoFormData.selectedCategoriesIds = [...this.temporarySelectedCategoriesIds];
      this.isCategoriesSelectionVisible = false
      this.temporarySelectedCategories = [];
      this.temporarySelectedCategoriesIds = [];
      this.categoriesKeywords = '';

      this.assignItemsSerials();
    },

    categoryRowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          let selectedButInvisibleList = this.temporarySelectedCategories.filter((item) => {
            return this.invisibleCategoriesIds.indexOf(item.id) > -1
          });
          let selectedButInvisibleIds = selectedButInvisibleList.map((item) => item.id);

          this.temporarySelectedCategories = [...selectedButInvisibleList, ...selectedRows];
          this.temporarySelectedCategoriesIds = [...selectedButInvisibleIds, ...selectedRowKeys];
        },
        selectedRowKeys: this.temporarySelectedCategoriesIds
      }
    },


    /*** Packing list **/
    showItemCustomizationForm(index) {
      console.log(`editing ${index}`, JSON.stringify(this.cargoFormData.packingList[this.packingItemIndex]))
      this.packingItemIndex = index;
      this.selectedPackingListItem = {
        ...this.cargoFormData.packingList[this.packingItemIndex]
      }
      this.isCustomPackingListItemFormVisible = true;
    },

    onRemovePackedItem(index) {
      this.isItemRemovalVisible = true;
      this.packingItemIndex = index;

    },

    removePackedItem(){
      console.log(`remove item at ${this.packingItemIndex}`)
      this.cargoFormData.packingList.splice(this.packingItemIndex,1)
      this.packingItemIndex = -1;
      this.isItemRemovalVisible = false;
    },

    savePackageItem() {

      console.log(`Saving ${JSON.stringify(this.selectedPackingListItem)}`)

      //remove item;
      this.cargoFormData.packingList = this.cargoFormData.packingList.filter(item => item.id !== this.selectedPackingListItem?.id);

      //re-add item: [This triggers UI refresh)
      this.cargoFormData.packingList.push(this.selectedPackingListItem);
      this.cargoFormData.packingList.sort((a, b) => a.serial - b.serial);

      //Reset modal form
      this.isCustomPackingListItemFormVisible = false;
      this.selectedPackingListItem = {}

      if(isNotEmpty(this.cargo.id)){
        console.log(`an update`)
        this.onSaveCargo(false);
      }
    },

    assignItemsSerials() {
      this.cargoFormData.packingList.forEach((item, index) => {
        item.serial = index + 1;
      })
    },

    /** Payment Transactions **/
    showTransactionForm() {
      this.transactionInstance = {currency: 'USD', exchangeRate: 1};
      this.transactionInstance.cargoId = this.cargo.id;
      this.isTransactionFormVisible = true;
    },

    onSaveTransaction() {
      this.isSavingTransaction = true;

      http.post('/cargo/payments/add', this.transactionInstance).then((resp) => {
        successHandler("Payment Recorded");
        console.log(`Payment Recorded... ${JSON.stringify(resp)}`)
        this.isTransactionFormVisible = false;
        this.fetchCargoPayments();

      }).catch((err) => {
        console.log(err)
        errorHandler("Failed to save payment");
      }).finally(() => {
        this.isSavingTransaction = false;
        window.location.reload();
      })
    },

    showTransactionRemovalForm(selectedTransaction) {
      this.isTransactionRemovalFormVisible = true;
      this.transactionRemovalData = {
        reason: '',
        transaction: selectedTransaction
      };
    },

    onRemoveTransaction() {
      this.isSavingTransaction = true;

      http.post('/cargo/payments/remove', {
        deletionRemark: this.transactionRemovalData.reason,
        transactionId: this.transactionRemovalData.transaction?.id,
      }).then((resp) => {
        successHandler("Payment removed");
        this.isTransactionRemovalFormVisible = false;
        this.fetchCargoPayments();

      }).catch((err) => {
        console.log(err)
        errorHandler("Failed to transaction");
      }).finally(() => {
        this.isSavingTransaction = false;
        window.location.reload();
      })
    },

    fetchCargoPayments() {
      const uri = `/cargo/payments/get?cargoId=${this.cargoId}`;
      console.log(`fetching cargoes ${uri}`)
      http.get(uri).then((resp) => {
        this.paymentTransactions = resp.data.payload
      }).catch((err) => {
        console.log(err)
        errorHandler("Failed to load resources: payments. Please refresh the page");
      }).finally(() => {
        this.isSavingTransaction = false;
      })

    },


    /*** Customers **/
    showCustomerPickDialog(customerType) {

      this.isNewCustomerFormVisible = true;
      this.isCustomerPickerDialogVisible = true;
      this.selectedCustomerType = customerType;
      if(this.cargoFormData.receiverCustomer != null){
        this.onCustomerSelected(this.cargoFormData.receiverCustomer)
      }else{
        this.showNewCustomerForm();
      }
    },

    addPhoneNumberSlot() {
      let phoneNumberField = {
        tempId: this.tempIndexId++,
        number: ''
      };
      this.customerInstance.phoneNumbersSlots.push(phoneNumberField);
    },

    removePhoneNumberSlot(index) {
      this.customerInstance.phoneNumbersSlots.splice(index, 1);
    },

    showNewCustomerForm() {
      this.customerInstance = {
        address: '',
        name: '',
        phoneNumbersSlots: [],
        phoneNumbers: []
      };
    },

    onConfirmCustomerSelection() {
      if (this.isNewCustomerFormVisible) {
        this.onSaveNewCustomer();
      } else {
        errorHandler("Select customer")
      }
    },

    onSearchCustomer() {
      this.isLoadingCustomer = true;
      console.log('search customer', JSON.stringify(this.customerInstance))
      const searchUri = `/customers/search?searchValue=${this.customerSearchValue ?? ''}`;
      http.get(searchUri).then((resp) => {
        this.isLoadingCustomer = false;
        this.customerList = resp.data.payload;
      }).catch((err) => {
        console.log(err)
        errorHandler("Failed to save customer");
        this.isLoadingCustomer = false;
      })
    },

    async onSaveNewCustomer() {
      this.isLoadingCustomer = true;
      const phoneNumbers = this.customerInstance.phoneNumbersSlots.map((item) => item.number)
      this.customerInstance.phoneNumbers = phoneNumbers.filter((item) => isNotEmpty(item))

      if (this.customerInstance.phoneNumbers.length === 0) {
        errorHandler("Add phone number")
        return;
      }

      http.post('/customer', this.customerInstance).then((resp) => {
        successHandler("Customer Added");
        this.isLoadingCustomer = false;
        this.closeCustomerPicker();
        this.assignCustomer(resp.data.payload)
      }).catch((err) => {
        console.log(err)
        errorHandler("Failed to save customer");
        this.isLoadingCustomer = false;
      })
    },

    onCustomerSelected(customer) {

      console.log(`editing existing customer ${JSON.stringify(customer)}`)
      console.log(JSON.stringify(customer))

      //Clear search result
      this.customerList = [];

      this.customerInstance.name = customer.name;

      this.customerInstance.phoneNumbers = [];
      if(isNotEmpty(customer.phoneNumber)){
        this.customerInstance.phoneNumbers.push(customer.phoneNumber)
      }
      if(isNotEmpty(customer.phoneNumber2)){
        this.customerInstance.phoneNumbers.push(customer.phoneNumber2)
      }
      if(isNotEmpty(customer.phoneNumber3)){
        this.customerInstance.phoneNumbers.push(customer.phoneNumber3)
      }

      this.customerInstance.phoneNumbersSlots = this.customerInstance.phoneNumbers.map((phoneInstance)=>{
        console.log("phoneInstance",JSON.stringify(phoneInstance))
        return {
          tempId: phoneInstance,
          number: phoneInstance
        }
      })
      this.isNewCustomerFormVisible = true;
      console.log("customerInstance",JSON.stringify(this.customerInstance))
    },

    assignCustomer(customer){
      if (this.selectedCustomerType === CUSTOMER_SENDER) {
        this.cargoFormData.senderCustomer = customer;
      } else {
        this.cargoFormData.receiverCustomer = customer;
      }
    },

    closeCustomerPicker() {
      this.isCustomerPickerDialogVisible = false;
      this.isNewCustomerFormVisible = false;
    },

    closeCustomerForm() {
      this.isNewCustomerFormVisible = false;
    }


  },

  created() {

    this.cargoFormData.containerId = this.$route.query.containerId;
    this.containerNumber = this.$route.query.containerNumber;

    //Fetch cargo info if cargoId is not null
    this.cargoId = this.$route.query.id;
    if (isNotEmpty(this.cargoId)) {
      this.fetchCargoDetails();
    } else {
      console.log("New Cargo")
    }

    this.fetchCategories();
    this.fetchCargoPayments();

  },
}

</script>


<style lang="scss" scoped>

.btn-action {
  color: #1890ff;
  border: 1px solid #1890ff;
}

.btn-warning {
  color: #ffb703;
  border: 1px solid #ffb703;
}

.btn-success {
  color: #4caf50;
  border: 1px solid #4caf50;
}

.x-small {
  font-size: 0.8em;
}

.blue {
  color: #1890ff;
}

.blue-line {
  width: 100%;
  height: 1px;
  background-color: #bee0ff;
}

.block-input {
  width: 100%;
}

.wrapper {
  padding: 24px;
}

.cargo-info-h {
  font-size: 1.6em;
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f2f2f2;
  padding: 10px;
  z-index: 1000; /* Ensure the header appears above other elements */
  transition: top 0.3s ease; /* Add smooth transition effect */
}

.main-content {
  overflow-y: auto;
  max-height: 300px; /* Set a maximum height for the main content to enable scrolling */
}


/** Payments table **/
.fancy-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.fancy-table th {
  border: 1px solid #ddd;
  padding: 0 8px;
}

.fancy-table td {
  border: 1px solid #ddd;
  padding: 4px 8px;
}

.fancy-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.fancy-table tr:hover {
  background-color: #ddd;

}

.fancy-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ffffff;
  color: #1890ff;
}

.total-bg{
  background-color: #c4e3fe;
  border-bottom: 1px solid #000000;
}

.total-values{
  font-weight: bolder;
}

/*** Customer **/
.selected-customer {
  border-radius: 4px;
  padding-top: 16px;
  border: 1px solid #1890ff;
  color: #8a8a8a;
}

.btn-clickable:hover {
  cursor: pointer;
}

</style>
