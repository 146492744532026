<template>
    <div>
        <div class="testimonial-container">
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
                <!-- Slides -->
                <slot name="my-slides"/>
            </div>
            <!-- If we need pagination -->
            <!--<div class="swiper-pagination ns-testimonial testimonial-pagination"></div>-->

            <!-- If we need navigation buttons -->
            <!-- <div class="testimonial-button-next swiper-button-prev"></div>
            <div class="testimonial-button-prev swiper-button-next"></div> -->

        </div>
    </div>
</template>


<script>
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.min.css'
export default {
    mounted() {
        let mySwiper = new Swiper('.testimonial-container', {
            speed: 400,
            autoplay: {
                delay: 10000,
            },
            navigation: {
                nextEl: '.testimonial-button-next',
                prevEl: '.testimonial-button-prev',
            },
            pagination: {
                el: '.testimonial-pagination',
                type: 'bullets',
            },
            initialSlide: 0,
            init: true,
            loop: true,
        });

    }
}
</script>

<style lang="scss">
    @import "../mixins";
    @import "../partials";

    .testimonial-container  {
        position: relative;
        .swiper-wrapper {
            height: 230px;
            width: 100%;
            @include mediaQuery(375px) {
                height: 230px;
            }
            @include mediaQuery(410px) {
                height: 200px;
            }
            @include mediaQuery($tablet-width) {
                height: 150px;
            }
        }
        .ns-testimonial {
            .swiper-pagination-bullet {
                background: $white;
            }
        }
    }
</style>



