<template>
    <div class="dashboard">Welcome to dashboard</div>
</template>

<script>
export default {
    name: "Dashboard"
}
</script>

<style lang="scss" scoped>
@import "../../../partials";
@import "../../../mixins";
.dashboard {
    background: white;
}
</style>
