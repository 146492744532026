import axios from "axios";
import {currentUser, errorHandler} from "@/services/utils";
import {$message} from "@/antdv";


export let baseUrl = process.env.NODE_ENV === 'development' ? "http://localhost:8083/api" : "https://api.bluewavecargo.com/api"
//export let baseUrl = "https://bluewavecargo.com/api";
//export let socketBaseUrl = "http://localhost:8085/";
export let socketBaseUrl = "http://198.199.89.65:8085/";

let axiosInstance = axios.create();

axiosInstance.interceptors.response.use((res)=>{
    let { code } = res.data;
    let hasAuthFailed = false;
    switch (code) {
        case 4: {
            $message.error("User Not Authenticated");
            setTimeout(()=>{
                currentUser.logout();
            },1000);
            hasAuthFailed = true;
            break;
        }
        case 0: {
            errorHandler("Error In DB Request");
            break;
        }
    }
    if(hasAuthFailed) {
        return;
    }
    return Promise.resolve(res);

},(error)=>{
    throw error;
});


let http = {
    get: (url, params) => {
        console.log(`GET request to ${baseUrl}${url}`)
        return axiosInstance({
            headers: {'auth-token': currentUser.getToken()},
            baseURL: baseUrl,
            url: url,
            params,
            method: "GET"
        });
    },
    post: (url, data) => {
        console.log(`POST request to ${baseUrl}${url}`)
        return axiosInstance({
            headers: {'auth-token': currentUser.getToken()},
            baseURL: baseUrl,
            url: url,
            data,
            method: "POST"
        });
    },
    put: (url, data) => {
        console.log(`PUT request to ${baseUrl}${url}`)
        return axiosInstance({
            headers: {'auth-token': currentUser.getToken()},
            baseURL: baseUrl,
            url: url,
            data,
            method: "PUT"
        });
    },
    delete: (url, data) => {
        console.log(`DELETE request to ${baseUrl}${url} ${JSON.stringify(data)}`)
        return axiosInstance({
            headers: {'auth-token': currentUser.getToken()},
            baseURL: baseUrl,
            url: url,
            data,
            method: "DELETE"
        });
    }
}

export default http;
