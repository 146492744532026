<template>
    <div :style="{backgroundImage: `url(${loginBg})`}" class="login-wrapper">
        <div class="bg-overlay"></div>
        <div class="form-wrapper">
            <div class="login-form ns-card">
                <img class="logo" :src="logo" alt="">
                <div class="form-header">
                    Welcome To Office Portal
                </div>
                <div @keyup.enter="submitLoginForm" style="margin-top: 5px;">
                    <div class="form-item">
                        <a-input @focus="formErrors.email = false" :class="{'input-error':formErrors.email}" v-model.trim="user.email" placeholder="E-mail">
                            <a-icon slot="prefix" type="mail" style="color:rgba(0,0,0,.25)" />
                        </a-input>
                    </div>
                    <div class="form-item">
                        <a-input-password @focus="formErrors.password = false" :class="{'input-error':formErrors.password}" v-model="user.password" placeholder="Password">
                            <a-icon slot="prefix" style="color:rgba(0,0,0,.25)" type="lock" />
                        </a-input-password>
                    </div>
                    <div style="text-align: center;font-size: 13px; color: rgba(0,0,0,0.5);" class="form-item">
                        Don't Have An Account Yet / Forgot Password? Talk To Your Manager
                    </div>
                    <div style="text-align: center;" class="form-item">
                        <a-button :loading="isLoading" style="min-width: 45%;" @click="submitLoginForm" type="primary">LOGIN</a-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import http from '@/http';
import loginBg from '@/assets/login-bg.jpeg'
import logo from '@/assets/logo.png'
import {currentUser, validation} from "@/services/utils";

export default {
    name: 'Login',
    data() {
        return {
            loginBg: loginBg,
            logo: logo,
            isLoading: false,
            user: {
                email: '',
                password: ''
            },
            formErrors: {
                email: false,
                password: false
            }
        }
    },
    methods: {
        validateForm() {
            let status = {
                isValid: true,
                message: 'Successful'
            }
            if(!validation.isEmail(this.user.email)) {
                status.isValid = false;
                status.message = 'Wrong E-mail';
                this.formErrors.email = true;
                return status;
            }

            if(this.user.password.length < 3) {
                status.isValid = false;
                status.message = 'Wrong Password';
                this.formErrors.password = true;
                return status;
            }

            return status;
        },
        async submitLoginForm() {
            let { isValid, message } = this.validateForm();
            if(!isValid) {
                this.$message.error(message);
                return
            }
            try {
                this.isLoading = true;
                let response = await http.post('/login',this.user);
                this.isLoading = false;
                if(response.data) {
                    if(response.data.code !== 200) {
                        this.$message.error('Wrong Credentials');
                        return;
                    }
                    currentUser.login(response);
                    await this.$router.push({name: 'shipment'})
                }
            }catch (e) {
                this.isLoading = false;
                this.$message.error('Network Error');
            }
        }
    }
}
</script>


<style lang="scss" scoped>
    @import "../../partials";
    @import "../../mixins";
    .login-wrapper {
        padding: 100px 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        .bg-overlay {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(0,0,0,0.5);
            z-index: 1;
        }
        .form-wrapper {
            position: relative;
            z-index: 1;
            .login-form {
                width: 90%;
                border-radius: 10px;
                max-width: 400px;
                margin: 0 auto;
                padding-left: 20px;
                padding-right: 20px;
                .logo {
                    width: 100px;
                    margin: 0 auto;
                    display: block;
                }
            }
            .form-item {
                padding: 5px 0;
            }
            .form-header {
                margin-top: 15px;
                font-size: 20px;
                font-weight: bolder;
                color: black;
            }
        }
    }
</style>


