import Vue from 'vue'
import {
    Button,Row,Col,Card,Checkbox,Radio,Input,InputNumber,
    Table,Icon, Menu, message ,Tooltip, Drawer, Modal, Form, Select,
    Tag, Spin, DatePicker
} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

message.config({
    top: '110px',
    duration: 4
});

let $message = message;

export { $message }

let antDVInstall = ()=>{
    Vue.use(Button);
    Vue.use(Row);
    Vue.use(Col);
    Vue.use(Card);
    Vue.use(Checkbox);
    Vue.use(Radio);
    Vue.use(Input);
    Vue.use(InputNumber);
    Vue.use(Table);
    Vue.use(Icon);
    Vue.use(Menu);
    Vue.use(Tooltip);
    Vue.use(Drawer);
    Vue.use(Modal);
    Vue.use(Form);
    Vue.use(Select);
    Vue.use(Tag);
    Vue.use(Spin);
    Vue.use(DatePicker);
    Vue.prototype.$message = $message;
    Vue.prototype.$confirm = Modal.confirm;
    Vue.prototype.$error = Modal.error;
}

export default antDVInstall;
