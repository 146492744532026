<template>

  <div class="wrapper">
    <div class="table-options">
      <div class="left">
        <div>
          <div @click="goBackToContainersList" class="shipment-list link" style="margin-bottom: 24px">
            <a-icon type="arrow-left"/>&nbsp;All Containers List
          </div>

          <div class="table-title">
            <img width="48px" v-bind:src="containerIcon" alt="">
            {{ $t('containerNumber') }}: {{ containerNumber !== '' ? containerNumber : $t('noContainerNumber') }}
          </div>
        </div>
      </div>
    </div>

    <div style="padding: 16px 0">
      <a-row>
        <a-col :span="8">
          <ul class="stat-item">
            <li class="stat-label"><i class="fa fa-archive"></i> Cargoes Count : <span
                class="stat-value">{{ totalPackages }}</span> Cargoes
            </li>
            <li class="stat-label" v-if="containerInfo"><i class="fa fa-calendar-alt"></i> Departure:
              {{ formatDate(containerInfo.departureDate) }}
            </li>
            <li class="stat-label" v-if="containerInfo"><i class="fa fa-calendar-alt"></i> Arrival:
              {{ formatDate(containerInfo.arrivalDate) }}
            </li>
          </ul>
        </a-col>

        <a-col :span="8">
          <ul class="stat-item">
            <li class="stat-label"><i class="fa fa-check-circle" style="color: #4CAF50"></i> Expected Payments: <span class="stat-value">{{ totalAmountDueUSD }} USD </span></li>
            <li class="stat-label"><i class="fa fa-check-circle" style="color: #4CAF50"></i> Total Paid Amount: <span class="stat-value">{{ totalPaidAmount }} USD </span></li>
            <li class="stat-label"><i class="fa fa-clock" style="color: #ff9800"></i> Total Unpaid Amount: <span style="color: #ff9800">{{ totalUnpaidAmount }} USD</span></li>
          </ul>
        </a-col>

        <a-col :span="8">
          <ul class="stat-item">
            <li class="stat-label">Gross profit: {{ totalAmountDueUSD }} USD</li>
            <li class="stat-label">Expected Net Profit: {{ expectedNetProfit }} USD</li>
            <li class="stat-label">Net Profit So Far: {{ netProfitSoFar }} USD</li>
          </ul>
        </a-col>
      </a-row>
    </div>


    <h2> Cargos In Container </h2>

    <div style="border: 1px solid #e1e1e1; padding: 24px 16px; background-color: #fefefe">
      <!-- Table buttons -->
      <div class="table-options" style="margin-bottom: 24px">
        <div class="left">
          <a-input-search style="width: 300px;" v-model="keywords"
                          :placeholder="`Tracking Number/Customer Name`" enter-button @search="onSearch"/>
        </div>

        <div class="right">
          <a-button :size="'large'" @click="navigateToCargoForm" type="primary" icon="plus">
            Add New Cargo
          </a-button>
        </div>
      </div>

      <a-table
          :size="'middle'"
          :rowKey="'id'"
          bordered
          :columns="cargoColumns"
          :data-source="cargos"
          :pagination="false"
          :loading="isFetchingCargos"
      >

        <template slot="receiverName" slot-scope="text, row">
          <div class="link">
            {{ row.receiverInfo.name !== '' ? row.receiverInfo.name : $t('noName') }}
          </div>
        </template>
        <template slot="transitStatus" slot-scope="text, row">
          <div>{{ getTransitStatusText(row) }}</div>
        </template>

        <template slot="payments" slot-scope="text, row">
          <p style="padding: 0; margin: 0; font-size: 0.8em">DUE: {{ row.amountDueUSD }}</p>
          <p style="padding: 0; margin: 0;font-size: 0.8em" >
            <span :class="`green`">PAID: {{ row.paidAmountUSD }}</span> <span style="color: transparent">--</span>
            <span :class="getUnpaidAmount(row)>0?`red`: `black`">UNPAID: {{ getUnpaidAmount(row) }}</span>
          </p>
        </template>

        <!--view-->
        <template slot="view" slot-scope="text, row">
          <a-button :size="'small'" @click="navigateToCargoForm(row)" type="primary">
            <i class="fa fa-eye" style="margin-right: 4px"></i>
            View
          </a-button>
        </template>

        <!--received_date-->
        <template slot="received_date" slot-scope="text, row">
          {{ formatDate(row.createdAt) }}
        </template>

        <!--actions-->
        <template slot="actions" slot-scope="text, row">
          <a-button-group :size="'small'">
            <a-tooltip>
              <template slot="title">
                <span>{{ $t('shareCargo') }}</span>
              </template>
              <a-button class="share-btn" @click="shareCargoWithClient(row)" type="primary" icon="share-alt"/>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                <span>{{ $t('copyPDFLink') }}</span>
              </template>
              <a-button @click="copyPDFLink(row)" type="primary" icon="file-pdf"/>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                <span>{{ $t('delete') }}</span>
              </template>
              <a-button @click="confirmDeleteCargo(row)" type="danger" icon="delete"/>
            </a-tooltip>
          </a-button-group>
        </template>

      </a-table>
    </div>

    <!--currency converter modal-->
    <a-modal
        v-model="isCurrencyConverterDialogVisible"
        :title="$t('customizeCurrencyConverter')"
        :width="600"
        centered
        @ok="customizeCurrencyConverter"
        :okText="$t('customize')"
        :cancelText="$t('cancel')"
    >
      <div style="display: flex; vertical-align: center; justify-content: center;">
        <div style="margin-right: 6px; display: flex; align-items: center;">1 US$ =</div>
        <div style="display: flex; align-items: center;">
          <a-input-number style="width: 100px;" v-model="currency"/>
          <div style="margin-left: 6px;">TShs.</div>
        </div>
      </div>
    </a-modal>


  </div>
</template>

<script>
import {drawerSetups, errorHandler, fuseInstance, successHandler, locale} from "@/services/utils";
import http from "@/http";
import moment from "moment"
import {eventBus, eventBusActions} from "@/eventBus";
import {momentToString} from "ant-design-vue/lib/_util/moment-util";
import {isEmpty, roundToDecimalPlaces} from "@/utils/helpers";

export default {
  name: "CargosList",

  data() {
    return {

      containerIcon: require('@/assets/icons/container.png'),

      containerExpensesList: [],
      currency: 0,
      isCurrencyConverterDialogVisible: false,
      isCategoriesSelectionVisible: false,
      containerNumber: '',
      containerId: '',
      isCargoInfoDrawerVisible: false,
      bodyStyle: drawerSetups.bodyStyle,
      contentStyle: drawerSetups.contentStyle,
      contentFormStyle: drawerSetups.contentFormStyle,
      contentFormFooterStyle: drawerSetups.contentFormFooterStyle,
      selectedCargo: {},
      searchKeys: ['customerName', 'trackingNumber'],
      categoriesSearchKeys: ['englishName', 'swahiliName', 'chineseName'],
      transitStatus: [
        {
          value: 4,
          title: this.$t('dischargedFromPort')
        },
        {
          value: 5,
          title: this.$t('deliveredToReceiver')
        }
      ],
      overallTransitStatus: [
        {
          value: 1,
          title: this.$t('loadingCargo')
        },
        {
          value: 2,
          title: this.$t('onTheWay')
        },
        {
          value: 3,
          title: this.$t('delayed')
        },
        {
          value: 4,
          title: this.$t('dischargedFromPort')
        },
        {
          value: 5,
          title: this.$t('deliveredToReceiver')
        }
      ],
      formData: {
        transitStatus: 4,
        deliveryDate: this.getTodayDate(),
        trackingNumber: '',
        receiverId: '',
        receiverInfo: {},
        containerId: '',
        containerInfo: {},
        packingList: [],
        paidAmountUSD: 0,
        currencyConverter: 0,
        selectedCategoriesIds: [],
        packagesNumber: 1,
        officialUseRemark: '',
        receiptNote: '',
        receiptNumber: ''
      },
      formDataReset: {
        transitStatus: 4,
        deliveryDate: this.getTodayDate(),
        trackingNumber: '',
        receiverId: '',
        receiverInfo: {},
        containerId: '',
        containerInfo: {},
        packingList: [],
        paidAmountUSD: 0,
        currencyConverter: 0,
        selectedCategoriesIds: [],
        packagesNumber: 1,
        officialUseRemark: ''
      },
      tempIndexId: 0,
      isSubmittingCustomerForm: false,
      isCustomerFormVisible: false,
      customerFormData: {
        name: '',
        address: '',
        phoneNumbersObjectsList: [],
        phoneNumbers: []
      },
      customerFormReset: {
        name: '',
        address: '',
        phoneNumbersObjectsList: [
          {
            tempId: Math.random() * 1000,
            number: ''
          }
        ],
        phoneNumbers: []
      },
      customers: [],
      isUpdateForm: false,
      isCargoFormVisible: false,
      isSubmittingCargoForm: false,
      labelCol: {
        xs: {span: 8}
      },
      wrapperCol: {
        xs: {span: 16}
      },
      cargoLabelCol: {
        xs: {span: 5}
      },
      cargoWrapperCol: {
        xs: {span: 19}
      },
      keywords: '',
      categoriesKeywords: '',

      cargoColumns: [
        {
          title: this.$t('trackingNumber'),
          dataIndex: 'trackingNumber',
          scopedSlots: {customRender: 'trackingNumber'},
        },
        {
          title: this.$t('receiverName'),
          dataIndex: 'receiverName',
          scopedSlots: {customRender: 'receiverName'},
          sorter: this.receiverNameSorter,
        },
        {
          title: this.$t('transitStatus'),
          dataIndex: 'transitStatus',
          scopedSlots: {customRender: 'transitStatus'},
        },
        {
          title: "Payment (USD)",
          dataIndex: 'payments',
          scopedSlots: {customRender: 'payments'}
        },
        {
          title: "Received Date",
          dataIndex: 'received_date',
          align: 'center',
          scopedSlots: {customRender: 'received_date'}
        },
        {
          title: "View | Edit",
          dataIndex: 'view',
          width: '108px',
          align: 'center',
          scopedSlots: {customRender: 'view'}
        },
        {
          title: this.$t('actions'),
          dataIndex: 'actions',
          width: '140px',
          align: 'center',
          scopedSlots: {customRender: 'actions'}
        }

      ],
      pagination: {
        simple: true,
        current: 1,
        pageSize: 600,
        total: 1
      },
      customersPagination: {
        current: 1,
        pageSize: 1500,
        total: 1
      },
      categoriesPagination: {
        current: 1,
        pageSize: 1500,
        total: 1
      },
      fixedPackingListColumns: [
        {
          title: this.$t('serialNumber'),
          dataIndex: 'serialNumber',
          scopedSlots: {customRender: 'serialNumber'},
          align: 'center',
          width: '70px'
        },
        {
          title: this.$t('englishName'),
          dataIndex: 'englishName',
          scopedSlots: {customRender: 'englishName'}
        },
        {
          title: this.$t('priceUSD'),
          dataIndex: 'price',
          width: '150px',
          scopedSlots: {customRender: 'price'}
        },
        {
          title: this.$t('quantity'),
          dataIndex: 'quantity',
          scopedSlots: {customRender: 'quantity'}
        },
        {
          title: this.$t('amountUSD'),
          dataIndex: 'amount',
          scopedSlots: {customRender: 'amount'}
        }
      ],
      categories: [],
      invisibleCategoriesIds: [],
      rawCategories: [],
      categoriesColumns: [
        {
          title: this.$t('englishName'),
          dataIndex: 'englishName',
          scopedSlots: {customRender: 'englishName'}
        },
        {
          title: this.$t('swahiliName'),
          dataIndex: 'swahiliName',
          scopedSlots: {customRender: 'swahiliName'}
        },
        {
          title: this.$t('chineseName'),
          dataIndex: 'chineseName',
          scopedSlots: {customRender: 'chineseName'}
        },
        {
          title: this.$t('priceUSD'),
          dataIndex: 'price',
          width: '150px',
          scopedSlots: {customRender: 'price'}
        }
      ],
      temporarySelectedCategories: [],
      temporarySelectedCategoriesIds: [],
      cargoTableSorter: null,
      cargos: [],
      rawCargos: [],
      containerInfo: {},
      isFetchingCargos: false,
      isCustomPackingListItemFormVisible: false,
      quantityMeasures: {
        'CBM': 'cbm',
        'Tonne': 'tonne',
        'Piece': 'piece',
        'Other': 'other'
      },
      packingItemIndex: 0,
      packingListItem: {
        englishName: '',
        quantityMeasure: '',
        price: '',
        customQuantityMeasure: '',
        quantity: 1
      },
      packingListItemReset: {
        englishName: '',
        quantityMeasure: '',
        price: '',
        customQuantityMeasure: '',
        quantity: 1
      }
    }
  },

  computed: {
    totalPackages() {
      if (this.rawCargos.length === 0) {
        return 0
      }
      let total = 0
      this.rawCargos.forEach((cargo) => {
        total = total + cargo.packagesNumber
      })
      return total
    },
    packingListColumns() {
      let columns = [...this.fixedPackingListColumns];
      if (this.isCargoFormVisible) {
        columns.push({
          title: this.$t('actions'),
          dataIndex: 'actions',
          width: '140px',
          align: 'center',
          scopedSlots: {customRender: 'actions'}
        });
      }
      return columns;
    },
    cargoFormTitle() {
      return this.isUpdateForm ? this.$t('updateCustomerCargo') : this.$t('addCustomerCargo');
    },
    containerFormOkButtonText() {
      return this.isUpdateForm ? this.$t('update') : this.$t('submit');
    },

    grossProfitTShsNumber() {
      let grossProfit = 0;
      this.rawCargos.forEach((cargo) => {
        if (cargo.packingList) {
          cargo.packingList.forEach((item) => {
            let amount = item.quantity * item.price * cargo.currencyConverter
            grossProfit += amount
          })
        }
      })
      return grossProfit
    },

    totalExpensesTShsNumber() {
      let totalAmountTShs = 0
      if (this.containerExpensesList && this.containerExpensesList.length > 0) {
        this.containerExpensesList.forEach((transaction) => {
          if (transaction.isUSDTransaction) {
            totalAmountTShs = totalAmountTShs + (transaction.amount * transaction.currencyConverter)
          } else {
            totalAmountTShs = totalAmountTShs + transaction.amount
          }
        })
      }
      return totalAmountTShs
    },

    totalExpenses() {
      return 0;
      // let totalUSD = 0
      // let totalTShs = 0
      // if (this.containerExpensesList && this.containerExpensesList.length > 0) {
      //   let usdList = this.containerExpensesList.filter((transaction) => transaction.isUSDTransaction)
      //   let tShsList = this.containerExpensesList.filter((transaction) => !transaction.isUSDTransaction)
      //   usdList.forEach((transaction) => {
      //     totalUSD = totalUSD + transaction.amount
      //   })
      //   tShsList.forEach((transaction) => {
      //     totalTShs = totalTShs + transaction.amount
      //   })
      // }
      // return `${this.roundToStringCurrency(totalUSD)} USD`
    },

    expectedNetProfit() {
      return this.totalAmountDueUSD - this.totalExpenses ;
    },

    netProfitSoFar() {
      return this.totalPaidAmount - this.totalExpenses ;
    },

    totalPaidAmount() {
      const amount = this.rawCargos.reduce((sum, item) => sum + item.paidAmountUSD, 0)
      return roundToDecimalPlaces(amount);
    },

    totalAmountDueUSD() {
      const amount =this.rawCargos.reduce((sum, item) => sum + item.amountDueUSD, 0)
      return Math.round(amount)
    },

    totalUnpaidAmount(){
      if(this.totalPaidAmount > this.totalAmountDueUSD){
        return 0;
      }
      return this.totalAmountDueUSD - this.totalPaidAmount;
    }


  },

  methods: {

    getUnpaidAmount(cargoItem) {
      if(cargoItem.paidAmountUSD>cargoItem.amountDueUSD){
        return  0;
      }
      return Math.round((cargoItem.amountDueUSD - cargoItem.paidAmountUSD))
    },

    customizeCurrencyConverter() {
      this.formData.currencyConverter = this.currency;
      this.isCurrencyConverterDialogVisible = false;
    },

    roundToStringCurrency(amount) {
      return ((Math.round(amount * 100) / 100).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    goBackToContainersList() {
      this.$router.replace({name: 'shipment'});
    },
    formatDate(dateString) {
      return moment(dateString).format('llll')
    },
    getTodayDate() {
      return moment(new Date().getTime())
    },
    canUpdateCargoTransitStatus(transitStatus) {
      return transitStatus >= 4
    },
    getTransitStatusText(item) {
      const transitStatus = this.canUpdateCargoTransitStatus(item.containerInfo.transitStatus) ? item.transitStatus : item.containerInfo.transitStatus;
      let transitStatusText = "";
      for (let i = 0; i < this.overallTransitStatus.length; i++) {
        if (this.overallTransitStatus[i].value === transitStatus) {
          transitStatusText = this.overallTransitStatus[i].title
          break;
        }
      }
      return transitStatusText
    },

    receiverNameSorter(a, b) {
      if (a.receiverInfo.name < b.receiverInfo.name) {
        return -1;
      }
      if (a.receiverInfo.name > b.receiverInfo.name) {
        return 1;
      }
      return 0;
    },

    unpaidAmountSorter(a, b) {
      let firstUnpaidAmount = this.getUnpaidAmountUSD(a);
      let secondUnpaidAmount = this.getUnpaidAmountUSD(b);
      if (firstUnpaidAmount < secondUnpaidAmount) {
        return -1;
      }
      if (firstUnpaidAmount > secondUnpaidAmount) {
        return 1;
      }
      return 0;
    },

    async fetchCargos() {
      try {
        let params = {
          pageNum: this.pagination.current,
          itemsPerPage: this.pagination.pageSize,
          containerId: this.containerId
        }
        if (this.cargoTableSorter) {
          params.sorter = {
            order: this.cargoTableSorter.order,
            field: this.cargoTableSorter.field
          }
        }
        this.isFetchingCargos = true;
        let response = await http.get('/cargos', params);
        this.isFetchingCargos = false;
        if (response.data.code === 200) {
          let serverData = response.data.data;
          this.rawCargos = serverData.result;
          this.formData.containerInfo = serverData.containerInfo;
          this.containerExpensesList = serverData.containerExpensesList;
          this.containerInfo = serverData.containerInfo;
          this.formDataReset.containerInfo = serverData.containerInfo;
          this.pagination.pageSize = serverData.itemsPerPage;
          this.pagination.total = serverData.totalPages;
          this.pagination.current = serverData.pageNum;
          this.onSearch();
        }
      } catch (e) {
        this.isFetchingCargos = false;
        errorHandler(this.$t('networkError'));
      }
    },


    confirmDeleteCargo(cargo) {
      this.$confirm({
        title: this.$t('deleteConfirmation'),
        content: this.$t('deleteCargoMessage', {trackingNumber: cargo.trackingNumber}),
        okText: this.$t('yes'),
        okType: 'danger',
        cancelText: this.$t('cancel'),
        onOk: () => {
          console.log("Cargo deletion confirmed")
          this.deleteCargo(cargo);
        }
      })
    },

    async deleteCargo(cargo) {
      try {
        this.isFetchingCargos = true;
        let response = await http.delete('/cargo/delete', {
          "id" : cargo.id
        });
        if (response.data.code === 200) {
          successHandler(this.$t('operationSuccessful'))
          this.fetchCargos();
        }else{
          console.error(`Failed to delete the cargo ${response.headers.code} ${JSON.stringify(response.data)}`)
        }
      } catch (e) {
        this.isFetchingCargos = false;
        errorHandler(this.$t('networkError'));
      }

      this.isFetchingCargos = false;
    },

    copyPDFLink(cargo) {
      let info = {
        text: `https://bluewavecargo.com/invoice/${cargo.trackingNumber}`,
        successMessage: 'Link Copied To Clipboard'
      }
      eventBus.$emit(eventBusActions.COPY_TEXT_TO_CLIPBOARD, info)
    },

    shareCargoWithClient(cargo) {
      let info = {
        text: `Dear client, your cargo's tracking number is:${cargo.trackingNumber}.You can track it by downloading our app (BlueWaveCargo) on PlayStore or AppStore.`,
        successMessage: 'Message Copied To Clipboard'
      }
      eventBus.$emit(eventBusActions.COPY_TEXT_TO_CLIPBOARD, info)
    },

    async fetchCategories() {
      try {
        let params = {
          pageNum: this.categoriesPagination.current,
          itemsPerPage: this.categoriesPagination.pageSize
        }
        let response = await http.get('/categories', params);
        if (response.data.code === 200) {
          let serverData = response.data.data;
          this.rawCategories = serverData.result;
          this.onSearchCategories();
        }
      } catch (e) {
        this.isFetchingCategories = false;
        errorHandler(this.$t('networkError'));
      }
    },

    onSearch() {
      let output = fuseInstance.search(this.searchKeys, this.rawCargos, this.keywords);
      this.cargos = [...output];
    },

    onSearchCategories() {
      let output = fuseInstance.search(this.categoriesSearchKeys, this.rawCategories, this.categoriesKeywords);
      this.categories = [...output];
      this.invisibleCategoriesIds = this.rawCategories.filter((item) => {
        let isInvisible = true
        for (let i = 0; i < this.categories.length; i++) {
          if (item.id === this.categories[i].id) {
            isInvisible = false;
            break;
          }
        }
        return isInvisible
      }).map((item) => item.id)
    },

    navigateToCargoForm(cargo = {}) {
      this.$router.push({name: 'cargo-form', query: {
        id: cargo.id,
        containerId: this.containerId,
        containerNumber: this.containerNumber,
      }})
    },

    async fetchAllCustomers() {
      try {
        let params = {
          pageNum: this.customersPagination.current,
          itemsPerPage: this.customersPagination.pageSize
        }

        let response = await http.get('/customers', params);
        if (response.data.code === 200) {
          let serverData = response.data.data;
          this.customers = serverData.result;
        }
      } catch (e) {
        errorHandler(this.$t('networkError'));
      }
    }

  },

  created() {
    this.containerId = this.$route.query.id;
    this.containerNumber = this.$route.query.containerNumber;
    this.formData.containerId = this.containerId;
    this.formDataReset.containerId = this.containerId;
    this.formDataReset.currencyConverter = locale.getCurrencyConverter();
    this.fetchAllCustomers();
    this.fetchCategories();
    this.fetchCargos();
  },
}
</script>


<style lang="scss" scoped>
@import "../../../partials";
@import "../../../mixins";
.red{
  color: #e63946;
}

.green{
  color: #4caf50;
}
.stat-item {
  border: 1px solid #e4eAeF;
  background-color: #f4fAfF;
  margin-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  list-style-type: none;
  padding-left: 16px;
}

.stat-label {
  font-size: 1.1em;
}

.stat-value {
  font-weight: bold;
}

.wrapper {
  background: white;
  padding: 15px;
}

.table-options {
  @include tableOptions;
}

.content-wrapper {
  @include drawerList;
}

.content-form-wrapper {
  padding: 15px 30px;
}

.footer-buttons {
  @include drawerFormFooterButtons;
}

.open-container-btn {
  background-color: #4caf50;
  color: white;
  border-top: 1px solid #4caf50;
  border-bottom: 1px solid #4caf50;
}

.shipment-list {
  padding: 2.5px 0;
}

.user-input-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 5px;
}

.user-input {
  width: 85%;
}

.share-btn {
  background-color: #4caf50;
  color: white;
  border-top: 1px solid #4caf50;
  border-bottom: 1px solid #4caf50;
}


</style>
