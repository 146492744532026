<template>
    <div class="wrapper">
        <div class="table-options">
            <div class="left">
                <div>
                    <div class="table-title">{{$t('categories')}}</div>
                    <div>
                        <div>{{$t('summary')}}</div>
                        <div>{{$t('totalCategories')}}: {{rawCategories.length}}</div>
                    </div>
                </div>
            </div>
            <div class="right">
                <a-input-search v-model="keywords" :placeholder="$t('categoryName')" enter-button @search="onSearch"/>
                <a-button @click="showAddNewCategoryForm" type="primary" icon="plus">
                    {{$t('addNew')}}
                </a-button>
            </div>
        </div>

        <a-table
            :size="'middle'"
            :rowKey="'id'"
            bordered
            :columns="columns"
            :data-source="categories"
            :pagination="false"
            :loading="isFetchingCategories"
        >
            <!--name-->
            <template slot="serialNumber" slot-scope="text, row, index">
                <div>{{ index + 1 }}</div>
            </template>
            <template slot="englishName" slot-scope="text, row">
                <div v-if="text.trim() === ''">{{ $t('noContent') }}</div>
                <div v-else class="link" @click="showCategoryDetailsDrawer(row)">{{ text }}</div>
            </template>
            <template slot="swahiliName" slot-scope="text, row">
                <div v-if="text.trim() === ''">{{ $t('noContent') }}</div>
                <div v-else class="link" @click="showCategoryDetailsDrawer(row)">{{ text }}</div>            </template>
            <template slot="chineseName" slot-scope="text, row">
                <div v-if="text.trim() === ''">{{ $t('noContent') }}</div>
                <div v-else class="link" @click="showCategoryDetailsDrawer(row)">{{ text }}</div>            </template>
            <template slot="price" slot-scope="text, row">
                <div style="text-transform: capitalize;">
                    {{ text }}US$/<span style="text-transform: uppercase">{{row.quantityMeasure === 'other' ? row.customQuantityMeasure : row.quantityMeasure}}</span>
                </div>
            </template>
            <!--actions-->
            <template slot="actions" slot-scope="text, row">
                <a-button-group :size="'small'">
                    <a-tooltip>
                        <template slot="title">
                            <span>{{$t('update')}}</span>
                        </template>
                        <a-button @click="showUpdateCategoryForm(row)" type="primary" icon="edit"/>
                    </a-tooltip>
                    <a-tooltip>
                        <template slot="title">
                            <span>{{$t('delete')}}</span>
                        </template>
                        <a-button @click="confirmDeleteCategory(row)" type="danger" icon="delete"/>
                    </a-tooltip>
                </a-button-group>
            </template>
        </a-table>

        <!--category info drawer-->
        <a-drawer
            :title="$t('categoryInfo')"
            placement="right"
            :closable="true"
            :width="500"
            @close="onCloseCategoryInfoDrawer"
            :bodyStyle="bodyStyle"
            :visible="isCategoryInfoDrawerVisible"
        >
            <div :style="contentStyle">
                <div class="content-wrapper">
                    <div :key="key" v-for="(item,key) in selectedCategory" class="info-item">
                        <template v-if="key === $t('officialUseRemark')">
                            <div class="item-label">{{ key }}:</div>
                            <div v-if="item.trim() === ''" class="item-description">{{$t('noContent')}}</div>
                            <div v-else class="item-description remark" v-html="item"></div>
                        </template>
                        <template v-else>
                            <div class="item-label">{{ key }}:</div>
                            <div v-if="item.trim() === ''" class="item-description">{{$t('noContent')}}</div>
                            <div v-else class="item-description">{{ item }}</div>
                        </template>
                    </div>
                </div>
            </div>
        </a-drawer>


        <!--category form-->
        <a-drawer
            :title="categoryFormTitle"
            placement="right"
            :closable="true"
            :width="600"
            @close="onCloseCategoryFormDrawer"
            :bodyStyle="bodyStyle"
            :visible="isCategoryFormVisible"
        >
            <div :style="contentFormStyle">
                <div class="content-form-wrapper">
                    <div @keyup.enter="submitCategoryForm">
                        <a-form :form="categoryForm" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-item
                                label="English Name">
                                <a-input autocomplete="new-password" placeholder="example:Bike Spare Parts"
                                         v-decorator="['englishName',{rules:[{required: true,validator:validateCategoryEnglishName}]}]"/>
                            </a-form-item>
                            <a-form-item
                                label="Swahili Name">
                                <a-input v-model.trim="formData.swahiliName" autocomplete="new-password" placeholder="example:Spea za pikipiki"/>
                            </a-form-item>
                            <a-form-item
                                label="Chinese Name">
                                <a-input v-model.trim="formData.chineseName" autocomplete="new-password" placeholder="比如:摩托车的配件"/>
                            </a-form-item>
                            <a-form-item
                                label="Quantity Measure">
                                <a-select v-model="formData.quantityMeasure">
                                    <a-select-option :key="key" v-for="(item,key) in quantityMeasures" :value="item">
                                        {{ key }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                            <a-form-item v-if="formData.quantityMeasure === 'other'"
                                         label="Other Measure">
                                <a-input v-model.trim="formData.customQuantityMeasure" autocomplete="new-password" placeholder="Custom Quantity Measure"/>
                            </a-form-item>
                            <a-form-item
                                label="Price In US$">
                                <a-input-number style="width: 100%;" v-model="formData.price" autocomplete="new-password" placeholder="Price In US$"/>
                                <div>Price: {{formData.price}}US$/<span style="text-transform: uppercase">{{formData.quantityMeasure === 'other'? formData.customQuantityMeasure : formData.quantityMeasure}}</span></div>
                            </a-form-item>
                            <a-form-item label="Official Use Remark">
                                <a-textarea rows="3" @keyup.enter.stop v-model.trim="formData.officialUseRemark" autocomplete="new-password" placeholder="Official Use Remark"/>
                                <div class="form-tip">{{$t('clientsWontSeeRemark')}}</div>
                            </a-form-item>
                        </a-form>
                    </div>
                </div>
            </div>
            <div :style="contentFormFooterStyle">
                <div class="footer-buttons">
                    <a-spin :spinning="isSubmittingCategoryForm">
                        <a-button @click="onCloseCategoryFormDrawer">{{$t('cancel')}}</a-button>
                        <a-button @click="submitCategoryForm" type="primary">{{categoryFormOkButtonText}}</a-button>
                    </a-spin>
                </div>
            </div>
        </a-drawer>


    </div>
</template>

<script>
import {drawerSetups, errorHandler, fuseInstance, successHandler} from "@/services/utils";
import http from "@/http";

export default {
    name: "Categories",
    data() {
        return {
            isCategoryInfoDrawerVisible: false,
            bodyStyle: drawerSetups.bodyStyle,
            contentStyle: drawerSetups.contentStyle,
            contentFormStyle: drawerSetups.contentFormStyle,
            contentFormFooterStyle: drawerSetups.contentFormFooterStyle,
            selectedCategory: {},
            searchKeys: ['englishName', 'swahiliName', 'chineseName'],
            formData: {
                englishName: '',
                swahiliName: '',
                chineseName: '',
                quantityMeasure: 'cbm',
                customQuantityMeasure: '',
                price: 0,
                officialUseRemark: '',
            },
            formDataReset: {
                englishName: '',
                swahiliName: '',
                chineseName: '',
                quantityMeasure: 'cbm',
                customQuantityMeasure: '',
                price: 0,
                officialUseRemark: '',
            },
            quantityMeasures: {
                'CBM': 'cbm',
                'Tonne': 'tonne',
                'Piece': 'piece',
                'Other': 'other'
            },
            isUpdateForm: false,
            isCategoryFormVisible: false,
            isSubmittingCategoryForm: false,
            categoryForm: this.$form.createForm(this, {name: 'category'}),
            labelCol: {
                xs: {span: 6}
            },
            wrapperCol: {
                xs: {span: 18}
            },
            keywords: '',
            columns: [
                {
                    title: this.$t('serialNumber'),
                    dataIndex: 'serialNumber',
                    scopedSlots: {customRender: 'serialNumber'},
                    align: 'center',
                    width: '70px'
                },
                {
                    title: this.$t('englishName'),
                    dataIndex: 'englishName',
                    scopedSlots: {customRender: 'englishName'},
                    sorter: this.englishNameSorter
                },
                {
                    title: this.$t('swahiliName'),
                    dataIndex: 'swahiliName',
                    scopedSlots: {customRender: 'swahiliName'},
                    sorter: this.swahiliNameSorter
                },
                {
                    title: this.$t('chineseName'),
                    dataIndex: 'chineseName',
                    scopedSlots: {customRender: 'chineseName'},
                    sorter: this.chineseNameSorter
                },
                {
                    title: this.$t('priceUSD'),
                    dataIndex: 'price',
                    width: '150px',
                    scopedSlots: {customRender: 'price'}
                },
                {
                    title: this.$t('actions'),
                    dataIndex: 'actions',
                    width: '140px',
                    align: 'center',
                    scopedSlots: {customRender: 'actions'}
                }
            ],
            pagination: {
                simple: true,
                current: 1,
                pageSize: 3000,
                total: 1
            },
            categories: [],
            rawCategories: [],
            isFetchingCategories: false
        }
    },
    computed: {
        categoryFormTitle() {
            return this.isUpdateForm ? this.$t('updateCategory') : this.$t('addNewCategory');
        },
        categoryFormOkButtonText() {
            return this.isUpdateForm ? this.$t('update') : this.$t('submit');
        }
    },
    created() {
        this.fetchCategories();
    },
    methods: {
        onCloseCategoryInfoDrawer() {
            this.isCategoryInfoDrawerVisible = false;
        },
        onCloseCategoryFormDrawer() {
            this.isCategoryFormVisible = false;
        },
        englishNameSorter(a,b) {
            if(a.englishName < b.englishName) { return -1; }
            if(a.englishName > b.englishName) { return 1; }
            return 0;
        },
        swahiliNameSorter(a,b) {
            if(a.swahiliName < b.swahiliName) { return -1; }
            if(a.swahiliName > b.swahiliName) { return 1; }
            return 0;
        },
        chineseNameSorter(a,b) {
            if(a.chineseName < b.chineseName) { return -1; }
            if(a.chineseName > b.chineseName) { return 1; }
            return 0;
        },
        async fetchCategories() {
            try {
                let params = {
                    pageNum: this.pagination.current,
                    itemsPerPage: this.pagination.pageSize
                }
                this.isFetchingCategories = true;
                let response = await http.get('/categories', params);
                this.isFetchingCategories = false;
                if(response.data.code === 200) {
                    let serverData = response.data.data;
                    this.rawCategories = serverData.result;
                    this.pagination.pageSize = serverData.itemsPerPage;
                    this.pagination.total = serverData.totalPages;
                    this.pagination.current = serverData.pageNum;
                    this.onSearch();
                }
            } catch (e) {
                this.isFetchingCategories = false;
                errorHandler(this.$t('networkError'));
            }
        },
        validateCategoryEnglishName(rule, value, callback) {
            if (!value || value.trim() === "") {
                callback(this.$t('categoryEnglishNameRequired'));
                return;
            }
            callback();
        },
        validateCategoryForm() {
            let validity = {
                isValid: true,
                message: this.$t('successful')
            };
            this.categoryForm.validateFields({force: true}, (error, values) => {
                if (error) {
                    validity.isValid = false;
                    validity.message = this.$t('checkFormAgain');
                    return
                }
                this.formData.englishName = values.englishName;

            });
            return validity;
        },
        async submitCategoryForm() {
            let {isValid, message} = this.validateCategoryForm();
            if (!isValid) {
                errorHandler(message);
                return;
            }

            try {
                this.isSubmittingCategoryForm = true;
                let response;
                if(this.isUpdateForm) {
                    response = await http.put('/category',this.formData);
                }else {
                    response = await http.post('/category',this.formData);
                }

                this.isSubmittingCategoryForm = false;
                if(response.data.code === 200) {
                    successHandler(this.$t('operationSuccessful'));
                    this.isCategoryFormVisible = false;
                    this.fetchCategories();
                }
            } catch (e) {
                this.isSubmittingCategoryForm = false;
                errorHandler(this.$t('networkError'));
            }
        },
        confirmDeleteCategory(category) {
            this.$confirm({
                title: this.$t('deleteConfirmation'),
                content: this.$t('deleteCategoryMessage',{name: category.englishName}),
                okText: this.$t('yes'),
                okType: 'danger',
                cancelText: this.$t('cancel'),
                onOk: () => {
                    this.deleteCategory(category);
                }
            })
        },
        async deleteCategory(category) {
            try {
                let response = await http.delete('/category',category);
                if(response.data.code === 200) {
                    successHandler(this.$t('operationSuccessful'))
                    this.fetchCategories();
                }
            } catch (e) {
                errorHandler(this.$t('networkError'));
            }
        },
        showUpdateCategoryForm(category) {
            this.isUpdateForm = true;
            this.isCategoryFormVisible = true;
            this.$nextTick(()=>{
                this.formData = {
                    ...category
                }
                this.categoryForm.setFieldsValue({
                    englishName: this.formData.englishName
                });
            });
        },
        showCategoryDetailsDrawer(category) {
            let quantityMeasure = (category.quantityMeasure === 'other' ? category.customQuantityMeasure : category.quantityMeasure).toUpperCase();
            this.selectedCategory = {
                'English Name': category.englishName,
                'Swahili Name': category.swahiliName,
                'Chinese Name': category.chineseName,
                'Quantity Measure': quantityMeasure,
                'Price': `${category.price}US$/${quantityMeasure}`,
                [this.$t('officialUseRemark')]: category.officialUseRemark
            };
            this.isCategoryInfoDrawerVisible = true;
        },
        onSearch() {
            let output = fuseInstance.search(this.searchKeys, this.rawCategories, this.keywords);
            this.categories = [...output];
        },
        showAddNewCategoryForm() {
            this.resetCategoryForm();
            this.isUpdateForm = false
            this.isCategoryFormVisible = true;
        },
        resetCategoryForm() {
            this.categoryForm.resetFields();
            this.formData = {
                ...this.formDataReset
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../partials";
@import "../../../mixins";

.wrapper {
    background: white;
    padding: 15px;

    .table-options {
        @include tableOptions;
    }
}
.content-wrapper {
    @include drawerList;
}
.content-form-wrapper {
    padding: 15px 30px;
}
.footer-buttons {
    @include drawerFormFooterButtons;
}

</style>
