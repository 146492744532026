<template>
    <div class="contact-us-wrapper">
        <div class="header-bg">
            <div class="bg-overlay"></div>
            <div class="header-content">
                <div class="title">Get In Touch</div>
                <div class="message">Let's talk about your shipping. Our dedicated customer service is ready to get your work done. Check our office contacts below.</div>
            </div>
        </div>
        <div class="content-wrapper">
            <div class="addresses">
                <div class="address-wrapper">
                    <a-row class="address-row">
                        <a-col class="address-column" :key="office.id" :xs="24" v-for="office in offices">
                            <div class="ns-card">
                                <div class="flag-icon" :style="{backgroundImage: `url(${getFLagIcon(office.title)})`}"></div>
                                <div class="address-item title">{{office.title}}</div>
                                <div v-if="hasChineseAddress(office)" class="address-item">
                                    Chinese Address: {{office.chineseAddress}}
                                </div>
                                <div class="address-item">
                                    {{hasChineseAddress(office)?`English Address: `:''}}
                                    {{office.englishAddress}}
                                </div>
                                <div class="address-item">
                                    <div :key="`${phone}-${index}`" v-for="(phone,index) in office.phones">
                                        <a target="_blank" :href="`tel:${phone}`" class="phone-link">{{phone}}</a>
                                    </div>
                                </div>
                            </div>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import flagTZ from "../../assets/flag_tz.jpg";
import flagCN from "../../assets/flag_cn.png";
import http from '@/http';
export default {
    data() {
        return {
            officeAddressCacheKey: 'officeAddress',
            offices: []
        }
    },
    created() {
        this.getCachedAddress();
        this.getOfficeAddress();
    },
    methods: {
        getCachedAddress() {
            let address = localStorage.getItem(this.officeAddressCacheKey);
            if(address) {
                this.offices = JSON.parse(address);
            }
        },
        async getOfficeAddress() {
            try{
                let {data} = await http.get('/office-locations');
                if(data.code === 200) {
                    data.data.forEach((address)=>{
                        let phones = address.phone.split('/');
                        address.phones = phones.map((phone) => {
                            return phone.trim();
                        });
                    });
                    this.offices = data.data;
                    //cache the address
                    localStorage.setItem(this.officeAddressCacheKey,JSON.stringify(data.data));
                }
            }catch (e) {

            }
        },
        hasChineseAddress(officeAddress) {
            return officeAddress.chineseAddress && officeAddress.chineseAddress !== ''
        },
        getFLagIcon(title) {
            let titleLowerCase = title.toLowerCase();
            if(titleLowerCase.indexOf('tanzania') > -1 || titleLowerCase.indexOf('tz') > -1 || titleLowerCase.indexOf('zanzibar') > -1 || titleLowerCase.indexOf('znz') > -1 || titleLowerCase.indexOf('dar') > -1) {
                return flagTZ;
            }
            return flagCN;
        }
    }
}
</script>


<style lang="scss" scoped>
    @import "../../partials";
    @import "../../mixins";
    $phone-header-bg-height: 330px;
    $tablet-header-bg-height: 450px;
    $pc-header-bg-height: 550px;
    $lg-pc-header-bg-height: 600px;
    .contact-us-wrapper {
        position: relative;
        min-height: $phone-header-bg-height;
        @include mediaQuery($tablet-width) {
            min-height: $tablet-header-bg-height;
        }
        @include mediaQuery($pc-width) {
            min-height: $pc-header-bg-height;
        }
        @include mediaQuery($lg-pc-width) {
            min-height: $lg-pc-header-bg-height;
        }
        .header-bg {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            top: 0;
            height: $phone-header-bg-height;
            background-color: #fff;
            background-image: url("../../assets/map.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            z-index: 4;
            @include mediaQuery($tablet-width) {
                height: $tablet-header-bg-height;
            }
            @include mediaQuery($pc-width) {
                height: $pc-header-bg-height;
            }
            @include mediaQuery($lg-pc-width) {
                height: $lg-pc-header-bg-height;
            }
            .bg-overlay {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: 100%;
                background: rgba(0,0,0,0.7);
                z-index: 3;
            }
            .header-content {
                @include absoluteYCenter;
                color: white;
                z-index: 8;
                padding-left: 4%;
                padding-right: 20%;
                @include mediaQuery($tablet-width) {
                    padding-left: 4%;
                    padding-right: 30%;
                }
                @include mediaQuery($pc-width) {
                    padding-left: 4%;
                    padding-right: 40%;
                }
            }
            .title {
                font-size: 30px;
                font-weight: bolder;
                @include mediaQuery($tablet-width) {
                    font-size: $font-size-header-8;
                }
                @include mediaQuery($pc-width) {
                    font-size: $font-size-header-10;
                }
            }
            .message {
                font-size: 18px;
                @include mediaQuery($tablet-width) {
                    font-size: 20px;
                }
            }
        }
        .content-wrapper {
            position: relative;
            z-index: 5;
            background: transparent;
            padding-top: $phone-header-bg-height - 50px;
            @include mediaQuery($tablet-width) {
                padding-top: $tablet-header-bg-height - 50px;
            }
            @include mediaQuery($pc-width) {
                padding-top: $pc-header-bg-height - 50px;
            }
            @include mediaQuery($lg-pc-width) {
                padding-top: $lg-pc-header-bg-height - 50px;
            }
            .addresses {
                padding: 0 5%;
                @include mediaQuery($tablet-width) {
                    padding: 0 20%;
                }
            }
            .address-wrapper {
                .ns-card {
                    border-radius: 10px;
                    margin-bottom: 0;
                }
            }
        }
    }
    .address-row {
        .address-column {
            padding: 5px 10px;
            margin-bottom: 20px;
            .flag-icon {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
            }
            .address-item {
                padding: 5px 0;
            }
            .title {
                font-weight: bold;
                color: black;
            }
            .phone-link {
                color: #1890ff;
                cursor: pointer;
            }
        }
    }
</style>


