<template>
    <div>
        <a-row class="footer">
            <a-col :span="24">
                <div class="app-links">
                    <div class="info-text">Track Your Cargo Any Time.Download Our App</div>
                    <div>
                        <a target="_blank" href="https://apps.apple.com/us/app/bluewavecargo/id1620899673">
                            <img :src="appStoreLogo" alt="">
                        </a>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.isunaslabs.bluewavecargo">
                            <img :src="playStoreLogo" alt="">
                        </a>
                    </div>
                </div>
            </a-col>
            <a-col :span="24">
                <div>
                    <div class="ns-header">Office Hours</div>
                    <a-row :key="officeHour.id" v-for="officeHour in officeHours">
                        <a-col :span="15"><i style="margin-right:7px;" class="far fa-clock"></i>{{officeHour.days}}</a-col>
                        <a-col :span="9" style="text-align: right;">{{officeHour.time}}</a-col>
                    </a-row>
                </div>
                <div class="ns-divider"></div>
            </a-col>
            <a-col :span="24" class="copyright-text">
                <div>&copy;Blue Wave Cargo LTD.</div>
                <div>Designed & Developed By <a target="new_tab" style="color: white;" href="https://www.isunaslabs.com">IsuNas Labs</a></div>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import appStoreLogo from '../assets/appstore.png';
import playStoreLogo from '../assets/playstore.png';

export default {
    data() {
        return {
            appStoreLogo: appStoreLogo,
            playStoreLogo: playStoreLogo,
            officeHours: [
                {
                    id: 1,
                    days: 'Monday - Friday',
                    time: '09:00 - 18:00'
                },
                {
                    id: 2,
                    days: 'Saturdays & Sundays',
                    time: '09:00 - 12:00'
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../mixins";
    @import "../partials";
    .footer {
        background: #000000;
        padding: 50px 8px;
        color: white;
        .ns-header {
            font-weight: bold;
            text-transform: uppercase;
            font-size: $font-size-header-1;
            margin-bottom: $space-1;
        }
        .ns-divider {
            width: 100%;
            height: 1.5px;
            background: white;
            margin: $space-3 0;
        }
        .copyright-text {
            text-align: center;
        }
    }
    .app-links {
        text-align: right;
        margin-bottom: 15px;
        .info-text {
            font-size: 16px;
            margin-bottom: 10px;
            padding-right: 10px;
        }
        img {
            width: 120px;
            margin-right: 10px;
            display: inline-block;
            vertical-align: middle;
            @include mediaQuery($pc-width) {
                width: 200px;
            }
        }
    }
</style>


