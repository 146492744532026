let enUs = {
    employees: 'Employees',
    employeeName: 'Employee Name',
    addNew: 'Add New',
    fullName: 'Full Name',
    privilege: 'Privilege',
    actions: 'Actions',
    updateEmployeeInfo: 'Update Employee Info',
    addNewEmployee: 'Add New Employee',
    update: 'Update',
    submit: 'Submit',
    resetPassword: 'Reset Password',
    activate: 'Activate',
    suspend: 'Suspend',
    delete: 'Delete',
    employeeInfo: 'Employee Info',
    cancel: 'Cancel',
    email: 'E-mail',
    password: 'Password',
    systemPrivilege: 'System Privilege',
    phoneNumbers: 'Phone Numbers',
    emailExample: 'example:john_doe@gmail.com',
    fullNameExample: 'example:John Doe',
    phoneNumberExample: 'example:+255713112233',
    addPhoneNumber: 'Add Phone Number',
    removePhoneNumber: 'Remove This Phone Number',
    newPassword: 'New Password',
    passwordTip: 'This password will be used by the employee to login the system',
    emailTip: 'This email will be used by the employee to login the system',
    activationConfirmation: 'Activation Confirmation',
    activationMessage: `After activation, this employee will be able to log into the office system as usual. Are you sure you want to activate {name}'s account?`,
    yes: 'Yes',
    operationSuccessful: 'Operation Successful',
    suspensionMessage: `After suspension, this employee will be automatically logged out the office system and won't be able to log into his/her account again until you activate it here. Are you sure you want to suspend {name}?`,
    suspensionConfirmation: 'Suspension Confirmation',
    networkError: 'Network Error',
    noPhoneNumber: '(No Phone Number)',
    suspended: 'Suspended',
    active: 'Active',
    categories: 'Categories',
    categoryName: 'Category Name',
    englishName: 'English Name',
    swahiliName: 'Swahili Name',
    chineseName: 'Chinese Name',
    price: 'Price',
    priceUSD: 'Price(US$)',
    quantityMeasure: 'Quantity Measure',
    updateCategory: 'Update Category',
    addNewCategory: 'Add New Category',
    categoryEnglishNameRequired: 'Category English Name Is Required',
    employeeNameRequired: 'Employee Name Is Required',
    emailRequired: 'E-mail Is Required',
    invalidEmail: 'E-mail Invalid',
    emailTaken: 'E-mail Already Taken',
    passwordRequired: 'Password Is Required',
    passwordTooShort: `Password Too Short,It's Unsafe`,
    successful: 'Successful',
    checkFormAgain: 'Check The Form And Re-Submit',
    deleteConfirmation: 'Delete Confirmation',
    deleteEmployeeMessage: `This action is irreversible.Are you sure you want to permanently delete employee:{name}?`,
    deleteCategoryMessage: `This action is irreversible.Are you sure you want to permanently delete category:{name}?`,
    totalCategories: 'Total Categories',
    totalContainers: 'Total Containers',
    summary: 'Summary',
    categoryInfo: 'Category Info',
    containers: 'Containers',
    serialNumber: 'S/N',
    containerName: 'Container Name',
    deleteContainerMessage: `This action is irreversible.Are you sure you want to permanently delete container:{name}?`,
    deleteCargoMessage: `This action is irreversible.Are you sure you want to permanently delete cargo with tracking number:{trackingNumber}?`,
    deleteTransactionMessage: `This action is irreversible.Are you sure you want to permanently delete transaction:{description}?`,
    containerInfo: 'Container Info',
    updateContainer: 'Update Container',
    addNewContainer: 'Add New Container',
    clientsWontSeeRemark: 'Clients will never see this remark.Only Blue Wave Cargo employees will see this.',
    clientsWillSeeContainerRemark: 'Clients whose cargos are in this container will see this remark, you can put a general announcement here for all the clients who have their cargos in this container, for example if this container will delay,etc. When they track their cargos, they will see this remark.',
    noContent: '(No Content)',
    containerNumber: 'Container Number',
    portOfDischarge: 'Port Of Discharge',
    shippingOrderNumber: 'Shipping Order Number',
    vessel: 'Vessel',
    sealNumber: 'Seal Number',
    departureDate: 'Departure Date',
    departureDateLabel: 'Departure Date (DD-MM-YYYY)',
    arrivalDate: 'Arrival Date',
    arrivalDateLabel: 'Arrival Date (DD-MM-YYYY)',
    blNumber: 'B/L Number',
    shipNumber: 'Ship Number',
    documentName: 'Document Name',
    phoneNumber: 'Phone Number',
    containerRemark: 'Container Remark',
    officialUseRemark: 'Official Use Remark',
    openContainer: 'Open Container',
    totalPackages: 'Total Packages',
    unpaidPackages: 'Unpaid Packages',
    grossProfit: 'Gross Profit',
    netProfit: 'Net Profit',
    expenses: 'Expenses',
    paidPackages: 'Paid Packages',
    unpaidAmount: 'Unpaid Amount',
    unpaidAmountUSD: 'Unpaid Amount (US$)',
    unpaidAmountTSHS: 'Unpaid Amount (TShs.)',
    transitStatus: 'Transit Status',
    containerStatusTip: 'This is the whole container status. All clients whose cargos are in this container will see this status when they track their cargos. Any single cargo status will be useless if this container status is not set at DISCHARGED FROM PORT. The container status must be set to DISCHARGED FROM PORT before the cargos status in it are set to DELIVERED',
    dischargedFromPort: 'Discharged From Port',
    loadingCargo: 'Loading Cargo',
    onTheWay: 'On The Way',
    delayed: 'Delayed',
    noContainerNumber: '(No Container Number)',
    customers: 'Customers',
    customerName: 'Customer Name',
    addNewCustomer: 'Add New Customer',
    updateCustomerInfo: 'Update Customer Info',
    deleteCustomerMessage: `This action is irreversible.Are you sure you want to permanently delete customer:{name}?`,
    customerInfo: 'Customer Info',
    address: 'Address',
    noAddress: '(No Address)',
    totalCustomers: 'Total Customers',
    noName: '(No Name)',
    trackingNumber: 'Tracking Number',
    cargoInfo: 'Cargo Info',
    cargoStatusTip: `This is a single cargo status. Only the owner of this cargo or any person tracking this particular cargo will be able to see it. For this status to become active, the whole container status must be set to DISCHARGED FROM PORT, otherwise the person tracking cargo will just see the whole container status, simply because the cargo can't be delivered before the whole container is discharged from the port first.` ,
    deliveredToReceiver: 'Delivered To The Receiver',
    trackingNumberAutoGenerate: 'Tracking Number will be automatically generated by the system after you submit this form',
    receiver: 'Receiver',
    selectReceiver: 'Select Receiver',
    selectReceiverTip: `Select this cargo's receiver from the customers contacts. If that customer's contact isn't in the selection list, you can add it by simply clicking on the Add Button, the newly added customer details will be added in the system's customers' contacts list`,
    selectedReceiverInfo: 'Selected Receiver Info',
    noReceiverSelected: 'No Receiver Selected',
    packagesNumber: 'Number Of Packages',
    deliveryDate: 'Delivery Date',
    deliveryDateLabel: 'Delivery Date (DD-MM-YYYY)',
    packingList: 'Packing List',
    selectCargoCategory: 'Select Cargo Category',
    done: 'Done',
    noPackingList: 'Nothing Added In The Packing List',
    updatePackingList: 'Update Packing List',
    updatePackingListTip: `Press the button above to update the packaging list, you can select as many items as you want according to what items are available in the customer's cargo. After selecting them press the DONE button, every selected item price and quantity measure can later be manually changed according to this customer's quotation.`,
    addCustomerCargo: 'Add Customer Cargo',
    updateCustomerCargo: 'Customer Cargo',
    customize: 'Customize',
    customizePackingListItem: 'Customize Packing List Item',
    quantity: 'Quantity',
    totalQuotation: 'Total Quotation',
    amount: 'Amount',
    amountUSD: 'Amount(US$)',
    canNotUpdateCargoTransitStatus: 'Cargo transit status is unchangeable',
    paidAmountUSD: 'Paid Amount(US$)',
    paidAmountTshs: 'Paid Amount(TShs.)',
    updateCurrencyConverter: 'Update Currency Converter',
    save: 'Save',
    currencyConverter: 'Exchange Rates',
    currencyConverterCustomizeTip: `Cargo currency converter amount (US$--->TShs.) is used from the system default amount by default, if you click the customize button above, you can customize this customer's cargo currency converter. If you want to change the system wide currency converter amount, you should use the CURRENCY CONVERTER link from the top navigation bar.`,
    customizeCurrencyConverter: 'Customize Currency Converter',
    receiverName: 'Receiver Name',
    paidAmount: 'Paid Amount',
    totalExpenses: 'Total Expenses',
    profitCalculationTip: `NB:The US$ to TShs. conversions maybe different from your expectations, each transaction is converted by itself due to the factors such as different customers may have been given different currency conversion rates on their invoices and each cargo expense's conversion rate may be different depending on how the expense was added into the system,etc.`,
    transactions: 'Transactions',
    balance: 'Balance',
    income: 'Income',
    expenditure: 'Expenditure',
    transactionDate: 'Transaction Date',
    transactionDateLabel: 'Transaction Date (DD-MM-YYYY)',
    description: 'Description',
    searchByDescription: 'Search By Description',
    addTransaction: 'Add Transaction',
    updateTransaction: 'Update Transaction',
    currency: 'Currency',
    type: 'Type',
    createTransactionCurrencyTip: 'Choose the transaction currency carefully. Once the transaction is created, the currency can not be updated. You can update the amount, etc but not the currency. To correct such a transaction currency choice, you\'ll have to delete the whole transaction record and create it again. After deleting the transaction, the balance will be recalculated accordingly',
    currencyChangeTip: 'This transaction currency in unchangeable, if you really want to change this transaction currency then you should delete this transaction and create a new one with different currency. After deleting this transaction, the balance will be recalculated accordingly.',
    transactionTip: 'Write better description of your transaction here for better understanding in the future.',
    transactionTypeTip: 'Transaction type is unchangeable, if you want to change this transaction type, you should delete this transaction and create it again. After deleting the transaction, the balance will be recalculated accordingly.',
    isContainerExpenses: 'Is It A Container Expense?',
    selectContainer: 'Select Container',
    chooseExpenseContainer: 'Choose the container that this expense is related to',
    expectedNetProfit: 'Expected Net Profit',
    netProfitSoFar: 'Net Profit So Far',
    totalPaidAmount: 'Total Paid Amount',
    totalUnpaidAmount: 'Total Unpaid Amount',
    shareCargo: `Share With Client`,
    copyPDFLink: 'Copy PDF Link',
    createdBy: 'Created By',
    isCargoPayment: 'Is It Cargo Payment?'
}

export default enUs;
