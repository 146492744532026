<template>
    <div id="app">
        <router-view/>
        <input id="hidden-input" style="position:fixed;top:-1000px;left:-1000px;" type="text">
    </div>
</template>

<script>
import {eventBus, eventBusActions} from "./eventBus"
export default {
    components: {},
    data() {
        return {
            message: "",
            toastTimeout: ""
        }
    },
    mounted() {
        eventBus.$on(eventBusActions.COPY_TEXT_TO_CLIPBOARD, (info) => {
            let input = document.getElementById("hidden-input")
            input.value = info.text
            input.select();
            input.setSelectionRange(0, 99999)
            document.execCommand("copy")
            this.$message.success(info.successMessage)
            // eventBus.$emit(eventBusActions.SHOW_TOAST,`Post Title Copied`)
            input.blur()
        })
    }
}
</script>

<style lang="scss">
@import "common";

body {
    font-family: 'Roboto', sans-serif !important;
    background-color: #f0f2f5 !important;
    line-height: $line-height !important;
}

.ns-card {
    background: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2);
    padding: 10px;
    margin-bottom: 20px;
}

.col-padding-8 {
    padding: 0 8px !important;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.ns-list {
    .ns-list-item {
        border-bottom: 1px solid #cecece;
    }
}

.ns-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.4);

    .ns-overlay-item {
        margin-top: 50px;
        margin-bottom: 50%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        max-width: 1000px;
    }
}

.input-error {
    input {
        border-color: red;
    }
}
.link {
    color: #1890ff;
    cursor: pointer;
}
.form-tip {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    line-height: 1.4;
    text-align: justify;
}
.ant-calendar-picker {
    width: 100%;
}
.ant-modal-wrap,
.ant-modal-mask {
    z-index: 1100 !important;
}
.ant-select-dropdown {
    z-index: 1120 !important;
}
.ant-tooltip {
    z-index: 1230 !important;
}
</style>
