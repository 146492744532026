import Fuse from 'fuse.js';
import {$message} from "@/antdv";


let currentUser = {
    isLoggedIn: () => {
        let isLoggedIn = false;
        let token = localStorage.getItem("auth-token");
        if (token && token !== "") {
            isLoggedIn = true;
        }
        return isLoggedIn;
    },
    login: (response) => {
        localStorage.setItem("auth-token", response.headers["auth-token"]);
        localStorage.setItem("user", JSON.stringify(response.data.data));
    },
    getToken: () => {
        let token = "";
        if (localStorage.getItem("auth-token")) {
            token = localStorage.getItem("auth-token");
        }
        return token;
    },
    logout: () => {
        localStorage.removeItem("auth-token");
        localStorage.removeItem("user");
        window.location = '/';
    },
    getUserDetails() {
        let user = {}
        if (localStorage.getItem("user")) {
            user = JSON.parse(localStorage.getItem("user"));
        }
        return user;
    }
};

let responseCodes = {
    successful: 200,
    dbRequestError: 0,
    parseFormError: 1,
    formFieldsNeededError: 2,
    itemNotFoundError: 3,
    userNotAuthenticatedError: 4,
    userExists: 5,
    wrongCredentials: 6,
    wrongToken: 7
};

let supportedCountriesNews = [
    {
        name: "Kenya News",
        value: "Kenya"
    },
    {
        name: "Tanzania News",
        value: "Tanzania"
    }
]

let locale = {
    getLastSelectedCountry: () => {
        let country = "Kenya";
        if (localStorage.getItem("country")) {
            country = localStorage.getItem("country");
        }
        return country;
    },
    setCountry: (country) => {
        localStorage.setItem("country", country);
    },
    setCurrencyConverter: (amount) => {
        localStorage.setItem("currency", amount)
    },
    getCurrencyConverter: () => {
        let currency = 2310.81;
        if(localStorage.getItem("currency")) {
            currency = localStorage.getItem("currency");
        }
        return +currency;
    }
}

let validation = {
    isEmail(text) {
        let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return RegExp(pattern).test(text);
    }
}

let fuseInstance = {
    search(searchKeysArray = [], originalList = [], keywords = "") {
        if (keywords === "") {
            return originalList;
        }
        const options = {
            // isCaseSensitive: false,
            // includeScore: false,
            // shouldSort: true,
            // includeMatches: false,
            // findAllMatches: false,
            // minMatchCharLength: 1,
            // location: 0,
            threshold: 0.2,
            // distance: 100,
            // useExtendedSearch: false,
            // ignoreLocation: false,
            // ignoreFieldNorm: false,
            // fieldNormWeight: 1,
            keys: [
                ...searchKeysArray
            ]
        };

        const fuse = new Fuse(originalList, options);
        const midOutput = fuse.search(keywords);
        return midOutput.map((singleItem) => singleItem.item);
    }
}

let drawerSetups = {
    bodyStyle: {
        backgroundColor: 'white',
        position: 'absolute',
        left: 0,
        right: 0,
        top: '55px',
        bottom: 0,
        padding: 0,
        overflow: 'hidden'
    },
    contentStyle: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        overflow: 'auto'
    },
    contentFormStyle: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: '50px',
        overflow: 'auto'
    },
    contentFormFooterStyle: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: '50px'
    }
}

let errorHandler = (message) => {
    $message.error(message);
}

let successHandler = (message) => {
    $message.success(message);
}

let privileges = {
    SUPER_ADMIN: 'super admin',
    SYSTEM_MANAGER: 'system manager',
    CONTENT_MANAGER: 'content manager'
}


export {
    currentUser,
    responseCodes,
    locale,
    supportedCountriesNews,
    validation,
    fuseInstance,
    drawerSetups,
    errorHandler,
    successHandler,
    privileges
};
