<template>
    <div>
        <!-- @click="getQuotation"-->
        <div>
            <Swiper ref="swiperRef">
                <template v-slot:my-slides>
                    <div :key="slide.id" v-for="slide in slideshow" class="swiper-slide" :style="{backgroundImage: `url(${slide.img})`}">
                        <div class="slide-overlay"></div>
                        <div class="slider-content">
                            <div class="slider-title">{{slide.title}}</div>
                            <div class="slider-text">{{slide.text}}</div>
                        </div>
                    </div>
                </template>
            </Swiper>
        </div>

        <div class="my-grids">
            <div class="header">Your People In The Gulf</div>
            <a-row>
                <a-col class="col-padding-8" :xs="{span: 24}" :md="{span: 8}" :key="item.header" v-for="item in bestSolution">
                    <div class="img-icon" :style="{'background-image':'url('+item.img+')'}"></div>
                    <div style="margin-top: 10px;" class="item-header">{{item.header}}</div>
                    <div class="image-text">{{item.text}}</div>
                </a-col>
            </a-row>
        </div>

        <div class="mid-layer blue-ocean">
            <div class="bg-overlay"></div>
            <div class="message">
                <div class="header">China-Tanzania Shipping Agency</div>
                <div class="sub-phrase">At Blue Wave Cargo, with 24/7 attendance, we have the expertise, talent, and resources to get the job done.</div>
                <a-button style="margin-top: 20px;" type="primary" @click="getQuotation">GET QUOTATION</a-button>
            </div>
        </div>

        <div class="mid-layer testimonial-section">
            <div class="icon-wrapper">
                <div class="header-icon"><i class="fas fa-quote-right quote"></i></div>
            </div>
            <div class="testimonial-wrapper">
                <Testimonial>
                    <template v-slot:my-slides>
                        <div :key="index" class="swiper-slide" v-for="(item,index) in testimonials">
                            <div class="testimonial-card">
                                <div class="message">{{item.message}}</div>
                                <div class="speaker">{{item.speaker}}</div>
                            </div>
                        </div>
                    </template>
                </Testimonial>
            </div>
        </div>

        <div class="my-grids">
            <!--why us-->
            <div class="header">Why Us</div>
            <a-row>
                <a-col class="col-padding-8" :xs="{span: 24}" :md="{span: 8}" :key="item.header" v-for="item in whyUs">
                    <div class="img-icon" :style="{'background-image':'url('+item.img+')'}"></div>
                    <div class="side-by-side">
                        <i :style="{'color':item.color}" class="fas item" :class="item.icon"></i>
                        <div class="item text-item">
                            <div style="text-transform: capitalize;" class="item-header">{{item.header}}</div>
                            <div class="image-text">{{item.text}}</div>
                        </div>
                    </div>
                </a-col>
            </a-row>
        </div>

    </div>
</template>


<script>
import Swiper from "../../components/Swiper"
import Testimonial from "../../components/Testimonial"
import smallPackages from "../../assets/small-packages.jpg"
import stackedContainers from "../../assets/stacked-containers.jpg"
import truck from "../../assets/truck.jpg"
import hrz24 from "../../assets/24hrz.png"
import track from "../../assets/track-cargo.png"
import warehouse from "../../assets/warehouse.jpg"

export default {
    components: {
        Swiper,
        Testimonial
    },
    data() {
        return {
            slideshow: [
                {
                    id: 1,
                    img: 'https://bluewavecargo.com/files/slide-1.jpg',
                    title: 'Efficiency',
                    text: 'Your time is our concern'
                },
                {
                    id: 2,
                    img: 'https://bluewavecargo.com/files/slide-2.jpg',
                    title: 'Reliability',
                    text: 'We are the most reliable'
                },
                {
                    id: 3,
                    img: 'https://bluewavecargo.com/files/slide-3.jpg',
                    title: 'Experience',
                    text: 'Several Years In Business'
                }
            ],
            bestSolution: [
                {
                    "header": "Loose Cargo",
                    "img": smallPackages,
                    "text": "No cargo is small to us. We ship cargos as small as they could be with the same priority."
                },
                {
                    "header": "Freight Forwarding",
                    "img": truck,
                    "text": "Let us handle your cargo transportation from the start to the end. We've got a large network of transportation partners."
                },
                {
                    "header": "Full Containers",
                    "img": stackedContainers,
                    "text": "We ship bulk goods including full containers from China to Tanzania at a very competitive price."
                }
            ],
            whyUs: [
                {
                    "header": "Warehousing",
                    "icon": "fa-warehouse",
                    "img": warehouse,
                    "text": "We also offer good standard and secured warehousing for your goods.",
                    "color": "#03A9F4"
                },
                {
                    "header": "24Hours Support",
                    "icon": "fa-question-circle",
                    "img": hrz24,
                    "text": "Get in touch with our customer service any time.",
                    "color": "#FF5722"
                },
                {
                    "header": "Track Your Cargo",
                    "icon": "fa-map-marked-alt",
                    "img": track,
                    "text": "Track your cargo status any time by downloading our Android/iOS app.",
                    "color": "#4CAF50"
                },
            ],
            testimonials: [
                {
                    "message": "Habari !boss Mizigo yangu nimeipata Asanteni sana nimepata on time,wateja wangu hawajanipigia kelele asanteni",
                    "speaker": "Ms Judith"
                },
                {
                    "message": "To be Honest, nimependa huduma zenu Especialy where I don’t have to worry about kufanyiwa manunuzi na kusafirishiwa.Hiyo nimeipenda sana. I'll recommend anyone to your company",
                    "speaker": "Mrs Irene Mushi"
                },
                {
                    "message": "Nimetumia huduma zenu mara mbili sasa, baadae nikahama ila nimeshawishika kurudi baada ya kuona mzigo wa rafiki yangu umefika kwa haraka zaidi tofauti na nilipihamia. I can see improvements than last time and good customer Service from china Office to tanzania warehouse.",
                    "speaker": "Mr Mike Original Jeans"
                }
            ]
        }
    },
    methods: {
        getQuotation() {
            this.$router.push({name: 'contactUs'});
        }
    }
}
</script>



<style lang="scss">
    @import "../../partials";
    @import "../../mixins";

    .swiper-slide {
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        .slide-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0,0,0,0.5);
        }
        .slider-content {
            @include absoluteCenter;
            color: $white;
            text-align: center;
            text-transform: uppercase;
            width: 90%;
            max-width: 280px;
            z-index: 3;
            @include mediaQuery($tablet-width) {
                width: auto;
                max-width: 700px;
            }
            .slider-title {
                font-style: italic;
                font-size: $font-size-header-5;
                position: relative;
                &::after {
                    content: "";
                    display: block;
                    width: 90px;
                    height: 5px;
                    background: $primary-color;
                    bottom: -7px;
                    @include absoluteXCenter;
                }
                @include mediaQuery($tablet-width) {
                    font-size: $font-size-header-8;
                }
                @include mediaQuery($pc-width) {
                    font-size: $font-size-header-12;
                }
            }
            .slider-text {
                font-size: $font-size-header-7;
                font-weight: bold;
                margin-top: 30px;
                @include mediaQuery($tablet-width) {
                    font-size: $font-size-header-10;
                }
                @include mediaQuery($pc-width) {
                    font-size: $font-size-header-16;
                }
            }
        }
    }
    .my-grids {
        background: #ffffff;
        padding-top: 30px;
        padding-bottom: 30px;
        .header {
            text-align: center;
            font-size: $font-size-header-3;
            font-weight: bold;
            text-transform: uppercase;
            color: $primary-text-color;
            @include mediaQuery($pc-width) {
                font-size: $font-size-header-5;
            }
        }
        .img-icon {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            padding-bottom: 50%;
            margin-top: 20px;
            border-radius: 20px;
        }
        .item-header {
            font-size: $font-size-2;
            font-weight: bold;
            color: $primary-text-color;
            @include mediaQuery($pc-width) {
                font-size: $font-size-3;
            }
        }
        .text {
            font-size: $font-size-2;
            margin: $space-1 0;
            @include mediaQuery($pc-width) {
                font-size: $font-size-3;
            }
        }
        .image-text {
            color: rgba(0,0,0,0.4);
            font-style: italic;
            font-size: $font-size-1;
            margin: $space-1 0;
            @include mediaQuery($pc-width) {
                font-size: $font-size-2;
            }
        }
        .side-by-side {
            margin-top: $space-3;
            .item {
                display: inline-block;
                vertical-align: middle;
                &.fas {
                    font-size: 40px;
                    margin-right: 20px;
                }
            }
            .text-item {
                width: 65%;
                @include mediaQuery($pc-width) {
                    width: 75%;
                }
            }
        }
    }
    .mid-layer {
        background: $primary-color;
        padding: 50px 0;
        position: relative;
        &.testimonial-section {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-image: url("../../assets/cranes.jpg");
        }
        .bg-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0,0,0,0.2);
        }
        &.blue-ocean {
            width: 100%;
            min-height: 400px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-image: url("../../assets/blue-ocean.jpg");
            .message {
                width: 90%;
                max-width: 300px;
                text-align: center;
                @include absoluteCenter;
                .sub-phrase {
                    color: white;
                    padding: 0 10px;
                    font-size: $font-size-3;
                }
            }
            @include mediaQuery($pc-width) {
                padding: 70px 0;
                background-attachment: fixed;
                .message {
                    max-width: 700px;
                }
            }
        }
        .header {
            text-align: center;
            font-size: $font-size-header-3;
            font-weight: bold;
            text-transform: uppercase;
            color: white;
            @include mediaQuery($pc-width) {
                font-size: $font-size-header-5;
            }
        }
        .testimonial-wrapper {
            width: 90%;
            max-width: 600px;
            margin: 0 auto;
            margin-top: $space-7;
            overflow: hidden;
        }
        .testimonial-card {
            margin-left: 15px;
            margin-right: 15px;
            color: white;
            text-align: center;
            .speaker {
                margin-top: $space-3;
                font-style: italic;
                text-align: right;
                font-size: $font-size-1;
            }
        }
        .icon-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .header-icon {
            width: 70px;
            height: 70px;
            border: 1px solid white;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 35px;
            color: white;
        }
    }
</style>


