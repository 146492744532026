<template>
  <div class="wrapper">
    <div class="table-options">
      <div class="left">
        <div style="margin-bottom: 24px">
          <div class="table-title">
            <img width="48px" :src="containersIcon" alt="">
            Containers
          </div>
          <div>
            <div> All Container: {{ rawContainers.length }}</div>
          </div>
        </div>
      </div>
      <div class="right">
        <a-input-search v-model="keywords" :placeholder="$t('containerNumber')" enter-button @search="onSearch"/>
        <a-button @click="showAddNewContainerForm" type="primary" icon="plus">
          Add Container
        </a-button>
      </div>
    </div>

    <a-table
        :size="'middle'"
        :rowKey="'id'"
        bordered
        :columns="columns"
        :data-source="containers"
        :pagination="pagination"
        :loading="isFetchingContainers"
        @change="onTableChange"
    >
      <template slot="added" slot-scope="text, row, index">
        <div>{{ toPrettyDate(row.createdAt) }}</div>
      </template>
      <template slot="containerNumber" slot-scope="text, row">
        <div class="link" @click="showContainerDetailsDrawer(row)">{{ text !== '' ? text : $t('noContent') }}</div>
      </template>
      <template slot="shippingOrderNumber" slot-scope="text, row">
        <div>{{ text !== '' ? text : $t('noContent') }}</div>
      </template>
      <!--<template slot="unpaidPackages" slot-scope="text, row">
          <div>{{ text }}</div>
      </template>
      <template slot="unpaidAmount" slot-scope="text, row">
          <div>{{ text }}</div>
      </template>-->
      <template slot="departureDate" slot-scope="text, row">
        <div>{{ text !== '' ? formatDate(text) : $t('noContent') }}</div>
      </template>

      <template slot="arrivalDate" slot-scope="text, row">
        <div>{{ text !== '' ? formatDate(text) : $t('noContent') }}</div>
      </template>

      <template slot="view" slot-scope="text, row">
        <a-button :size="'small'" class="open-container-btn" @click="openContainer(row)" type="primary">
          Open
        </a-button>
      </template>

      <!--actions-->
      <template slot="actions" slot-scope="text, row">
        <a-button-group :size="'small'">
          <a-tooltip>
            <template slot="title">
              <span>{{ $t('update') }}</span>
            </template>
            <a-button @click="showUpdateContainerForm(row)" type="primary" icon="edit"/>
          </a-tooltip>
          <a-tooltip>
            <template slot="title">
              <span>{{ $t('openContainer') }}</span>
            </template>
            <a-button class="open-container-btn" @click="openContainer(row)" type="primary" icon="file-done"/>
          </a-tooltip>
          <a-tooltip>
            <template slot="title">
              <span>{{ $t('delete') }}</span>
            </template>
            <a-button @click="confirmDeleteContainer(row)" type="danger" icon="delete"/>
          </a-tooltip>
        </a-button-group>
      </template>
    </a-table>


    <!--container info drawer-->
    <a-drawer
        :title="$t('containerInfo')"
        placement="right"
        :closable="true"
        :width="500"
        @close="onCloseContainerInfoDrawer"
        :bodyStyle="bodyStyle"
        :visible="isContainerInfoDrawerVisible"
    >
      <div :style="contentStyle">
        <div class="content-wrapper">
          <div :key="key" v-for="(item,key) in selectedContainer" class="info-item">
            <template v-if="key === $t('officialUseRemark') || key === $t('containerRemark')">
              <div class="item-label">{{ key }}:</div>
              <div v-if="item.trim() === ''" class="item-description">{{ $t('noContent') }}</div>
              <div v-else class="item-description remark" v-html="item"></div>
            </template>
            <template v-else-if="key === $t('transitStatus')">
              <div class="item-label">{{ key }}:</div>
              <div class="item-description">{{ getTransitStatusText(item) }}</div>
            </template>
            <template v-else>
              <div class="item-label">{{ key }}:</div>
              <div v-if="item === null || typeof item === 'undefined' || `${item}`.trim() === ''"
                   class="item-description">{{ $t('noContent') }}
              </div>
              <div v-else class="item-description">{{ item }}</div>
            </template>
          </div>
        </div>
      </div>
    </a-drawer>


    <!--container form-->
    <a-drawer
        :title="containerFormTitle"
        placement="right"
        :closable="true"
        :width="600"
        @close="onCloseContainerForm"
        :bodyStyle="bodyStyle"
        :visible="isContainerFormVisible"
    >
      <div :style="contentFormStyle">
        <div class="content-form-wrapper">
          <div @keyup.enter="submitContainerForm">
            <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-form-item
                  :label="$t('transitStatus')">
                <a-select v-model="formData.transitStatus">
                  <a-select-option :key="item.value" v-for="item in transitStatus" :value="item.value">
                    {{ item.title }}
                  </a-select-option>
                </a-select>
                <div class="form-tip">{{ $t('containerStatusTip') }}</div>
              </a-form-item>
              <a-form-item
                  label="Country Of Origin">
                <a-input v-model="formData.countryOfOrigin"  placeholder="example: China"/>
              </a-form-item>
              <a-form-item
                  label="Port Of Discharge">
                <a-input v-model="formData.dischargePort"  placeholder="example: DSM"/>
              </a-form-item>
              <a-form-item
                  :label="$t('shippingOrderNumber')">
                <a-input v-model="formData.shippingOrderNumber"  placeholder=""/>
              </a-form-item>
              <a-form-item
                  :label="$t('vessel')">
                <a-input v-model="formData.vessel"  placeholder=""/>
              </a-form-item>
              <a-form-item
                  :label="$t('containerNumber')">
                <a-input v-model="formData.containerNumber"  placeholder=""/>
              </a-form-item>
              <a-form-item
                  :label="$t('sealNumber')">
                <a-input v-model="formData.sealNumber"  placeholder=""/>
              </a-form-item>
              <a-form-item
                  :label="$t('departureDate')">
                <a-date-picker
                    :key="`1-${new Date().getTime()}`"
                    :default-value="formData.departureDate"
                    format="DD-MM-YYYY"
                    placeholder="Date-Month-Year"
                    :allowClear="false"
                    @change="onDepartureDateChange"
                />
              </a-form-item>
              <a-form-item
                  :label="$t('arrivalDate')">
                <a-date-picker
                    :key="`2-${new Date().getTime()}`"
                    :default-value="formData.arrivalDate"
                    format="DD-MM-YYYY"
                    placeholder="Date-Month-Year"
                    :allowClear="false"
                    @change="onArrivalDateChange"
                />
              </a-form-item>
              <a-form-item
                  :label="$t('blNumber')">
                <a-input v-model="formData.blNumber"  placeholder=""/>
              </a-form-item>
              <a-form-item
                  :label="$t('shipNumber')">
                <a-input v-model="formData.shipNumber"  placeholder=""/>
              </a-form-item>
              <a-form-item
                  :label="$t('documentName')">
                <a-input v-model="formData.documentName"  placeholder=""/>
              </a-form-item>
              <a-form-item
                  :label="$t('phoneNumber')">
                <a-input v-model="formData.phoneNumber"  placeholder=""/>
              </a-form-item>
              <a-form-item
                  :label="$t('containerRemark')">
                <a-textarea rows="3" @keyup.enter.stop v-model.trim="formData.remark"
                            placeholder="Remark"/>
                <div class="form-tip">{{ $t('clientsWillSeeContainerRemark') }}</div>
              </a-form-item>
              <a-form-item :label="$t('officialUseRemark')">
                <a-textarea rows="3" @keyup.enter.stop v-model.trim="formData.officialUseRemark"
                             placeholder="Official Use Remark"/>
                <div class="form-tip">{{ $t('clientsWontSeeRemark') }}</div>
              </a-form-item>
            </a-form>
          </div>
        </div>
      </div>
      <div :style="contentFormFooterStyle">
        <div class="footer-buttons">
          <a-spin :spinning="isSubmittingContainerForm">
            <a-button @click="onCloseContainerForm">{{ $t('cancel') }}</a-button>
            <a-button @click="submitContainerForm" type="primary">{{ $t('submit') }}</a-button>
          </a-spin>
        </div>
      </div>
    </a-drawer>


  </div>
</template>

<script>
import {drawerSetups, errorHandler, fuseInstance, successHandler} from "@/services/utils";
import http from "@/http";
import moment from "moment"
import {toPrettyDate} from "../../../utils/helpers";

export default {
  name: "ContainersList",
  data() {
    return {
      containersIcon: require('@/assets/icons/containers.png'),

      isContainerInfoDrawerVisible: false,
      bodyStyle: drawerSetups.bodyStyle,
      contentStyle: drawerSetups.contentStyle,
      contentFormStyle: drawerSetups.contentFormStyle,
      contentFormFooterStyle: drawerSetups.contentFormFooterStyle,
      selectedContainer: {},
      searchKeys: ['containerNumber'],
      transitStatus: [
        {
          value: 1,
          title: this.$t('loadingCargo')
        },
        {
          value: 2,
          title: this.$t('onTheWay')
        },
        {
          value: 3,
          title: this.$t('delayed')
        },
        {
          value: 4,
          title: this.$t('dischargedFromPort')
        }
      ],
      formData: {
        countryOfOrigin: '',
        dischargePort: '',
        shippingOrderNumber: '',
        vessel: '',
        containerNumber: '',
        sealNumber: '',
        departureDate: this.getTodayDate(),
        arrivalDate: this.getMonthLaterDate(),
        blNumber: '',
        shipNumber: '',
        documentName: '',
        phoneNumber: '',
        transitStatus: 1,
        remark: '',
        officialUseRemark: ''
      },
      formDataReset: {
        dischargePort: 'DSM',
        shippingOrderNumber: '',
        vessel: '',
        containerNumber: '',
        sealNumber: '',
        departureDate: this.getTodayDate(),
        arrivalDate: this.getMonthLaterDate(),
        blNumber: '',
        shipNumber: '',
        documentName: '',
        phoneNumber: '',
        transitStatus: 1,
        remark: '',
        officialUseRemark: ''
      },
      isUpdateForm: false,
      isContainerFormVisible: false,
      isSubmittingContainerForm: false,
      labelCol: {
        xs: {span: 8}
      },
      wrapperCol: {
        xs: {span: 16}
      },
      keywords: '',
      columns: [
        {
          title: "Added",
          dataIndex: 'added',
          scopedSlots: {customRender: 'added'},
          align: 'left',
          width: '96px'
        },
        {
          title: this.$t('containerNumber'),
          dataIndex: 'containerNumber',
          scopedSlots: {customRender: 'containerNumber'},
          sorter: this.containerNumberSorter,
        },
        {
          title: "Shipping Order Number",
          dataIndex: 'shippingOrderNumber',
          scopedSlots: {customRender: 'shippingOrderNumber'},
        },
        {
          title: this.$t('departureDateLabel'),
          dataIndex: 'departureDate',
          scopedSlots: {customRender: 'departureDate'},
          sorter: this.departureDateSorter,
        },
        {
          title: this.$t('arrivalDateLabel'),
          dataIndex: 'arrivalDate',
          scopedSlots: {customRender: 'arrivalDate'},
          sorter: this.arrivalDateSorter,
        }, {
          title: "Open",
          scopedSlots: {customRender: 'view'},
          width: '108px'
        },
        {
          title: this.$t('actions'),
          dataIndex: 'actions',
          width: '140px',
          align: 'center',
          scopedSlots: {customRender: 'actions'}
        }
      ],
      pagination: {
        simple: true,
        current: 1,
        pageSize: 150,
        total: 1
      },
      tableSorter: null,
      containers: [],
      rawContainers: [],
      isFetchingContainers: false
    }
  },
  computed: {
    containerFormTitle() {
      return this.isUpdateForm ? this.$t('updateContainer') : this.$t('addNewContainer');
    },
    containerFormOkButtonText() {
      return this.isUpdateForm ? this.$t('update') : this.$t('submit');
    }
  },
  created() {
    this.fetchContainers();
  },
  methods: {
    toPrettyDate,
    getTransitStatusText(transitStatus) {
      let transitStatusText = "";
      for (let i = 0; i < this.transitStatus.length; i++) {
        if (this.transitStatus[i].value === transitStatus) {
          transitStatusText = this.transitStatus[i].title
          break;
        }
      }
      return transitStatusText
    },
    formatDate(dateString) {
      return moment(dateString).format('llll')
    },
    onDepartureDateChange(momentInstance) {
      this.formData.departureDate = momentInstance
    },
    onArrivalDateChange(momentInstance) {
      this.formData.arrivalDate = momentInstance
    },
    getTodayDate() {
      return moment(new Date().getTime())
    },
    getMonthLaterDate() {
      return moment(new Date().getTime()).add(1, 'months')
    },
    onTableChange(pagination, filters, sorters) {
      if (this.pagination.current !== pagination.current) {
        //page number
        this.pagination.current = pagination.current;
        this.fetchContainers();
      }
      /* else {
          //a change in filters or sorters
          this.pagination.current = 1;
          if(sorters.order) {
              this.tableSorter = {
                  field: sorters.field,
                  order: sorters.order
              }
          }else {
              this.tableSorter = null;
          }
      }*/
    },
    onCloseContainerForm() {
      this.isContainerFormVisible = false;
    },
    onCloseContainerInfoDrawer() {
      this.isContainerInfoDrawerVisible = false;
    },
    containerNumberSorter(a, b) {
      if (a.containerNumber < b.containerNumber) {
        return -1;
      }
      if (a.containerNumber > b.containerNumber) {
        return 1;
      }
      return 0;
    },
    departureDateSorter(a, b) {
      if (a.departureDate < b.departureDate) {
        return -1;
      }
      if (a.departureDate > b.departureDate) {
        return 1;
      }
      return 0;
    },
    arrivalDateSorter(a, b) {
      if (a.arrivalDate < b.arrivalDate) {
        return -1;
      }
      if (a.arrivalDate > b.arrivalDate) {
        return 1;
      }
      return 0;
    },
    async fetchContainers() {
      try {
        let params = {
          pageNum: this.pagination.current,
          itemsPerPage: this.pagination.pageSize
        }
        if (this.tableSorter) {
          params.sorter = {
            order: this.tableSorter.order,
            field: this.tableSorter.field
          }
        }
        this.isFetchingContainers = true;
        let response = await http.get('/containers', params);
        this.isFetchingContainers = false;
        if (response.data.code === 200) {
          let serverData = response.data.data;
          this.rawContainers = serverData.result;
          this.pagination.pageSize = serverData.itemsPerPage;
          this.pagination.total = serverData.totalPages;
          this.pagination.current = serverData.pageNum;
          this.onSearch();
        }
      } catch (e) {
        this.isFetchingContainers = false;
        errorHandler(this.$t('networkError'));
      }
    },
    async submitContainerForm() {
      try {
        this.isSubmittingContainerForm = true;
        if (this.formData.departureDate === "") {
          this.formData.departureDate = this.getTodayDate();
        }
        if (this.formData.arrivalDate === "") {
          this.formData.arrivalDate = this.getMonthLaterDate();
        }
        let response;
        if (this.isUpdateForm) {
          response = await http.put('/container', this.formData);
        } else {
          response = await http.post('/container', this.formData);
        }

        this.isSubmittingContainerForm = false;
        if (response.data.code === 200) {
          successHandler(this.$t('operationSuccessful'));
          this.isContainerFormVisible = false;
          this.fetchContainers();
        }
      } catch (e) {
        this.isSubmittingContainerForm = false;
        errorHandler(this.$t('networkError'));
      }
    },
    confirmDeleteContainer(container) {
      this.$confirm({
        title: this.$t('deleteConfirmation'),
        content: this.$t('deleteContainerMessage', {name: container.englishName}),
        okText: this.$t('yes'),
        okType: 'danger',
        cancelText: this.$t('cancel'),
        onOk: () => {
          this.deleteContainer(container);
        }
      })
    },
    async deleteContainer(container) {
      try {
        this.isFetchingContainers = true;
        let response = await http.delete('/container', container);
        if (response.data.code === 200) {
          successHandler(this.$t('operationSuccessful'))
          this.fetchContainers();
        }
      } catch (e) {
        this.isFetchingContainers = false;
        errorHandler(this.$t('networkError'));
      }
    },

    openContainer(container) {
      this.$router.push({name: 'cargos-list',
        query: {id: container.id, containerNumber: container.containerNumber}})
    },
    showUpdateContainerForm(container) {
      this.isUpdateForm = true;
      this.isContainerFormVisible = true;
      this.$nextTick(() => {
        this.formData = {
          ...container,
          departureDate: moment(container.departureDate),
          arrivalDate: moment(container.arrivalDate)
        }
      });
    },
    showContainerDetailsDrawer(container) {
      this.selectedContainer = {
        [this.$t('transitStatus')]: container.transitStatus,
        [this.$t('shippingOrderNumber')]: container.shippingOrderNumber,
        [this.$t('vessel')]: container.vessel,
        [this.$t('containerNumber')]: container.containerNumber,
        [this.$t('sealNumber')]: container.sealNumber,
        [this.$t('departureDateLabel')]: this.formatDate(container.departureDate),
        [this.$t('arrivalDateLabel')]: this.formatDate(container.arrivalDate),
        [this.$t('blNumber')]: container.blNumber,
        [this.$t('shipNumber')]: container.shipNumber,
        [this.$t('documentName')]: container.documentName,
        [this.$t('phoneNumber')]: container.phoneNumber,
        [this.$t('containerRemark')]: container.remark,
        [this.$t('officialUseRemark')]: container.officialUseRemark
      };
      this.isContainerInfoDrawerVisible = true;
    },
    onSearch() {
      let output = fuseInstance.search(this.searchKeys, this.rawContainers, this.keywords);
      this.containers = [...output];
    },
    showAddNewContainerForm() {
      this.resetContainerForm();
      this.isUpdateForm = false
      this.isContainerFormVisible = true;
    },
    resetContainerForm() {
      this.formData = {
        ...this.formDataReset,
        departureDate: this.getTodayDate(),
        arrivalDate: this.getMonthLaterDate()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../partials";
@import "../../../mixins";

.wrapper {
  background: white;
  padding: 15px;

  .table-options {
    @include tableOptions;
  }
}

.content-wrapper {
  @include drawerList;
}

.content-form-wrapper {
  padding: 15px 30px;
}

.footer-buttons {
  @include drawerFormFooterButtons;
}

.open-container-btn {
  background-color: #4caf50;
  color: white;
  border-top: 1px solid #4caf50;
  border-bottom: 1px solid #4caf50;
}
</style>
