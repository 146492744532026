import moment from "moment/moment";


export function  canUpdateCargoTransitStatus(transitStatus) {
    return transitStatus >= 4
}

export function getTotalQuotationStringCurrencyUSD(cargo) {
    let total = 0;
    cargo.packingList.forEach((item) => {
        total = total + (item.price * item.quantity)
    });
    return this.roundToStringCurrency(total);
}

export function  getTotalQuotationStringCurrencyTShs(cargo) {
    let total = 0;
    cargo.packingList.forEach((item) => {
        total = total + (item.price * item.quantity * cargo.currencyConverter)
    });
    return this.roundToStringCurrency(total);
}


export const transitStatusOptions  = [
    {
        value: 4,
        title: "Discharged From Port"
    },
    {
        value: 5,
        title: "Delivered To Receiver"
    }
]