import Vue from 'vue'
import App from './App.vue'
import router from './router'
import antDVInstall from "@/antdv"
import i18n from "@/i18n";

antDVInstall();

Vue.config.productionTip = false

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
