<template>
    <div class="no-overflow">
        <Navigation :is-admin-view="true"/>
        <div class="ns-wrapper-top ns-wrapper">
            <DashboardNavigation/>
            <div class="padded-content">
                <div class="scrollable">
                    <div class="ns-admin-wrapper">
                        <router-view/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navigation from "../components/TopNavigation.vue";
import DashboardNavigation from "@/components/SideNavigation.vue";
export default {
    components: {
        Navigation,
        DashboardNavigation
    }
}
</script>

<style lang="scss" scoped>
    @import "../partials";
    @import "../mixins";
    .no-overflow {
        overflow: hidden;
    }
    .ns-wrapper {
        max-width: $website-max-width;
        margin-left: auto;
        margin-right: auto;
    }
    .padded-content {
        padding-left: $admin-nav-width;
        position: relative;
        height: calc(100vh - 70px);
        @include mediaQuery($pc-width) {
            height: calc(100vh - 90px);
        }
    }
    .scrollable {
        position: absolute;
        left: $admin-nav-width;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: auto;
    }
    .ns-admin-wrapper {
        min-width: $admin-table-view-min-width;
        padding: 10px;
        @include mediaQuery($tablet-width) {
            padding-bottom: 25px;
        }
    }
    .ns-wrapper-top {
        position: relative;
        margin-top: $header-height-mobile;
        @include mediaQuery($pc-width) {
            margin-top: $header-height;
        }
    }
</style>
