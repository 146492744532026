import moment from "moment/moment";

export function isEmpty(value){

    if(value===null){
        return true;
    }

    if(value===undefined){
        return true;
    }

    if(value==='undefined'){
        return true;
    }

    if(value==='null'){
        return true;
    }

    return value === '';

}

export function isNotEmpty(value){

    return !isEmpty(value);

}


export function getTodayDate() {
    return moment(new Date().getTime())
}

export function toPrettyDate(dateString) {
   return moment(dateString).format('DD MMM YYYY HH:mm')
}

export function roundToDecimalPlaces(value, decimalPlaces=2) {
    if (decimalPlaces < 0) {
        return Math.round(value);
    }
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.round(value * multiplier) / multiplier;
}