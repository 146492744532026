<template>
    <div class="dashboard-nav-wrapper">
        <div class="scrollable-nav">
            <a-menu
                :style="menuStyle"
                mode="inline"
                :default-selected-keys="defaultSelectedKeys"
                @click="handleMenuClick">
                <a-menu-item :key="menuItem.routeName" v-for="(menuItem,index) in menu">
                    <i style="margin-right: 8px;" :class="menuItem.icon" class="fas"></i>
                    <span>{{menuItem.title}}</span>
                </a-menu-item>
            </a-menu>
        </div>
    </div>
</template>

<script>
import adminMenu from "@/services/adminMenu";
import {currentUser, privileges} from "@/services/utils";

export default {
    name: "SideNavigation",
    data() {
        return {
            defaultSelectedKeys: [],
            menuStyle: {
                height: '100%'
            },
            menu: []
        }
    },
    methods: {
        setDashboardNavigation() {
            let privilege = currentUser.getUserDetails().privilege;
            if(privilege === privileges.SYSTEM_MANAGER || privilege === privileges.SUPER_ADMIN) {
                this.menu = adminMenu;
                return;
            }

            this.menu = adminMenu.filter((item)=>{
                return item.privilege !== privileges.SYSTEM_MANAGER;
            })
        },
        async handleMenuClick(event) {
            try{
                await this.$router.push({name: event.key});
            }catch (e) {

            }
        }
    },
    created() {
        this.setDashboardNavigation();
    },
    mounted() {
        this.menu.forEach((item)=>{
            let isShipmentChild = item.routeName === 'shipment' && this.$route.name === 'container';
            if(item.routeName === this.$route.name || isShipmentChild) {
                this.defaultSelectedKeys.push(item.routeName);
            }
        });
    }
}
</script>

<style lang="scss" scoped>
@import "../mixins";
@import "../partials";

.dashboard-nav-wrapper {
    position: fixed;
    top: $header-height-mobile;
    bottom: 0;
    width: $admin-nav-width;
    background: white;
    overflow: hidden;
    border-right: 1px solid rgba(0,0,0,0.4);
    z-index: 3;
    @include mediaQuery($pc-width) {
        top: $header-height;
    }
    .scrollable-nav {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: $admin-nav-width + 40;
        overflow-y: auto;
    }
}
</style>
