<template>
    <div>
        <Navigation/>
        <div class="ns-wrapper-top ns-wrapper">
            <router-view/>
        </div>
        <Footer class="ns-wrapper"/>
    </div>
</template>

<script>
import Navigation from "../components/TopNavigation.vue"
import Footer from "../components/Footer"
export default {
    components: {
        Navigation,
        Footer
    }
}
</script>

<style lang="scss" scoped>
    @import "../partials";
    @import "../mixins";
    .ns-wrapper {
        max-width: $website-max-width;
        margin-left: auto;
        margin-right: auto;
    }
    .ns-wrapper-top {
        margin-top: $header-height-mobile;
        @include mediaQuery($pc-width) {
            margin-top: $header-height;
        }
    }
</style>