<template>
  <div>
    <div class="fixed-nav">
      <div class="nav-container">
        <router-link to="/" class="logo">
          <img src="../assets/logo.png" alt="">
        </router-link>
        <div class="nav-items">
          <router-link v-if="showNormalUserView" to="/home" class="item">Home</router-link>
          <router-link v-if="showNormalUserView" to="/our-services" class="item">Our Services</router-link>
          <router-link v-if="showNormalUserView" to="/contact-us" class="item">Contact Us</router-link>
          <router-link v-if="isUserLoggedIn && showNormalUserView" to="/admin/shipment" class="item">
            Dashboard
          </router-link>
          <router-link v-if="!isUserLoggedIn" to="/login" class="item">Login</router-link>
          <span @click="showCurrencyConverterDialog" v-if="isUserLoggedIn && !showNormalUserView" class="item">
             <i style="margin-right: 8px;" class="fa-usd fas"></i>
             Exchange Rates
          </span>
          <span @click="logout" v-if="isUserLoggedIn" class="item">Logout</span>
        </div>

        <div @click="toggleMobileNav()" class="nav-toggler">
          <span :class="{open:isNavOpen}"></span>
          <span :class="{hide:isNavOpen}"></span>
          <span :class="{open:isNavOpen}"></span>
        </div>
      </div>
    </div>
    <!--only on mobile devices-->
    <div @click="hideMobileNav()" :class="{'show-nav':isNavOverlayOpen}" class="dummy-overlay"></div>
    <div class="mobile-nav" :class="{'show-nav':isNavOpen}">
      <div class="nav-items">
        <router-link v-if="showNormalUserView" @click.native="hideMobileNav" to="/home" class="item">Home
        </router-link>
        <router-link v-if="showNormalUserView" @click.native="hideMobileNav" to="/our-services" class="item">Our
          Services
        </router-link>
        <router-link v-if="showNormalUserView" @click.native="hideMobileNav" to="/contact-us" class="item">
          Contact Us
        </router-link>
        <router-link v-if="isUserLoggedIn && showNormalUserView" to="/admin/shipment"
                     @click.native="hideMobileNav" class="item">Dashboard
        </router-link>
        <router-link v-if="!isUserLoggedIn" to="/login" @click.native="hideMobileNav" class="item">Login</router-link>

        <span @click="showCurrencyConverterDialog" v-if="isUserLoggedIn && !showNormalUserView" class="item">
                   <i style="margin-right: 8px;" class="fa-users fas"></i>
                  Exchange Rates
                </span>

        <span @click="logout" v-if="isUserLoggedIn" class="item">Logout</span>
      </div>
    </div>


    <!--currency converter modal-->
    <a-modal
        v-model="isCurrencyConverterDialogVisible"
        :title="$t('updateCurrencyConverter')"
        :width="600"
        centered
        @ok="updateCurrencyConverter"
        :okText="$t('save')"
        :cancelText="$t('cancel')"
    >
      <div style="display: flex; vertical-align: center; justify-content: center;">
        <div style="margin-right: 6px; display: flex; align-items: center;">1 US$ =</div>
        <div style="display: flex; align-items: center;">
          <a-input-number style="width: 100px;" v-model="currency"/>
          <div style="margin-left: 6px;">TShs.</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {currentUser, errorHandler, successHandler, locale} from '@/services/utils';
import http from "@/http";

export default {
  props: {
    isAdminView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showNormalUserView: !this.isAdminView,
      isNavOpen: false,
      isNavOverlayOpen: false,
      isUserLoggedIn: false,
      isLoggingOut: false,
      isCurrencyConverterDialogVisible: false,
      currency: 0,
      currentExchangeRate: {}
    }
  },
  watch: {
    isAdminView() {
      this.showNormalUserView = !this.isAdminView;
    }
  },
  created() {
    this.isUserLoggedIn = currentUser.isLoggedIn();
    this.currency = locale.getCurrencyConverter();
    this.getExchangeRates();
  },
  methods: {
    async getExchangeRates() {
      try {
        let response = await http.get('/rates')
        let serverResponse = response.data
        if (serverResponse.code === 200) {
          if (serverResponse.data) {
            this.currentExchangeRate = serverResponse.data
            locale.setCurrencyConverter(this.currentExchangeRate.exchangeRate)
            this.currency = this.currentExchangeRate.exchangeRate
          }
        }
      } catch (e) {
        console.log(e)
        //ignore
      }
    },
    async updateServerExchangeRates() {
      try {
        let response = await http.put('/rates', this.currentExchangeRate)
        let serverResponse = response.data
        if (serverResponse.code === 200) {
          this.$message.success('Exchange rates have been updated')
        }
      } catch (e) {
        //ignore
      }
    },
    updateCurrencyConverter() {
      this.isCurrencyConverterDialogVisible = false;
      locale.setCurrencyConverter(this.currency)
      this.currentExchangeRate.exchangeRate = this.currency
      this.updateServerExchangeRates()
    },
    showCurrencyConverterDialog() {
      this.currency = locale.getCurrencyConverter();
      this.isCurrencyConverterDialogVisible = true;
    },
    async logout() {
      this.hideMobileNav();
      if (this.isLoggingOut) {
        return
      }
      try {
        let response = await http.post('/logout');
        successHandler("User Logged Out");
        this.isLoggingOut = false;
        currentUser.logout();
      } catch (e) {
        this.isLoggingOut = false;
        errorHandler("Network Error");
      }
    },
    hideMobileNav() {
      this.isNavOpen = false
      setTimeout(() => {
        this.isNavOverlayOpen = false
      }, 300);
    },
    showMobileNav() {
      this.isNavOpen = true
      this.isNavOverlayOpen = true
    },
    toggleMobileNav() {
      if (this.isNavOpen) {
        this.hideMobileNav()
        return
      }
      this.showMobileNav()
    }
  }
}
</script>


<style lang="scss" scoped>
@import "../mixins";
@import "../partials";

.fixed-nav {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  background: $white;
  height: $header-height-mobile;
  z-index: 15;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2);
  @include mediaQuery($pc-width) {
    height: $header-height;
  }

  .nav-container {
    max-width: $website-max-width;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    width: 100%;
    height: 100%;

    .logo {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);

      img {
        max-width: 100px;
        @include mediaQuery($pc-width) {
          max-width: 130px;
        }
      }
    }

    .nav-items {
      display: none;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      @include mediaQuery($pc-width) {
        display: block !important;
      }

      .item {
        display: inline-block;
        height: $header-height;
        line-height: $header-height;
        padding: 0 15px;
        margin-right: 1px;
        color: $primary-text-color;
        text-transform: uppercase;

        &.router-link-exact-active,
        &:hover {
          color: $link-color !important;
          cursor: pointer;
        }
      }
    }
  }

  .nav-toggler {
    position: fixed;
    right: 10px;
    top: 0;
    height: 18px;
    width: 30px;
    @include absoluteYCenter;
    @include mediaQuery($pc-width) {
      display: none !important;
    }

    span {
      position: absolute;
      width: 100%;
      height: 4px;
      background: #000;
      display: block;
      border-radius: 8px;

      &:nth-child(1) {
        top: 0;

        &.open {
          top: 50%;
          transform: translateY(-50%) rotate3d(0, 0, 1, 45deg);
        }
      }

      &:nth-child(2) {
        @include absoluteYCenter;

        &.hide {
          display: none;
        }
      }

      &:nth-child(3) {
        bottom: 0;

        &.open {
          bottom: auto;
          top: 50%;
          transform: translateY(-50%) rotate3d(0, 0, 1, -45deg);
        }
      }
    }
  }
}

.dummy-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: $header-height-mobile;
  width: 100%;
  bottom: 0;
  z-index: 7;
  background: $overlay-bg;
  display: none;
  @include mediaQuery($pc-width) {
    display: none !important;
  }

  &.show-nav {
    display: block;
  }
}

.mobile-nav {
  position: fixed;
  left: 0;
  right: 0;
  top: $header-height-mobile;
  width: 100%;
  overflow: hidden;
  z-index: 10;
  transform: translate3d(0, -100%, 0);
  transition: transform 300ms ease-in-out;

  &.show-nav {
    transform: translate3d(0, 0%, 0);
  }

  @include mediaQuery($pc-width) {
    display: none !important;
  }

  .nav-items {
    background: $white;

    .item {
      display: block;
      height: 50px;
      line-height: 50px;
      border-top: 1px solid $border-color;
      padding: 0 $space-2;
      color: $primary-color;
      text-transform: uppercase;

      &.router-link-exact-active {
        background: $link-color;
        color: $white !important;
      }
    }
  }
}

a {
  text-transform: uppercase;
}
</style>

