<template>
    <div>
        <div class="parallax-bg">
            <div class="big-header">
                <div>Sea Freight</div>
                <div>&</div>
                <div>Freight Forwarding</div>
            </div>
        </div>

        <!--sea freight-->
        <a-row type="flex">
            <a-col class="xs-order-two" :xs="{span: 24}" :md="{span: 12}">
                <div :style="{backgroundImage: `url(${stackedContainers})`}" class="cargo-showcase"></div>
            </a-col>
            <a-col :xs="{span: 24}" :md="{span: 12}" class="service-intro xs-order-one">
                <div style="color:gray;font-style:italic;">We provide fast, flexible and reliable sea freight solutions, from China to Tanzania.</div>
                <div class="ns-header">Sea freight transportation services</div>
                <div class="intro-text">
                    <div>
                        Blue Wave Cargo provides the best sea freight solutions for cargos from China to Tanzania. From loose cargos to bulk cargos, we've got you covered.
                        Our solutions are the most secured, time guaranteed and have always proven to have satisfied lots of clients for many years now.
                    </div>
                </div>
            </a-col>
        </a-row>


        <!--freight forwarding-->
        <a-row type="flex">
            <a-col :xs="{span: 24}" :md="{span: 12}" class="service-intro">
                <div style="color:gray;font-style:italic;">We provide the most time friendly freight forwarding services.</div>
                <div class="ns-header">Freight forwarding services</div>
                <div class="intro-text">
                    <div>
                        Blue Wave Cargo provides shipping services door to door as well as customs clearances. Do you need to import freight from China?
                        Along with being a licensed customs broker, we are also a certified freight forwarder. We can handle your international shipment
                        door to door with ease.
                    </div>
                </div>
            </a-col>
            <a-col :xs="{span: 24}" :md="{span: 12}">
                <div :style="{backgroundImage: `url(${truck})`}" class="cargo-showcase"></div>
            </a-col>
        </a-row>


        <!--warehouse-->
        <a-row type="flex">
            <a-col class="xs-order-two" :xs="{span: 24}" :md="{span: 12}">
                <div :style="{backgroundImage: `url(${warehouse})`}" class="cargo-showcase"></div>
            </a-col>
            <a-col :xs="{span: 24}" :md="{span: 12}" class="service-intro xs-order-one">
                <div style="color:gray;font-style:italic;">We provide secured storage for all your cargos.</div>
                <div class="ns-header">Warehousing services</div>
                <div class="intro-text">
                    <div>
                        At Blue Wave Cargo we understand that you may need a temporary storage for your cargo, we've
                        got that service just for you. From the time you deliver your cargos at our offices in China
                        until you receive them in Tanzania, rest assured on the safety of your cargos at all weathers.
                    </div>
                </div>
            </a-col>
        </a-row>


        <!--<div class="more-services">
            <div class="header">More Services</div>
            <a-row>
                <a-col :xs="{span: 24}" :md="{span: 8}" :key="item.title" v-for="item in moreServices" class="service">
                    <i :style="{'color':item.color}" class="fas icon" :class="item.icon"></i>
                    <div class="title">{{item.title}}</div>
                    <div class="message">{{item.message}}</div>
                </a-col>
            </a-row>
        </div>-->

        <div class="get-quote">
            <div class="side-by-side">
                <div class="left">
                    Get A Free Quotation
                </div>
                <div class="right">
                    <a-button @click="getQuotation">GET QUOTATION</a-button>
                </div>
            </div>
        </div>



    </div>
</template>


<script>
import stackedContainers from "../../assets/stacked-containers.jpg"
import truck from "../../assets/truck.jpg"
import warehouse from "../../assets/warehouse.jpg"
export default {
    components: {
    },
    data() {
        return {
            stackedContainers: stackedContainers,
            truck: truck,
            warehouse: warehouse,
            "moreServices": [
                {
                    "title": "Documentations and Custom",
                    "message": `We can help you to deal with your
                    documentation and customs difficulties. Our agents
                    can deal with any of your import documents in a
                    very short time`,
                    "icon": "fa-file-signature",
                    "color": "#FF5722"
                },
                {
                    "title": "Warehousing",
                    "message": `Worried about your goods security? Worry no more,
                    we provide very safe storage service for your goods at an
                    affordable price.`,
                    "icon": "fa-warehouse",
                    "color": "#FF5722"
                },
                {
                    "title": "Door to door",
                    "message": `We also offer door to door delivery service at a very
                    affordable price.`,
                    "icon": "fa-truck-moving",
                    "color": "#FF5722"
                }
            ]
        }
    },
    methods: {
        getQuotation() {
            this.$router.push({name: 'contactUs'});
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../partials";
    @import "../../mixins";
    .parallax-bg {
        background-color: #fff;
        background-image: url("../../assets/containers.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        @include mediaQuery($pc-width) {
            background-attachment: fixed;
        }
        .big-header {
            color: white;
            padding: 60px 0;
            text-align: center;
            background: rgba(0,0,0,0.5);
            font-size: $font-size-header-7;
            font-weight: bold;
            text-transform: uppercase;
            @include mediaQuery($tablet-width) {
                font-size: $font-size-header-10;
            }
            @include mediaQuery($pc-width) {
                font-size: $font-size-header-16;
                padding: 150px 0;
            }
        }
    }
    .cargo-showcase {
        width: 100%;
        height: 400px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        @include mediaQuery($pc-width) {
            height: 500px;
        }
    }
    .service-intro {
        padding: 20px;
        .ns-header {
            color: $primary-color;
            font-size: $font-size-header-5;
            font-weight: bold;
            text-transform: uppercase;
            position: relative;
                &::after {
                    content: "";
                    display: block;
                    width: 60px;
                    height: 5px;
                    background: $primary-color;
                    position: absolute;
                    bottom: -17px;
                    left: 0;
                }
            @include mediaQuery($tablet-width) {
                font-size: $font-size-header-8;
            }
            @include mediaQuery($pc-width) {
                font-size: $font-size-header-10;
            }
        }
        .intro-text {
            margin-top: $space-10;
            margin-bottom: $space-5;
        }
    }
    .more-services {
        background-image: url("../../assets/gray_origami.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding-top: 30px;
        padding-bottom: 30px;
        .header {
            text-align: center;
            font-size: $font-size-header-3;
            font-weight: bold;
            text-transform: uppercase;
            color: $error-border-color;
            margin-bottom: $space-3;
            @include mediaQuery($pc-width) {
                font-size: $font-size-header-5;
            }
        }
        .service {
            padding: 15px;
            text-align: center;
            margin-top: $space-4;
            .icon {
                font-size: $font-size-header-18;
                margin-bottom: $space-1;
            }
            .title {
                margin-bottom: $space-1;
            }
        }
    }
    .get-quote {
        background: rgb(3, 169, 244);
        padding: 70px 0;
        position: relative;
        .side-by-side {
            .left {
                color: white;
                padding-left: 20px;
                font-weight: bold;
                font-size: $font-size-header-1;
                max-width: 60%;
                text-transform: uppercase;
            }
            .right {
                @include absoluteYCenter;
                right: 20px;
                .btn-primary {
                    color: #FF5722;
                    background: white;
                    text-transform: uppercase;
                    padding: 6px 12px;
                    border-radius: 5px;
                    font-weight: 400;
                    font-size: 16px;
                }
            }
        }
    }
    .xs-order-one {
        order: 1;
        @include mediaQuery($tablet-width) {
            order: 2;
        }
    }
    .xs-order-two {
        order: 2;
        @include mediaQuery($tablet-width) {
            order: 1;
        }
    }
</style>


