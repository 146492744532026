import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '../views/homepage/Homepage'
import OurServices from '../views/our-services/OurServices'
import contactUS from '../views/contact-us/ContactUs'
import RootView from '../views/RootView'
import NotFound from "@/views/NotFound"
import {currentUser} from "@/services/utils"
import Login from "@/views/login/Login";
import RootViewAdmin from "@/views/RootViewAdmin";
import Dashboard from "@/views/admin/dashboard/Dashboard";
import Employees from "@/views/admin/employees/Employees";
import ContainersList from "@/views/admin/shipment/ContainersList";
import CargosList from "@/views/admin/shipment/CargosList";
import Finance from "@/views/admin/finance/Finance";
import Categories from "@/views/admin/categories/Categories";
import Customers from "@/views/admin/customers/Customers";
import CargoForm from "@/views/admin/shipment/CargoForm.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: RootView,
        children: [
            {
                path: '/',
                name: 'siteRoot',
                redirect: "/home"
            },
            {
                path: 'home',
                name: 'home',
                component: Homepage
            },
            {
                path: 'our-services',
                name: 'ourServices',
                component: OurServices
            },
            {
                path: 'contact-us',
                name: 'contactUs',
                component: contactUS
            },
            {
                path: 'login',
                name: 'login',
                component: Login
            }
        ]
    },
    {
        path: '/admin',
        component: RootViewAdmin,
        children: [
            {
                path: '/',
                name: 'adminHome',
                redirect: "/admin/dashboard"
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                component: Dashboard
            },
            {
                path: 'employees',
                name: 'employees',
                component: Employees
            },
            {
                path: 'shipment',
                name: 'shipment',
                component: ContainersList
            },
            {
                path: 'shipment/cargos-list',
                name: 'cargos-list',
                component: CargosList
            }, {
                path: 'shipment/cargo-form',
                name: 'cargo-form',
                component: CargoForm
            },
            {
                path: 'categories',
                name: 'categories',
                component: Categories
            },
            {
                path: 'finance',
                name: 'finance',
                component: Finance
            },
            {
                path: 'customers',
                name: 'customers',
                component: Customers
            }
        ]
    },
    {
        path: '*',
        name: 'NotFound',
        component: NotFound
    }
]

const router = new VueRouter({
    routes,
    mode: "history",
})


//Auth
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    if (!currentUser.isLoggedIn()) {
        //user is not logged in
        if (to.path.startsWith('/admin/')) {
            //the user is pretending to be an admin,but he isn't, reject him
            next('/');
            return;
        }
        //the user is going to his allowed pages
        next();
        return;
    }

    //user seems to be logged in because he has an auth-token
    //stop the user from getting into the login/reset password routes
    if (to.path.startsWith("/login") || to.path.startsWith("/reset-password")) {
        next("/admin");
        return;
    }
    next();

});


export default router
