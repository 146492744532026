import {privileges} from "@/services/utils";

let adminMenu = [
    /*{
        title: 'Dashboard',
        routeName: 'dashboard',
        icon: 'fa-tachometer-alt'
    },*/
    {
        title: 'Employees',
        routeName: 'employees',
        privilege: privileges.SYSTEM_MANAGER,
        icon: 'fa-users'
    },
    {
        title: 'Shipment',
        routeName: 'shipment',
        icon: 'fa-ship'
    },
    {
        title: 'Categories',
        routeName: 'categories',
        icon: 'fa-tags'
    },
    {
        title: 'Customers',
        routeName: 'customers',
        icon: 'fa-address-book'
    },
    {
        title: 'Finance',
        routeName: 'finance',
        icon: 'fa-funnel-dollar'
    }
]

export default adminMenu;
